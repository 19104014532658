import React from "react";
import { FaFile, FaLaptop } from "react-icons/fa";
import { LiaStarSolid } from "react-icons/lia";
import Profile from "../../../assets/profile2.png";
import Card2 from "./Card2";


const HomeTeacherPortfolio = () => {

  const card = [{
    profile: Profile,
    name: 'Irfan Ahsan',
    charge: "Per Hour $50",
  
  }]
  return (
    <div className="m-3 flex flex-col break-words w-full mb-4 shadow-lg bg-white rounded-lg  border-0">
      <div className="flex items-center xl:p-5 sm:p-0 justify-center">
        <div className="w-full  xl:p-5 sm:p-0">

          <div className="p-3">
            <Card2
              card={card} />
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-3 ">
            <div className="   md:px-4 md:py-4 col-span-1 sm:col-span-2 md:col-span-2 lg:col-span-2 xl:col-span-2 space-y-5">
              
              <div className="border rounded-3xl shadow-xl p-5">
                <p className="text-2xl font-semibold mb-3">About</p>
                <p className="text-xs font-light">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
              </div>

              <div className="border rounded-3xl shadow-xl p-5">
                <p className="text-2xl font-semibold mb-3">Education</p>
                <div>
                  <p className="text-sm font-semibold">Lorem Ipsum</p>
                  <p className="text-sm font-light mb-3">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                </div>
                <div>
                  <p className="text-sm font-semibold">Lorem Ipsum</p>
                  <p className="text-sm font-light mb-3">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                </div>
                <div>
                  <p className="text-sm font-semibold">Lorem Ipsum</p>
                  <p className="text-sm font-light mb-3">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                </div>
                <div>
                  <p className="text-sm font-semibold">Lorem Ipsum</p>
                  <p className="text-sm font-light mb-3">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                </div>
              </div>

            </div>
            <div className="  col-span-1 sm:col-span-1 md:col-span-1 lg:col-span-1 xl:col-span-1 md:px-4 md:py-4 overflow-x-auto space-y-5 mt-5 md:mt-0">
              <div className="border rounded-3xl shadow-xl p-5">
                <p className="text-2xl font-semibold mb-2">Skills</p>
                <p className="text-sm font-semibold">Lorem Ipsum, Lorem Ipsum, Lorem Ipsum
                  Lorem Ipsum, Lorem Ipsum</p>
              </div>
              <div className="border rounded-3xl shadow-xl p-5">
                <p className="text-2xl font-semibold mb-3">Profile Overview</p>

                <p className="flex text-yellow-400 items-center"><LiaStarSolid /><LiaStarSolid /><LiaStarSolid /><LiaStarSolid /><LiaStarSolid /> <span className="text-black">5.0</span></p>
                <div>
                  <div className="flex items-center text-base font-semibold space-x-1 mb-2">
                    <p className="text-lg"><FaLaptop /></p>
                    <p>Home Tuition Location</p>
                  </div>
                  <div>
                    <p className="text-sm font-semibold">Lorem Ipsum, Lorem Ipsum, Lorem Ipsum
                      Lorem Ipsum, Lorem Ipsum</p>
                  </div>
                </div>

              </div>
              <div className="border rounded-3xl shadow-xl p-5">
                <p className="text-2xl font-semibold mb-3">Subjects</p>
                <div>
                  <div className="flex items-center text-base font-semibold space-x-1 mb-2">
                    <p className="text-lg"><FaFile /></p>
                    <p>Main Subject</p>
                  </div>
                  <div>
                    <p className="text-sm font-semibold">Subject Name</p>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeTeacherPortfolio;
