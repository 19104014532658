import React, { useState, useEffect } from "react";
import { FaRegUserCircle } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import axios from "axios";
import apiKey from "../../../config/apiKey";

function NewNotification() {
  const [notifications, setNotifications] = useState([]);
  const student = JSON.parse(localStorage.getItem("edo-student"));
  const userId = student ? student.student.id : "";
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchNotifications = async () => {
      setLoading(true); // Start loading before data fetch
      try {
        const response = await axios.get(
          `${apiKey}/api/edolutions/student/notifications/new/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${student?.token}`,
            },
          }
        );
        setNotifications(response.data);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
      finally {
        setLoading(false); // Stop loading after data fetch
      }
    };

    fetchNotifications();
  }, [userId,student?.token]);

  const markAsRead = async (notificationId) => {
    try {
      await axios.put(
        `${apiKey}/api/edolutions/student/notifications/mark-as-read/${notificationId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${student?.token}`,
          },
        }
      );
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification.id === notificationId
            ? { ...notification, read: true }
            : notification
        )
      );
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const markAllAsRead = () => {
    notifications.forEach((notification) => {
      if (!notification.read) {
        markAsRead(notification.id);
      }
    });
  };

  return (
    <div className="flex items-center xl:p-5 sm:p-0 justify-center">
      <div className="w-full border rounded-3xl shadow-xl p-5">
        <div>
          <p className="text-2xl font-bold">Notifications</p>
          {/* <p className="text-[#22B7AA] font-light text-sm">
            Student Name Notification
          </p> */}
        </div>
        <div className="flex border-b-2 justify-between items-center mt-8">
          <div className="flex flex-wrap md:justify-start md:space-x-12 space-x-5 text-base font-medium">
            <NavLink
              to=".././notification"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              All
            </NavLink>

            <NavLink
              to=".././newNotification"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              New
            </NavLink>

            {/* <NavLink
              to=".././unreadNotification"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              Unread
            </NavLink> */}
          </div>
          {/* <div>
            <p
              className="text-[12px] bg-[#FAFAF8] text-[#7E7E7E] font-semibold border-[#7E7E7E] p-1 rounded-lg cursor-pointer mb-2"
              onClick={markAllAsRead}
            >
              Mark All As Read
            </p>
          </div> */}
        </div>
        {loading ? (
            <tr>
              <td colSpan="9" className="py-5 px-1 text-center">
                <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-200 bg-opacity-5 backdrop-blur-sm z-50">
                  <div className="w-12 h-12 border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin border-t-[#2DD0A5]"></div>
                  <p className="mt-4 text-lg font-semibold text-[#2dd0a5]">
                    Edolution...
                  </p>
                </div>
              </td>
            </tr>
          ) : notifications.length === 0 ? (
            <tr>
              <td colSpan="9" className="py-5 px-1 text-center">
                <p className="text-gray-500">No Notification Available</p>
              </td>
            </tr>
          ) : (
        notifications.map((notification) => (
          <div
            key={notification.id}
            className="grid  gap-4 my-4 bg-[#A3E7E11C] p-4"
          >
            <div className="flex gap-2">
              <div>
                <FaRegUserCircle className="h-8 w-8" />
              </div>
              <div>
                <p className="text-[#2D3748] capitalize text-[14px] font-semibold">
                  {notification.message}
                </p>
                <span className="text-[12px] text-[#2D3748]">
                  {new Date(notification.created_at).toLocaleString()}
                </span>
              </div>
            </div>

          </div>
       ) ))}
      </div>
    </div>
  );
}

export default NewNotification;
