import React, { useEffect, useState } from "react";
import { VscEyeClosed } from "react-icons/vsc";
import { RxEyeOpen } from "react-icons/rx";
import logo from "../assets/logo.png";
import FormImage from "../assets/Form.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import apiKey from "../config/apiKey";
import { toast } from "react-toastify";

const StudentFormLogin = (props) => {
  const [isLoading, setIsLoading] = useState(false); // Add isLoading state
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading to true on form submit
    axios
      .post(`${apiKey}/api/edolutions/student/login`, formData)
      .then((res) => {
        localStorage.setItem("edo-student", JSON.stringify(res.data.data));
        toast.success("Student Login Successfully", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: {
            backgroundColor: "#4FD1C5", // Customize background color
            color: "#fff", // Customize text color
          },
        });
        navigate("/student/profile");
      })
      .catch((err) => {
        console.error(err); 

        if (err.response && err.response.data && err.response.data.errors) {
          setErrors(err.response.data.errors);
        } else {
          setErrors({ general: "An unexpected error occurred" });
        }
      })
      .finally(() => {
        setIsLoading(false); // Reset loading state after the request completes
      });
  };

  useEffect(() => {
    const user = localStorage.getItem("edo-student");
    if (user) {
      navigate("/student");
    }
  }, [navigate]);

  return (
    <div className="flex flex-wrap  ">
      <div className="w-full sm:w-1/2 ">
        <div className="flex flex-wrap  items-center justify-center p-4 bg-white ">
          <div className="w-full sm:w-12/12 p-4  text-center mt-10">
            <div className="flex justify-center">
              <NavLink to="../../work">
                <img className="w-[258px]  h-[44px]" src={logo} alt="" />
              </NavLink>
            </div>
            <div className="text-[24px] font-semibold mt-[35px] text-[#263238]">
              <p>Welcome back!</p>
            </div>
            <div className="text-[16px] text-[#292731] font-normal mt-3">
              <p>Please login to access your account.</p>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap  items-center  justify-center  bg-white md-1   ">
          <div className="w-full sm:w-9/12 p-4 mt-2 text-center">
            <form onSubmit={handleSubmit}>
              {errors.general && (
                <div className="text-red-500">{errors.general}</div>
              )}
              {/* <div className="flex justify-between items-center">
                <div className="text-start text-[14px] font-medium my-2 ">
                  Email
                </div>
                <div>
                  <CiCircleAlert />
                </div>
              </div> */}
              <input
                className="w-full px-8 py-3 bg-[#F5F5F5] border  my-2 rounded-md"
                type="email"
                placeholder="Email Address"
                name="email"
                onChange={handleChange}
              />
              {errors.email && (
                <div className="text-red-500 text-start text-sm">
                  {errors.email}
                </div>
              )}
              {/* <div className="text-start text-[14px] font-medium my-2">
                Password
              </div> */}
              <div className="relative">
                <input
                  className="w-full px-8 py-3 bg-[#F5F5F5] border my-2 rounded-md pr-12" // Adjusted style to accommodate the icon
                  type={showPassword ? "text" : "password"} // Toggle password visibility
                  placeholder="Password"
                  name="password"
                  onChange={handleChange}
                />
                {/* Toggle visibility icon */}
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 px-3 flex items-center"
                  onClick={() => setShowPassword(!showPassword)} // Toggle state onClick
                >
                  {showPassword ? (
                    <RxEyeOpen className="h-5" />
                  ) : (
                    <VscEyeClosed className="h-5" />
                  )}
                </button>
              </div>
              <Link to={props.forgetpath}>
                <div className="text-end text-[14px] font-medium text-[#66BCE8] my-1 cursor-pointer">
                  Forgot Password?
                </div>
              </Link>
              {/* <Link to="ema"> */}
              <button
                className={`w-full rounded-md bg-[#09A48C] text-[20px] font-medium text-white text-center py-4 my-2 flex items-center justify-center ${
                  isLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={isLoading} // Disable button while loading
              >
                {isLoading ? (
                  <svg
                    className="animate-spin h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  "Login"
                )}
              </button>
              {/* </Link> */}

              <div className="text-[18px] font-medium mt-5">
                <p>
                  Create your account.{" "}
                  <Link to={props.path}>
                    <span className="text-[#66BCE8] cursor-pointer">
                      Sign Up
                    </span>
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        className="w-full h-screen sm:w-1/2 "
        style={{ backgroundColor: `rgba(9, 164, 140, 0.07)` }}
      >
        <img className="w-[] h-[]" src={FormImage} alt="" />

        <div className="flex justify-center">
          <img className=" h-[40vh]  -mt-[1rem]" src={props.Image} alt="" />
        </div>

        <div className="flex flex-wrap justify-center p-4 md-1   ">
          <div className="w-full sm:w-10/12  text-center">
            <div className="flex justify-center text-[20px] font-semibold">
              <p className="text-center">Hey there!!</p>
            </div>
            <div className="flex justify-center text-[20px] font-semibold">
              <p className="text-center">{props.Description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentFormLogin;
