import React, { useState,useEffect } from "react";
import { VscEyeClosed } from "react-icons/vsc";
import { RxEyeOpen } from "react-icons/rx";
import FormImage from "../assets/Form.png";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { CiCircleAlert } from "react-icons/ci";
import google from "../assets/google.png";
import facebook from "../assets/facebook.png";
import logo from "../assets/logo.png";
import axios from "axios";
import apiKey from "../config/apiKey";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";

const StudentFormSignUp = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [classes, setClasses] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    class_id: "",
    email: "",
    phone: "",
    type: "",
    password: "",
    password_confirmation: "",
  });
  useEffect(() => {
    axios
      .get(`${apiKey}/api/edolutions/classes`, {
        
      })
      .then((res) => {
        setClasses(res.data);
  
      })
      .catch((err) => {
        console.error(err);
      });
    },[])

    
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: "",
    });
  };

  const handleSubmit = (e) => {

    
    e.preventDefault();
    setIsLoading(true); // Set loading to true on form submit
    axios
      .post(`${apiKey}/api/edolutions/student/register`, formData)
      .then((res) => {
        // console.log(res);
        toast.success("Student Register Successfully", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: {
            backgroundColor: "#4FD1C5", // Customize background color
            color: "#fff", // Customize text color
          },
        });
        navigate("/login/studentLogin");
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.data) {
          setErrors(err.response.data.data);
        } else {
          setErrors({ general: "An unexpected error occurred" });
        }
      })
      .finally(() => {
        setIsLoading(false); // Reset loading state after the request completes
      });
  };

  return (
    <div className="flex flex-wrap">
      <div className="w-full sm:w-1/2">
        <div className="flex flex-wrap items-center justify-center p-4 bg-white">
        <div className="flex justify-center">
              <NavLink to="../../work">
                <img className="w-[258px] mt-8  h-[44px]" src={logo} alt="" />
              </NavLink>
            </div>
          <div className="w-full sm:w-12/12 p-4 text-center">
            <div className="text-[30px] font-semibold mt-[35px] text-[#263238]">
              <p>Welcome To Edolutions</p>
            </div>
            <div className="text-[16px] text-[#292731] mt-3">
              <p>Please Signup to access your account.</p>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap items-center justify-center p-4 bg-white md-1 md:mb-[170px]">
          <div className="w-full sm:w-9/12 p-4 text-center">
            <form onSubmit={handleSubmit}>
              <div className="flex justify-between items-center">
                <div className="text-start text-[14px] font-medium my-2">
                  Full Name
                </div>
                <div>
                  <CiCircleAlert />
                </div>
              </div>
              <input
                className="w-full px-8 py-3 bg-[#F5F5F5] border my-2 rounded-md"
                type="text"
                placeholder="Full name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              {errors.name && (
                <div className="text-red-500 text-start text-sm">
                  {errors.name[0]}
                </div>
              )}

              <div className="flex justify-between items-center">
                <div className="text-start text-[14px] font-medium my-2">
                  Email
                </div>
                <div>
                  <CiCircleAlert />
                </div>
              </div>
              <input
                className="w-full px-8 py-3 bg-[#F5F5F5] border my-2 rounded-md"
                type="text"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && (
                <div className="text-red-500 text-start text-sm">
                  {errors.email[0]}
                </div>
              )}

              <div className="flex justify-between items-center">
                <div className="text-start text-[14px] font-medium my-2">
                  Phone Number
                </div>
                <div>
                  <CiCircleAlert />
                </div>
              </div>

              <div className="w-full px-8 py-2 bg-[#F5F5F5] border my-2 rounded-md text-start">
                <PhoneInput
                  className="text-start"
                  containerStyle={{
                    width: "100%",
                    backgroundColor: "transparent",
                    borderBottom: "1px solid #F5F5F5",
                  }}
                  inputStyle={{
                    backgroundColor: "transparent",
                    width: "100%",
                    border: "none",
                    outline: "none",
                    fontSize: "16px",
                    color: "#000",
                  }}
                  buttonStyle={{
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  country={"pk"}
                  placeholder={"Phone"}
                  inputProps={{ required: true }}
                  type={"tel"}
                  name="phone"
                  value={formData.phone}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      phone: e,
                    });
                  }}
                />
              </div>
              {errors.phone && (
                <div className="text-red-500 text-start text-sm">
                  {errors.phone[0]}
                </div>
              )}
              <div className="flex justify-between items-center">
                <div className="text-start text-[14px] font-medium my-2">
                  Select Class
                </div>
                <div>
                  <CiCircleAlert />
                </div>
              </div>
              
              <select
                className="w-full px-8 py-3 bg-[#F5F5F5] border my-2 rounded-md text-[#B5B7C0]"
                onChange={handleChange}
                name="class_id"
                containerStyle={{
                  width: "100%",
                  backgroundColor: "transparent",
                  borderBottom: "1px solid #F5F5F5",
                }}
                inputStyle={{
                  backgroundColor: "transparent",
                  width: "100%",
                  border: "none",
                  outline: "none",
                  fontSize: "16px",
                  color: "#000",
                }}
                buttonStyle={{
                  border: "none",
                  backgroundColor: "transparent",
                }}>
                <option value={JSON.stringify({})}>Select Class</option>
                {classes.map((classItem, index) => (
                  <option key={index} value={classItem.id}>
                    {classItem.class_name}
                  </option>
                ))}
              </select>

              <div className="text-start text-[14px] font-medium my-2">
                Password
              </div>
              <div className="relative">
                <input
                  className="w-full px-8 py-3 bg-[#F5F5F5] border my-2 rounded-md pr-12"
                  type={showPassword ? "text" : "password"}
                  placeholder="Type your password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 px-3 flex items-center"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <RxEyeOpen className="h-5" />
                  ) : (
                    <VscEyeClosed className="h-5" />
                  )}
                </button>
              </div>
              {errors.password && (
                <div className="text-red-500 text-start text-sm">
                  {errors.password[0]}
                </div>
              )}

              <div className="text-start text-[14px] font-medium my-2">
                Confirm Password
              </div>
              <div className="relative">
                <input
                  className="w-full px-8 py-3 bg-[#F5F5F5] border my-2 rounded-md pr-12"
                  type={showPassword ? "text" : "password"}
                  placeholder="Type your password"
                  name="password_confirmation"
                  value={formData.password_confirmation}
                  onChange={handleChange}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 px-3 flex items-center"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <RxEyeOpen className="h-5" />
                  ) : (
                    <VscEyeClosed className="h-5" />
                  )}
                </button>
              </div>
              {errors.password_confirmation && (
                <div className="text-red-500 text-start text-sm">
                  {errors.password_confirmation[0]}
                </div>
              )}

              {errors.general && (
                <div className="text-red-500 text-center text-sm mb-4">
                  {errors.general}
                </div>
              )}

<button
                className={`w-full rounded-md bg-[#09A48C] text-[20px] font-medium text-white text-center py-4 my-2 flex items-center justify-center ${
                  isLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={isLoading} // Disable button while loading
              >
                {isLoading ? (
                  <svg
                    className="animate-spin h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  "Create Account"
                )}
              </button>
              <div className="text-[18px] font-medium mt-[20px]">
                <p>
                  I already have an account.{" "}
                  <Link to={props.path}>
                    <span className="text-[#66BCE8] cursor-pointer">Login</span>
                  </Link>
                </p>
              </div>
              {/* <div className="text-[18px] font-medium mt-[30px]">
                <p>Or Sign Up As</p>
              </div>
              <div className="flex justify-center items-center mt-8">
                <button className="flex w-auto px-8 rounded-md bg-[#fff] text-[20px] font-medium text-[#000] shadow-xl b text-center py-4 my-2">
                  <img src={google} alt="" className="mx-4 mt-1" />
                  Sign Up with Google
                </button>
              </div>
              <div className="flex justify-center items-center mt-5">
                <button className="flex w-auto px-8 rounded-md bg-[#fff] text-[20px] font-medium text-[#000] shadow-xl b text-center py-4 my-2">
                  <img src={facebook} alt="" className="mx-4 mt-1" />
                  Sign Up with Facebook
                </button>
              </div> */}
            </form>
          </div>
        </div>
      </div>
      <div
        className="w-full sm:w-1/2"
        style={{ backgroundColor: `rgba(9, 164, 140, 0.07)` }}
      >
        <img className="w-[] h-[]" src={FormImage} alt="" />
        <div className="flex justify-center">
          <img className="w-[284px] h-[254px]" src={props.Image} alt="" />
        </div>
        <div className="flex flex-wrap items-center justify-center p-4 md-1 md:mb-[170px]">
          <div className="w-full sm:w-10/12 p-4 text-center">
            <div className="flex justify-center text-[24px] font-semibold">
              <p className="text-center">Hey there!!</p>
            </div>
            <div className="flex justify-center text-[24px] font-semibold">
              <p className="text-center">{props.Description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentFormSignUp;
