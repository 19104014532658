import React from 'react'
import StudentFormLogin from '../../components/StudentFormLogin'
import Login1 from "../../assets/Login1.png";

const StudentLogin = () => {
  return (
    <div>
      <StudentFormLogin
       Description="Where you left off on your learning journey. Your personalized dashboard awaits, ready to empower you with the tools and resources you need to succeed."
       Image={Login1}
       path='.././studentSignUp'
       forgetpath='.././forgetPass'
       
      />
    </div>
  )
}

export default StudentLogin
