import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import apiKey from "../../../config/apiKey";
import { FaPaperPlane } from "react-icons/fa"; // Example icons
import { getHitID } from "../../../config/HitIDStorage";
const DEFAULT_IMAGE_URL = `${apiKey}/userProfile/87654321.png`;

const StudentChat = () => {
  const admin = JSON.parse(localStorage.getItem("edo-admin"));
  const ID = getHitID();
  const user = {
    id: admin ? admin.admin.id : "",
  };
  // const [studentId, setStudentId] = useState(null);
  const [messagesByTeacher, setMessagesByTeacher] = useState([]);
  const [selectedTeacherId, setSelectedTeacherId] = useState(null);
  const [selectedTeacherName, setSelectedTeacherName] = useState("");
  const [selectedTeacherImage, setSelectedTeacherImage] = useState("");
  const [selectedMessages, setSelectedMessages] = useState([]);
 
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const fetchMessages = () => {
      axios
        .get(`${apiKey}/api/edolutions/admin/showmessagestudent/${ID}`, {
          headers: {
            Authorization: `Bearer ${admin?.token}`,
          },
        })
        .then((res) => {
          // setStudentId(res.data.student_id);
          setMessagesByTeacher(res.data.messages_by_teacher);

          if (selectedTeacherId) {
            const teacherMessages = res.data.messages_by_teacher.find(
              (teacher) => teacher.teacher_id === selectedTeacherId
            );
            setSelectedMessages(
              teacherMessages
                ? teacherMessages.messages.map((msg) => ({
                    ...msg,
                    teacher_name: teacherMessages.teacher_name, // Ensure teacher_name is included
                  }))
                : []
            );
            setSelectedTeacherName(
              teacherMessages ? teacherMessages.teacher_name : ""
            );
            setSelectedTeacherImage(
              teacherMessages
                ? teacherMessages.teacher_photo
                  ? `${apiKey}/userProfile/${teacherMessages.teacher_photo}`
                  : DEFAULT_IMAGE_URL
                : DEFAULT_IMAGE_URL
            );
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };

    fetchMessages();
    // const intervalId = setInterval(fetchMessages, 3000);

    // return () => clearInterval(intervalId);
  }, [user.id, selectedTeacherId, admin?.token]);

  useEffect(() => {
    // Scroll to bottom of the messages container when new messages are added or fetched
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedMessages]);

  const handleTeacherClick = (teacherId) => {
    const teacherMessages = messagesByTeacher.find(
      (teacher) => teacher.teacher_id === teacherId
    );
    setSelectedTeacherId(teacherId);
    setSelectedMessages(
      teacherMessages
        ? teacherMessages.messages.map((msg) => ({
            ...msg,
            teacher_name: teacherMessages.teacher_name, // Ensure teacher_name is included
          }))
        : []
    );
    setSelectedTeacherName(teacherMessages ? teacherMessages.teacher_name : ""); // Set selected teacher name
    setSelectedTeacherImage(
      teacherMessages
        ? teacherMessages.teacher_photo
          ? `${apiKey}/userProfile/${teacherMessages.teacher_photo}`
          : DEFAULT_IMAGE_URL
        : DEFAULT_IMAGE_URL
    ); // Set teacher image
  };

  const [searchQuery, setSearchQuery] = useState("");
  const filteredTeachers = messagesByTeacher.filter((teacher) =>
    teacher.teacher_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="flex h-screen bg-gray-50">
      {/* Teacher List */}
      <div className="w-1/4 bg-white border-r border-gray-300 p-4 overflow-y-auto">
        <h2 className="text-2xl font-bold mb-4 text-gray-700">Teachers</h2>
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search teachers..."
          className="mb-4 w-full border border-gray-300 rounded-lg p-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <h6 className="text-sm text-gray-400">Chats</h6>
        <ul>
          {filteredTeachers.map((teacher) => (
            <li
              key={teacher.teacher_id}
              onClick={() => handleTeacherClick(teacher.teacher_id)}
              className={`p-3 mb-3 cursor-pointer rounded-lg transition-colors duration-300
                ${
                  teacher.teacher_id === selectedTeacherId
                    ? "bg-gray-200 text-blue-600"
                    : "hover:bg-gray-100"
                } flex items-center gap-3`}
            >
              <img
                src={
                  teacher.teacher_photo
                    ? `${apiKey}/userProfile/${teacher.teacher_photo}`
                    : DEFAULT_IMAGE_URL
                }
                onError={(e) => (e.target.src = DEFAULT_IMAGE_URL)} // Set default image on error
                alt={teacher.teacher_name}
                className="w-10 h-10 rounded-full object-cover"
              />
              <span className="font-semibold">{teacher.teacher_name}</span>
             
            </li>
          ))}
        </ul>
      </div>

      {/* Message Box */}
      <div className="flex-1 flex flex-col bg-gray-200">
        <div className="flex-1 flex flex-col bg-white p-1 shadow-lg overflow-hidden">
          <div className="bg-gray-100 p-4 rounded-t-lg shadow-sm flex items-center gap-3">
            <img
              src={
                selectedTeacherImage ? selectedTeacherImage : DEFAULT_IMAGE_URL
              }
              onError={(e) => (e.target.src = DEFAULT_IMAGE_URL)} // Set default image on error
              alt={selectedTeacherName}
              className="w-12 h-12 rounded-full object-cover"
            />
            <h2 className="text-2xl font-bold text-gray-700">
              {selectedTeacherId
                ? `${selectedTeacherName}`
                : "Select a Teacher"}
            </h2>
          </div>
          <div className="flex-1 overflow-y-auto p-4">
            {selectedMessages.length === 0 ? (
              <div className="flex flex-col items-center justify-center h-full text-gray-500">
                <FaPaperPlane className="text-6xl text-[#4FD1C5] mb-4" />
                <p className="text-[#4FD1C5]">
                  No messages yet. Start a conversation!
                </p>
              </div>
            ) : (
              selectedMessages.map((message) => (
                <div
                  key={message.id}
                  className={`mb-4 p-3 rounded-lg shadow-sm 
                    ${
                      message.student_message
                        ? "bg-gray-100 text-black text-left"
                        : "bg-[rgb(187,222,251,0.2)] text-black text-right"
                    }`}
                >
                  <p className="font-semibold">
                    {message.student_message
                      ? "Student"
                      : `Teacher (${message.teacher_name})`}
                    :
                  </p>
                  <p>{message.student_message || message.teacher_message}</p>
                  <p className="text-xs text-gray-500 text-right">
                    {new Date(message.created_at).toLocaleString("en-US", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false, // Use 24-hour time format
                    })}
                  </p>
                </div>
              ))
            )}
            {/* Scroll reference */}
            <div ref={messagesEndRef} />
          </div>
        </div>

      
      </div>
    </div>
  );
};

export default StudentChat;
