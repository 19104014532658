import React from "react";
import AboutLanding from "../components/AboutLnding";
import Card from "../components/Card";
import Edolcards from "../components/Edolcards";
import picture from '../assets/ourMisi.png'
import vision from '../assets/ourVision.png'
import impact from '../assets/impact.png'
import involved from '../assets/involved.png'
import ChooseMe from "../components/ChooseME";
import Boards from "../components/Boards";


const AboutUs = () => {
  return (
    <>
      <AboutLanding />
      <Card
        Title="Our Story"
        discription2="Edolutions was founded by Talha Sami and Rafay Shaikh, inspired by Talha Sami’s early experience as a tutor during his A-Levels. As his tutoring network expanded, he realized the potential to create a more structured platform to connect students and tutors more effectively. Initially, he started by building a network on WhatsApp, bringing together tutors and students. Seeing the success of this model, he decided to take it further by creating a global platform with a dedicated website, leading to the birth of Edolutions. The goal was to make education more accessible and flexible by providing students the opportunity to learn from home or online, connecting them with the right tutors worldwide"
      />

      <Edolcards
        Title="Our Mission"
        Description="Edolutions is to make education more accessible, personalized, and flexible for students worldwide. We aim to connect learners with qualified tutors who can provide tailored support, helping students achieve their academic goals and reach their full potential. By leveraging technology, we strive to create a global learning community where education is not bound by location, allowing students to learn at their own pace, anytime and anywhere."
        Image={picture}
      />
      <Edolcards
        Title1="Our Vision"
        Description1="By providing a platform that emphasizes personalized, flexible, and accessible education, we aim to break down traditional barriers to learning. Our vision is to create a world where every student has the opportunity to learn from the best tutors."
        Image1={vision}
        paragraph="We aspire to create an educational paradigm where students can thrive in environments tailored to their unique needs and learning styles. Ultimately, we hope to contribute to a future where education is a universal right, empowering students everywhere to achieve their fullest potential and positively impact their communities and the world"
      />

      <ChooseMe />

      <Edolcards
        Title="Our Impact"
        Description="Edolutions has connected over 10,000 students with expert tutors, creating a positive impact on their academic performance and confidence. Our platform supports tutors by providing flexible opportunities to earn and grow professionally. Through our global reach, we are helping to make quality education accessible to everyone, no matter their location or background."
        Image={impact}
      />
       
      <Edolcards
        Title1="Get Involved"
        Description1="Join our mission to make education accessible to all. Whether you’re a student seeking academic support, a tutor looking to share your knowledge, or an advocate of education looking to contribute, there’s a place for you at Edolutions. Sign up today, refer friends, or explore partnership opportunities with us to make a difference in education worldwide."
        Image1={involved}
      />

      <Boards/>

    </>
  );
};

export default AboutUs;
