
import React, { useState } from "react";
import { CiSquarePlus } from "react-icons/ci";
import "react-phone-input-2/lib/style.css";
import { NavLink } from "react-router-dom";


const SubjectWP = () => {
 
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
 

 
  return (
    <>
      <div className="flex items-center xl:p-5 sm:p-0 justify-center">
        <div className="w-full border rounded-3xl shadow-xl p-5 px-10">
          <div className="mt-3  flex  items-center">
            <div>
              {" "}
              <p className="text-2xl font-semibold ">Settings</p>
            </div>
          </div>

          {/* tableee----------- */}
          <div className="flex  flex-wrap md:justify-start justify-center  space-x-5  border-b-2 text-base font-medium my-5">
            <NavLink
              to=".././profileSettingTech"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              Profile Settings
            </NavLink>

            <NavLink
              to=".././subjectTech"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              Subjects & WP
            </NavLink>

            <NavLink
              to=".././otherDetail"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              Other Details
            </NavLink>

            <NavLink
              to=".././security"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              Privacy & Security
            </NavLink>

            {/* <NavLink
              to=".././techerNotiSetting"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              Notification
            </NavLink> */}
          </div>
          {/* end---------- */}

          <div className="md:flex  flex-wrap justify-between">
            <div className="mt-5">
              <p className="text-[22px] font-semibold ">
                Subjects & Work Preferences
              </p>
            </div>
            <div className="flex space-x-5 mt-5 cursor-pointer ">
              <p className="text-[11px] text-[#B5B7C0] border border-[#B5B7C0] px-6 py-2  rounded-lg">
                Cancel
              </p>
              <p className="text-[11px]  text-[#5963F5] border border-[#5963F5] px-6 py-2 rounded-lg">
                Save
              </p>
            </div>
          </div>

          <div className="md:w-1/2 w-full">
            <div className="mt-5">
              <p className="text-xl font-semibold ">Main & Others Subjects</p>
              <p className="text-lg font-semibold mt-5 ">Main Subject</p>
            </div>
            <form action="">
              <div className="md:flex block md:space-y-0 space-y-3 mt-5 gap-10 w-1/2">
                <div className="flex-1 bg-[#F9FBFF] border border-[#F9FBFF] rounded-lg px-1 ">
                  <select
                    className="outline-none px-1 py-2 focus-none  w-full bg-[#F9FBFF] cursor-pointer"
                    placeholder="Country"
                    type="text"
                  >
                    <option value="">Select Subject</option>
                    <option>Math</option>
                    <option>English</option>
                    <option>Chemistry</option>
                  </select>
                </div>
              </div>
            </form>
          </div>

          <div className="md:w-1/2 w-full">
            <p className="text-lg font-semibold mt-5 ">
              Are you willing to teacher main subject
            </p>
            <div className="flex justify-between mt-4 mx-3">
              <div className="flex items-center">
                <input
                  id="radio1"
                  type="radio"
                  name="radio-group"
                  value="online"
                  checked={selectedOption === "online"}
                  onChange={() => setSelectedOption("online")}
                  className="form-radio h-4 w-4 text-blue-600"
                />
                <label
                  for="radio1"
                  className="ml-2 text-gray-700 text-base font-medium"
                >
                  Online
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="radio2"
                  type="radio"
                  name="radio-group"
                  value="home"
                  checked={selectedOption === "home"}
                  onChange={() => setSelectedOption("home")}
                  className="form-radio h-4 w-4 text-blue-600"
                />
                <label
                  for="radio2"
                  className="ml-2 text-gray-700 text-base font-medium"
                >
                  At Home
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="radio3"
                  type="radio"
                  name="radio-group"
                  value="both"
                  checked={selectedOption === "both"}
                  onChange={() => setSelectedOption("both")}
                  className="form-radio h-4 w-4 text-blue-600"
                />
                <label
                  for="radio3"
                  className="ml-2 text-gray-700 text-base font-medium"
                >
                  Both
                </label>
              </div>
            </div>
            {selectedOption === "online" && (
              <div>
                <p className="text-lg mt-2 font-medium">
                  Charges Range (Online)
                </p>
                <input
                  type="text"
                  name=""
                  id="onlineRange"
                  className="text-[#B5B7C0] text-sm dropdown-select w-full mt-2 rounded-lg outline-none bg-[#F9FBFF] px-5 py-2"
                  placeholder="per hours"
                />
              </div>
            )}

            {selectedOption === "home" && (
              <div>
                <p className="text-lg mt-2 font-medium">
                  Charges Range (At Home)
                </p>
                <input
                  type="text"
                  name=""
                  id="homeRange"
                  className="text-[#B5B7C0] text-sm dropdown-select w-full mt-2 rounded-lg outline-none bg-[#F9FBFF] px-5 py-2"
                  placeholder="per hours"
                />
              </div>
            )}

            {selectedOption === "both" && (
              <>
                <div>
                  <p className="text-lg mt-2 font-medium">
                    Charges Range (Online)
                  </p>
                  <input
                    type="text"
                    name=""
                    id="onlineRange"
                    className="text-[#B5B7C0] text-sm dropdown-select w-full mt-2 rounded-lg outline-none bg-[#F9FBFF] px-5 py-2"
                    placeholder="per hours"
                  />
                </div>
                <div>
                  <p className="text-lg mt-2 font-medium">
                    Charges Range (At Home)
                  </p>
                  <input
                    type="text"
                    name=""
                    id="homeRange"
                    className="text-[#B5B7C0] text-sm dropdown-select w-full mt-2 rounded-lg outline-none bg-[#F9FBFF] px-5 py-2"
                    placeholder="per hours"
                  />
                </div>
              </>
            )}
          </div>

          <div className="md:w-1/2 my-8 w-full  flex justify-between items-center ">
            <div>
              {" "}
              <p className="text-lg font-semibold ">Other Subjects</p>
            </div>

            <div className="flex space-x-5" onClick={() => setShowModal(true)}>
              <CiSquarePlus className=" text-[25px] text-[#5963F5] cursor-pointer" />
            </div>
          </div>
        </div>
      </div>

      {/* modal */}
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative mx-auto md:w-2/5">
              {/* {/content/} */}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none overflow-y-auto shiftmodel p-5 ">
                {/* {/header/} */}
                <div className="flex items-start justify-center  border-solid border-blueGray-200 rounded-t">
                  <button
                    className=" ml-auto  float-right text-2xl"
                    onClick={() => setShowModal(false)}
                  >
                    <span> × </span>
                  </button>
                </div>

                <div className="flex justify-center">
                  <h1 className="text-2xl font-medium">Edit Subject</h1>
                </div>

                <div className="lg:w-2/3 w-full m-auto">
                  <div>
                    <p className="text-lg mt-2 font-medium">Subject</p>
                  </div>
                  <div>
                    <select className="text-[#B5B7C0] text-sm dropdown-select w-full mt-2 rounded-lg outline-none bg-[#F9FBFF] px-5 py-2">
                      <option value="">Select Class</option>
                      <option>Math</option>
                      <option>Science</option>
                      <option>History</option>
                    </select>
                  </div>
                  <div>
                    <p className="text-lg mt-2 font-medium">
                      Are you willing to teacher main subject
                    </p>
                  </div>
                  <div className="flex justify-between mt-4 mx-3">
                    <div className="flex items-center">
                      <input
                        id="radio1"
                        type="radio"
                        name="radio-group"
                        value="online"
                        checked={selectedOption === "online"}
                        onChange={() => setSelectedOption("online")}
                        className="form-radio h-4 w-4 text-blue-600"
                      />
                      <label
                        for="radio1"
                        className="ml-2 text-gray-700 text-base font-medium"
                      >
                        Online
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="radio2"
                        type="radio"
                        name="radio-group"
                        value="home"
                        checked={selectedOption === "home"}
                        onChange={() => setSelectedOption("home")}
                        className="form-radio h-4 w-4 text-blue-600"
                      />
                      <label
                        for="radio2"
                        className="ml-2 text-gray-700 text-base font-medium"
                      >
                        At Home
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="radio3"
                        type="radio"
                        name="radio-group"
                        value="both"
                        checked={selectedOption === "both"}
                        onChange={() => setSelectedOption("both")}
                        className="form-radio h-4 w-4 text-blue-600"
                      />
                      <label
                        for="radio3"
                        className="ml-2 text-gray-700 text-base font-medium"
                      >
                        Both
                      </label>
                    </div>
                  </div>
                  {selectedOption === "online" && (
                    <div>
                      <p className="text-lg mt-2 font-medium">
                        Charges Range (Online)
                      </p>
                      <input
                        type="text"
                        name=""
                        id="OnlineRange"
                        className="text-[#B5B7C0] text-sm dropdown-select w-full mt-2 rounded-lg outline-none bg-[#F9FBFF] px-5 py-2"
                        placeholder="per hours"
                      />
                    </div>
                  )}

                  {selectedOption === "home" && (
                    <div>
                      <p className="text-lg mt-2 font-medium">
                        Charges Range (At Home)
                      </p>
                      <input
                        type="text"
                        name=""
                        id="HomeRange"
                        className="text-[#B5B7C0] text-sm dropdown-select w-full mt-2 rounded-lg outline-none bg-[#F9FBFF] px-5 py-2"
                        placeholder="per hours"
                      />
                    </div>
                  )}

                  {selectedOption === "both" && (
                    <>
                      <div>
                        <p className="text-lg mt-2 font-medium">
                          Charges Range (Online)
                        </p>
                        <input
                          type="text"
                          name=""
                          id="Online_range"
                          className="text-[#B5B7C0] text-sm dropdown-select w-full mt-2 rounded-lg outline-none bg-[#F9FBFF] px-5 py-2"
                          placeholder="per hours"
                        />
                      </div>
                      <div>
                        <p className="text-lg mt-2 font-medium">
                          Charges Range (At Home)
                        </p>
                        <input
                          type="text"
                          name=""
                          id="Home_range"
                          className="text-[#B5B7C0] text-sm dropdown-select w-full mt-2 rounded-lg outline-none bg-[#F9FBFF] px-5 py-2"
                          placeholder="per hours"
                        />
                      </div>
                    </>
                  )}
                  <NavLink to=".././addSubject">
                    <div className="flex justify-center my-5 mt-8 cursor-pointer">
                      <p className="bg-blue-500 w-fit hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg ml-2 focus:outline-none focus:shadow-outline">
                        Save
                      </p>
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {/* modal-end */}
    </>
  );
};

export default SubjectWP;
