import React from "react";
import Verifi from "../../assets/Verification.png";

const Verification = () => {
  return (
    <div className="flex flex-wrap items-center ">
      <div className="w-full sm:w-1/2">
        <div className="flex justify-center">
          <img className="w-[284px] h-[254px]" src={Verifi} alt="" />
        </div>
      </div>
      <div className="w-full sm:w-1/2 ">
        <div className="flex flex-wrap  items-center justify-center p-4 bg-white ">
          <div className="w-full sm:w-12/12 p-4 text-center">
            <div className="text-[24px] font-semibold mt-[35px]">
              <p>Verification Your Number</p>
            </div>
            <div className="text-[16px] font-normal mt-3">
              <p>Enter OTP code sent to your number</p>
              <p>+921364584844</p>
            </div>
          </div>
        </div>
        {/* md-1 md:mb-[170px]   */}
        <div className="flex flex-wrap  items-center justify-center p-4 bg-white ">
          <div className="w-full sm:w-9/12 p-4 text-center">
            <form action="">
              <div className="text-start text-[14px] font-medium  my-2">
                Phone Number
              </div>
              <input
                className="w-full px-8 py-3 bg-[#F5F5F5] border  my-2 rounded-md"
                type="text"
                placeholder="+92"
                name=""
                id="phNo"
              />
              <div className="text-start text-[14px] font-medium my-2">OTP</div>
              <input
                className="w-full px-8 py-3 bg-[#F5F5F5] border  my-2 rounded-md"
                type="password"
                placeholder="Enter your OTP"
                name=""
                id="pw"
              />
              <button className="w-full rounded-md bg-[#5963F5] text-[20px] font-medium text-white text-center py-4 my-2">
                Verify
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verification;
