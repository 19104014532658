import React, { useState, useEffect } from "react";
import image from "../assets/whatwe.png";
import image2 from "../assets/2.png";
import image3 from "../assets/3.png";
import "../App.css";

const images = [image, image2, image3];
const texts = [
  "• Tailored Lessons to Suit Your Academic Needs.",
  "• Flexible In-person and Online Sessions.",
  "• Secure Payments Options and 24/7 Support Services."
];

const Offer = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="my-24">
      <div className="flex items-center justify-center">
        <div className="flex flex-wrap w-full max-w-6xl items-center justify-center p-4 ">
          <div className="w-full lg:w-7/12 md:w-7/12">
            <p className="text-[40px] font-bold leading-[40px] text-[#1E1E1E] small-heading medium-heading" data-aos="fade-left">
              What We Offer?
            </p>
            <div className="text-pretty mt-4">
              {texts.map((text, index) => (
                <p
                  key={index}
                  className="text-[20px] small-text font-normal leading-[27px] text-[#1E1E1E] my-2"
                >
                  {text}
                </p>
              ))}
            </div>
          </div>

          <div className="w-full lg:w-5/12 md:w-5/12 flex items-center justify-center">
            <div className="relative overflow-hidden w-full">
              <img
                src={images[currentIndex]}
                alt=""
                className="w-full h-auto transition-opacity duration-1000 ease-in-out"
                style={{ opacity: 1 }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offer;
