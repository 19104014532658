import React from 'react'
import { NavLink } from 'react-router-dom'
import TeacherCard from './TeacherCard'

function TeacherReport() {
  return (
    <>
    <div className="flex items-center xl:p-5 sm:p-0 justify-center">
      <div className="w-full border rounded-3xl shadow-xl p-5">

        <TeacherCard />
        <div className="flex flex-wrap md:justify-start justify-center space-x-5 border-b-2 text-base font-medium ">

          <NavLink to="../teacherDash" className={({ isActive }) => (isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined)}>Dashboard</NavLink>

          <NavLink to=".././teacherMessage" className={({ isActive }) => (isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined)}>View Profile</NavLink>

          <NavLink to=".././onlineTutoring" className={({ isActive }) => (isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined)}>Online Tutoring</NavLink>

          <NavLink to=".././homeTutoring" className={({ isActive }) => (isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined)}>Home Tutoring</NavLink>



          <NavLink to=".././teacherChat" className={({ isActive }) => (isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined)}>Messages</NavLink>

          <NavLink to=".././teacherReport" className={({ isActive }) => (isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined)}>Report</NavLink>
        </div>
        <div>
          <h1>Teacher Report</h1>
        </div>
      </div>
    </div>

  </>
  )
}

export default TeacherReport
