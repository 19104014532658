

// const apiKey = "http://127.0.0.1:8000"
// const apiKeyHttp = "127.0.0.1:8000"
// // const apiKey="https://api.edolutions.com"
// export default apiKey
// export {apiKeyHttp}


// const apiKey = "http://127.0.0.1:8000"
const apiKeyHttp = "127.0.0.1"
const apiKey="https://api.edolutions.com"
export default apiKey
export {apiKeyHttp}

// //const apiKey = "http://127.0.0.1:8000"
//  const apiKey="https://api.edolutions.com"
//  const apiKeyHttp="https://api.edolutions.com"
//  export default apiKey
//  export {apiKeyHttp}
