import React, { useEffect, useState } from 'react'
import { BsWhatsapp } from 'react-icons/bs'
import { NavLink } from 'react-router-dom'

function Whatup() {

  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;

    if (scrollTop > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };


  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div>
      {isVisible && (
      <NavLink to="https://wa.me/923313655666?text=Hi..." target='_blank'>
        <div  className="fixed z-50 bottom-20 right-10  bg-[#2DD0A5] text-white rounded-full p-2 w-12 h-12 flex items-center justify-center focus:outline-none shadow-3xl ">
      <BsWhatsapp className='w-8 h-8 flex items-center justify-center animate-bounce' />
        </div>
      </NavLink>
        )}
    </div>
  )
}

export default Whatup
