import React, { useState, useEffect } from "react";
import { FaRegUserCircle } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import axios from "axios";
import apiKey from "../../../config/apiKey";

function NewNotification() {
  const [notifications, setNotifications] = useState([]);
  const admin = JSON.parse(localStorage.getItem("edo-admin"));
  const [loading, setLoading] = useState(true); // Add loading state
  useEffect(() => {
    const fetchNotifications = async () => {
      setLoading(true); // Start loading before data fetch
      try {
        const response = await axios.get(
          `${apiKey}/api/edolutions/admin/notifications/new`,
          {
            headers: {
              Authorization: `Bearer ${admin?.token}`,
            },
          }
        );
        setNotifications(response.data);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      } finally {
        setLoading(false); // Stop loading after data fetch
      }
    };

    fetchNotifications();
  }, [admin?.token]);
  return (
    <div>
      <div>
        <div className="flex items-center xl:p-5 sm:p-0 justify-center">
          <div className="w-full border rounded-3xl shadow-xl p-5">
            <div>
              <p className="text-2xl font-bold">Notification</p>
              <p className="text-[#22B7AA] font-light text-sm">
                Admin Notification
              </p>
            </div>
            <div className=" flex border-b-2 justify-between items-center mt-8">
              <div className="flex flex-wrap md:justify-start md:space-x-12  space-x-5  text-base font-medium ">
                <NavLink
                  to=".././allNotification"
                  className={({ isActive }) =>
                    isActive
                      ? "text-blue-500 border-b-2 border-blue-500"
                      : undefined
                  }
                >
                  All
                </NavLink>

                <NavLink
                  to=".././newNotification"
                  className={({ isActive }) =>
                    isActive
                      ? "text-blue-500 border-b-2 border-blue-500"
                      : undefined
                  }
                >
                  New
                </NavLink>

                <NavLink
                  to=".././unreadNotification"
                  className={({ isActive }) =>
                    isActive
                      ? "text-blue-500 border-b-2 border-blue-500"
                      : undefined
                  }
                >
                  Unread
                </NavLink>
              </div>
              {/* <div>
                <p className="text-[12px] bg-[#FAFAF8] text-[#7E7E7E] font-semibold border-[#7E7E7E] p-1 rounded-lg cursor-pointer mb-2">
                  Mark All As Read
                </p>
              </div> */}
            </div>

            {loading ? (
              <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-200 bg-opacity-5 backdrop-blur-sm z-50">
                <div className="w-12 h-12 border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin border-t-[#2DD0A5]"></div>
                <p className="mt-4 text-lg font-semibold text-[#2dd0a5]">
                  Edolution...
                </p>
              </div>
            ) : notifications.length === 0 ? (
              <div className="py-5 px-1 text-center">
                <p className="text-gray-500">No Notification available</p>
              </div>
            ) : (
              notifications.map((notification) => (
                <div
                  key={notification.id}
                  className="grid gap-4 my-4 bg-[#A3E7E11C] p-4"
                >
                  <div className="flex gap-2">
                    <div>
                      <FaRegUserCircle className="h-8 w-8" />
                    </div>
                    <div>
                      <p className="text-[#2D3748] text-[14px] font-semibold">
                        {notification.message}
                      </p>
                      <span className="text-[12px] text-[#2D3748]">
                        {new Date(notification.created_at).toLocaleString()}
                      </span>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewNotification;
