import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import "./../../App.css";

export default function DataTable2({ heading, header, data, button, select }) {
  const [showModal, setShowModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);

  return (
    <div className="mt-2">
      <div className="flex flex-wrap items-center justify-between p-2">
        <div className="flex justify-between">
          <p className="text-[22px] font-bold ">{heading}</p>
        </div>

        {button &&
          button.map((index) => (
            <NavLink to={index.path}>
              <div className="border-2 border-[#5963F5] rounded-md px-3 py-2 flex gap-2">
                <div>
                  <FaPlus className="h-5 w-3 item-center fill-[#5963F5]" />
                </div>
                <button
                  className="text-[#5963F5] text-[14px] font-semibold"
                  onClick={() => setShowModal(true)}
                >
                  {index.name}
                </button>
              </div>
            </NavLink>
          ))}

        {select &&
          select.map((index) => (
            <div className="flex items-center">
              <div className="relative border shadow-md rounded-md flex items-center  px-5 py-2">
                <FiSearch className="h-5 w-5 absolute  text-[#5963F5] " />{" "}
                <input
                  type="text"
                  className="outline-none pl-8"
                  placeholder="Search"
                  name=""
                  id=""
                />
              </div>
              <div className="relative ml-2 ">
                <select
                  name=""
                  className="border shadow-md rounded-md cursor-pointer px-5 py-2 outline-none pl-2 pr-8 text-sm "
                >
                  <option value="">{index.option}</option>
                  <option value="">{index.option2}</option>
                  <option value="">{index.option3}</option>
                  <option value="">{index.option4}</option>
                  <option value="">{index.option5}</option>
                </select>
              </div>
            </div>
          ))}
      </div>

      <div className="mt-5">
        <table className="w-full text-sm text-left text-gray-500 border-b table-auto">
          <thead className="text-sm text-[#B5B7C0] font-medium border-b">
            {header &&
              header.map((items) => (
                <tr key={items.id} className="border-b">
                  <td className="py-5 px-1">{items.thead}</td>
                  <td className="py-5 px-1">{items.thead2}</td>
                  <td className="py-5 px-1">{items.thead3}</td>
                  <td className="py-5 px-1">{items.thead4}</td>
                  <td className="py-5 px-1 ">{items.thead5}</td>
                  <td className="py-5 px-1 ">{items.thead6}</td>
                </tr>
              ))}
          </thead>
          <tbody className="text-black text-sm font-medium ">
            {data.map((item) => (
              <tr key={item.id} className="border-b">
                <td className="py-5 px-1">{item.column} </td>
                <td className="py-5 px-1">{item.column2}</td>
                <td className="py-5 px-1">{item.column3}</td>
                <td className="py-5 px-1 ">{item.column4} </td>
                <td className="py-5 px-1 ">{item.column5} </td>
                <NavLink to={item.path}>
                  <td className="py-5 px-1 cursor-pointer">
                    <p
                      className={`border cursor-pointer px-5 rounded-md py-1 w-fit ${
                        item.column6 === "Active"
                          ? "text-[#5963F5] border-[#5963F5]"
                          : item.column6 === "Inactive"
                          ? "text-red-500 border-red-500"
                          : ""
                      }`}
                    >
                      {item.column6}
                    </p>
                  </td>
                </NavLink>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* 1-modal------------------------- */}
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative mx-auto w-1/3">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none overflow-y-auto shiftmodel">
                {/*header*/}
                <div className="items-start justify-center p-5 border-solid border-blueGray-200 rounded-t">
                  <button
                    className=" ml-auto  float-right text-2xl"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="text-[#000]"> × </span>
                  </button>
                </div>

                <div className="text-center">
                  <h1 className="text-[24px] font-semibold text-[#000]">
                    Enter Class Details
                  </h1>
                  <p className="text-[12px] font-semibold text-[#2D3748]">
                    {" "}
                    Lorem Ipsum is simply dummy text of the printing.
                  </p>
                </div>

                {/*body*/}
                <div className="relative p-4 text-start h-60 ">
                  <div className="text-center my-4">
                    <input
                      type="date"
                      placeholder="Select Date"
                      className="input-date border-2 w-80  px-6 py-2 rounded-md text-gray-700 focus:outline-none focus:border-[#2BACE2] date-icon-color"
                    />
                  </div>
                  <div className=" text-center my-4">
                    <select
                      placeholder="Select Subject"
                      name="subject"
                      className="border-2  w-80 px-6 py-2 rounded-md text-gray-700 focus:outline-none focus:border-[#2BACE2]"
                    >
                      <option value="">Select</option>
                      <option value="">Math</option>
                      <option value="">English</option>
                    </select>
                  </div>
                  <div className=" text-center my-4">
                    <select
                      name="student"
                      className="border-2  w-80 px-6 py-2 rounded-md text-gray-700 focus:outline-none focus:border-[#2BACE2]"
                    >
                      <option value="">Select</option>
                      <option value="">Muhammad Sohail</option>
                      <option value="">Muhammad Muslim</option>
                      <option value="">Irfan Ahmed</option>
                    </select>
                  </div>
                </div>
                <div className="text-center mb-7">
                  <button
                    className="font-semibold text-white text-[20px]  border-2 rounded-lg bg-[#5963F5] px-6 cursor-pointer py-3"
                    onClick={() => setShowSecondModal(true)}
                  >
                    Generate Link
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      {/* 2-modal-------------------------------- */}

      {showSecondModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative mx-auto w-1/3">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none overflow-y-auto shiftmodel">
                {/*header*/}
                <div className="items-start justify-center p-5 border-solid border-blueGray-200 rounded-t">
                  <button
                    className=" ml-auto  float-right text-2xl"
                    onClick={() => setShowSecondModal(false)}
                  >
                    <span className="text-[#000]"> × </span>
                  </button>
                </div>

                <div className="text-center">
                  <h1 className="text-[24px] font-semibold text-[#000]">
                    Class Zoom Link
                  </h1>
                  <p className="text-[12px] font-semibold text-[#2D3748]">
                    {" "}
                    Lorem Ipsum is simply dummy text of the printing.
                  </p>
                </div>

                {/*body*/}
                <div className="relative p-4 text-start h-60 ">
                  <div className=" text-center my-4">
                    <h1>Link Here</h1>
                  </div>
                </div>
                <div className="text-center mb-7">
                  <button className="font-semibold text-white text-[20px]  border-2 rounded-lg bg-[#5963F5] px-6 cursor-pointer py-3">
                    Send the Students
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
}
