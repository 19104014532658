import React from "react";
import { Link, useParams } from "react-router-dom";
import logo from "../assets/logo.png";
import Login1 from "../assets/Login1.png";
import Login3 from "../assets/Login3.png";
import LoginNew from "../assets/LoginNew.webp";
import line1 from "../assets/Vector 77.png";
import line2 from "../assets/Vector 76.png";

const Login = () => {
  // Extract the 'type' parameter from the URL
  const { type } = useParams();

  // Determine the link paths based on the type parameter
  const studentLink = type === "Signup" ? "../studentSignUp" : "../studentLogin";
  const teacherLink = type === "Signup" ? "../teacherSignUp" : "../teacherLogin";

  return (
    <div className="relative justify-center">
      <div className="relative items-center justify-center">
        <div className="flex flex-wrap items-center justify-center py-4">
          <div className="w-full sm:w-12/12 text-center">
            <div className="flex justify-center">
              <Link to="/">
                <img className="w-[310px]" src={logo} alt="Logo" />
              </Link>
            </div>
            <div className="flex justify-center">
              <div className="text-[25px] w-fit font-semibold mt-5">
                <p>
                  Start Building A Better <br /> Connection Today
                </p>
                <div className="relative left-6">
                  <img src={line1} alt="Line 1" />
                  <img src={line2} alt="Line 2" />
                </div>
              </div>
            </div>
            <div className="text-[16px] font-normal mt-3">
              <p>Create your free account now</p>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap items-end justify-center px-6 bg-white">
          <div className="w-full flex-col justify-center sm:w-6/12 p-10">
            <Link to={studentLink}>
              <div className="border-2 shadow-lg hover:shadow-xl hover:border-[#4fd1c5] cursor-pointer p-6 rounded-lg transition-all duration-300 ease-in-out transform hover:scale-105">
                <div className="flex justify-center">
                  <img
                    className="w-[180px] rounded-lg transition-transform duration-300 ease-in-out transform hover:scale-105"
                    src={Login1}
                    alt="Student"
                  />
                </div>
                <div className="flex justify-center mt-6">
                  <p className="text-2xl font-semibold border-2 rounded shadow-lg px-2 py-1 text-black hover:text-[#38B2AC] hover:border-[#4fd1c5] transition-colors duration-300">
                    {type === "signup" ? "Sign Up as Student" : "I'm a Student"}
                  </p>
                </div>
              </div>
            </Link>
          </div>

          <div className="w-full flex-col justify-center sm:w-6/12 p-10 -mt-6 md:border-l-4">
            <Link to={teacherLink}>
              <div className="border-2 shadow-lg hover:shadow-xl hover:border-[#4fd1c5] cursor-pointer p-6 rounded-lg transition-all duration-300 ease-in-out transform hover:scale-105">
                <div className="flex justify-center">
                  <img
                    className="w-[180px] rounded-lg transition-transform duration-300 ease-in-out transform hover:scale-105"
                    src={LoginNew}
                    alt="Teacher"
                  />
                </div>
                <div className="flex justify-center mt-6">
                  <p className="text-2xl font-semibold border-2 rounded shadow-lg px-2 py-1 text-black hover:text-[#38B2AC] hover:border-[#4fd1c5] transition-colors duration-300">
                    {type === "signup" ? "Sign Up as Teacher" : "I'm a Teacher"}
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </div>

        <div className="flex flex-wrap items-center justify-center px-6 bg-white">
          <div className="w-full sm:w-12/12 p-4 text-center">
            <div className="flex justify-center">
              <img src={Login3} alt="Join Us" />
            </div>
            <div className="text-[16px] font-normal text-[#5653FC]">
              <p>Are you a school leader?</p>
              <p>Join Edolutions to make your future</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
