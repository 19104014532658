import React, { useEffect } from "react";

import { ZoomMtg } from "@zoom/meetingsdk";
import apiKey from "../../../config/apiKey";
import { useLocation } from "react-router-dom";
import axios from "axios";

function ZoomMeetingPageStd() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const meetingNumber = queryParams.get("meetingNumber");
  const password = queryParams.get("password");
  const student = JSON.parse(localStorage.getItem("edo-student"));
  
  var authEndpoint = `${apiKey}/api/edolutions/student/generate-signature`;
  var sdkKey = "vF_zXfg7SRCIBbxozW5Dg";
  // var meetingNumber = '82157403197'
  var passWord = password;
  var role = 0;
  var userName = student?.student.name;
  var userEmail = student?.student.email;
  var registrantToken = "";
  var zakToken = "";
  var leaveUrl = `${window.location.origin}/student/myTutoring`;

  useEffect(() => {
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();
    function getSignature() {
      axios
        .post(
          authEndpoint,
          {
            meetingNumber: meetingNumber,
            role: role,
          },
          {
            headers: {
              Authorization: `Bearer ${student?.token}`,
            },
          }
        )
        .then((response) => {
          startMeeting(response.data.signature);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    function startMeeting(signature) {
      document.getElementById("zmmtg-root").style.display = "block";

      ZoomMtg.init({
        leaveUrl: leaveUrl,
        patchJsMedia: true,
        leaveOnPageUnload: true,
        success: (success) => {
          // console.log(success);

          ZoomMtg.join({
            signature: signature,
            sdkKey: sdkKey,
            meetingNumber: meetingNumber,
            passWord: passWord,
            userName: userName,
            userEmail: userEmail,
            tk: registrantToken,
            zak: zakToken,
            success: (success) => {
              // console.log(success);
            },
            error: (error) => {
              console.error(error);
            },
          });
        },
        error: (error) => {
          console.error(error);
        },
      });
    }

    getSignature();
  }, [
    authEndpoint,
    leaveUrl,
    meetingNumber,
    passWord,
    registrantToken,
    role,
    sdkKey,
    student?.token,
    userEmail,
    userName,
    zakToken,
  ]);

  return (
    <div className="App">
      <main>
        <h1>Zoom Meeting SDK Sample React</h1>

        {/* <button onClick={getSignature}>Join Meeting</button> */}
      </main>
    </div>
  );
}

export default ZoomMeetingPageStd;
