import React from 'react';
import TeacherTable from '../../../components/AdminTable/TeacherTable';

function Teacher() {
  const header = [
    { head1: 'Teacher Name', head2: 'Email', head3: 'Phone', head4: 'Type', head5: 'Status', head6: 'Visibility', head7:'Delete'}
  ];

  return (
    <main>
      <TeacherTable 
        heading={"Teachers"} 
        SubHeading={""}
        header={header}
      />
    </main>
  );

}

export default Teacher
