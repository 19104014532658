import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import apiKey from "../../../config/apiKey";
import { FaPaperPlane } from "react-icons/fa"; // Example icon
import Echo from "laravel-echo";
import Pusher from "pusher-js";

const DEFAULT_IMAGE_URL = `${apiKey}/userProfile/87654321.png`;

const TeacherChat = ({ setTotalUnreadCount }) => {
  const teacher = JSON.parse(localStorage.getItem("edo-teacher"));
  const user = {
    id: teacher ? teacher.teacher.id : "",
  };
  const [teacherId, setTeacherId] = useState(null);
  const [messagesByStudent, setMessagesByStudent] = useState([]);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [selectedStudentName, setSelectedStudentName] = useState("");
  const [selectedStudentImage, setSelectedStudentImage] =
    useState(DEFAULT_IMAGE_URL); // Default image URL
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [unreadCounts, setUnreadCounts] = useState({});

  const messagesEndRef = useRef(null);

  
  const markMessagesAsRead = useCallback((studentId) => {
    if (studentId)
    axios
      .post(
        `${apiKey}/api/edolutions/teacher/markMessagesAsRead/${teacherId}/${studentId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${teacher?.token}`,
          },
        }
      )
      .then((response) => {
        // console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [teacher?.token, teacherId]);

  useEffect(() => {
    const fetchMessages = () => {
      axios
        .get(`${apiKey}/api/edolutions/teacher/showmessage/${user.id}`, {
          headers: {
            Authorization: `Bearer ${teacher?.token}`,
          },
        })
        .then((res) => {
          setTeacherId(res.data.teacher_id);
          setMessagesByStudent(res.data.messages_by_student);

          // Calculate unread counts with the new condition
          const counts = {};
          let totalUnread = 0;
          res.data.messages_by_student.forEach((student) => {
            const unread = student.messages.filter(
              (msg) => msg.read === 0 && msg.student_message !== null
            ).length;
            counts[student.student_id] = unread;
            totalUnread += unread;
          });
          setUnreadCounts(counts);
          setTotalUnreadCount(totalUnread);

          if (selectedStudentId) {
            const studentMessages = res.data.messages_by_student.find(
              (student) => student.student_id === selectedStudentId
            );
            setSelectedMessages(
              studentMessages
                ? studentMessages.messages.map((msg) => ({
                    ...msg,
                    student_name: studentMessages.student_name,
                  }))
                : []
            );
            setSelectedStudentName(
              studentMessages ? studentMessages.student_name : ""
            );
            setSelectedStudentImage(
              studentMessages
                ? studentMessages.student_photo
                  ? `${apiKey}/studentProfile/${studentMessages.student_photo}`
                  : DEFAULT_IMAGE_URL
                : DEFAULT_IMAGE_URL
            );

            // Mark messages as read
            markMessagesAsRead();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };

    fetchMessages();
  }, [user.id, selectedStudentId, teacher?.token, setTotalUnreadCount, markMessagesAsRead]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedMessages]);

  //broadcasting connection
  useEffect(() => {
    window.Pusher = Pusher;
  
    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: 'b7245c5f531fd8d69ec7', // Pusher app key
      cluster: 'ap2', // Pusher app cluster (e.g., 'eu')
      forceTLS: true, // Pusher uses TLS by default
      encrypted: true, // Ensures connection encryption
      authEndpoint: `${apiKey}/broadcasting/auth`, // Same endpoint for Pusher
      auth: {
        headers: {
          Authorization: `Bearer ${teacher?.token}`,
        },
      },
    });
    
    window.Echo.private("chat-message-event.teacher." + user.id).listen(
      "ChatMessageTeacherEvent",
      (e) => {
        setMessagesByStudent((prevMessages) => {
          return prevMessages.map((student) => {
            if (student.student_id === e.data.student_id) {
              // Append the new message to the teacher's messages array
              return {
                ...student,
                messages: [...student.messages, e.data],
              };
            }
            // Return the teacher unchanged if it's not the correct teacher
            return student;
          });
        });

        if (selectedStudentId === e.data.student_id) {
          const studentMessages = messagesByStudent.find(
            (student) => student.student_id === e.data.student_id
          );

          setSelectedMessages(() => {
            let message = studentMessages
              ? studentMessages.messages.map((msg) => ({
                  ...msg,
                  student_name: studentMessages.student_name, // Ensure teacher_name is included
                }))
              : [];

            // Return the updated array with the new message appended
            return [
              ...message,
              { ...e.data, student_name: studentMessages.student_name },
            ];
          });
        }
      }
    );
  }, [messagesByStudent, selectedStudentId, teacher?.token, user]);

  const handleStudentClick = (studentId) => {
    const studentMessages = messagesByStudent.find(
      (student) => student.student_id === studentId
    );
    setSelectedStudentId(studentId);
    setSelectedMessages(
      studentMessages
        ? studentMessages.messages.map((msg) => ({
            ...msg,
            student_name: studentMessages.student_name,
          }))
        : []
    );
    setSelectedStudentName(studentMessages ? studentMessages.student_name : "");
    setSelectedStudentImage(
      studentMessages
        ? studentMessages.student_photo
          ? `${apiKey}/studentProfile/${studentMessages.student_photo}`
          : DEFAULT_IMAGE_URL
        : DEFAULT_IMAGE_URL
    );

    // Call markMessagesAsRead when a student is clicked
    markMessagesAsRead(studentId);
  };

  const handleSendMessage = () => {
    if (!newMessage.trim() || selectedStudentId === null) return;

    const newMsg = {
      id: Date.now(),
      teacher_message: newMessage,
      student_message: null,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      student_name: "",
    };

    setSelectedMessages((prev) => [...prev, newMsg]);
    setNewMessage("");

    const messageData = {
      teacher_id: teacherId,
      student_id: selectedStudentId,
      teacher_message: newMessage,
    };

    axios
      .post(
        `${apiKey}/api/edolutions/teacher/storemessageteacher`,
        messageData,
        {
          headers: {
            Authorization: `Bearer ${teacher?.token}`,
          },
        }
      )
      .then((res) => {
        setSelectedMessages((prev) =>
          prev.map((msg) => (msg.id === newMsg.id ? res.data.message : msg))
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const [searchQuery, setSearchQuery] = useState("");

  const filteredStudents = messagesByStudent.filter((student) =>
    student.student_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="flex h-screen bg-gray-50">
      {/* Student List */}
      <div className="w-1/4 bg-white border-r border-gray-300 p-4 overflow-y-auto">
        <h2 className="text-2xl font-bold mb-4 text-gray-700">Students</h2>
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search students..."
          className="mb-4 w-full border border-gray-300 rounded-lg p-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <h6 className="text-sm text-gray-400">Chats</h6>
        <ul>
          {filteredStudents.map((student) => (
            <li
              key={student.student_id}
              onClick={() => handleStudentClick(student.student_id)}
              className={`p-3 mb-3 cursor-pointer rounded-lg transition-colors duration-300 
        ${
          student.student_id === selectedStudentId
            ? "bg-gray-200 text-blue-600"
            : "hover:bg-gray-100"
        } flex items-center gap-3`}
            >
              <img
                src={
                  student.student_photo
                    ? `${apiKey}/studentProfile/${student.student_photo}`
                    : DEFAULT_IMAGE_URL
                }
                onError={(e) => (e.target.src = DEFAULT_IMAGE_URL)} // Set default image on error
                alt={student.student_name}
                className="w-10 h-10 rounded-full object-cover"
              />
              <div className="flex-1">
                <span className="font-semibold">{student.student_name}</span>
                {unreadCounts[student.student_id] > 0 && (
                  <span className="ml-2 text-xs bg-red-500 text-white px-2 py-1 rounded-full">
                    {unreadCounts[student.student_id]}
                  </span>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* Message Box */}
      <div className="flex-1 flex flex-col bg-gray-200">
        <div className="flex-1 flex flex-col bg-white p-1 shadow-lg overflow-hidden">
          <div className="bg-gray-100 p-4 rounded-t-lg shadow-sm flex items-center gap-3">
            <img
              src={
                selectedStudentImage ? selectedStudentImage : DEFAULT_IMAGE_URL
              }
              onError={(e) => (e.target.src = DEFAULT_IMAGE_URL)} // Set default image on error
              alt={selectedStudentName}
              className="w-12 h-12 rounded-full object-cover"
            />
            <h2 className="text-2xl font-bold text-gray-700">
              {selectedStudentId
                ? `${selectedStudentName}`
                : "Select a Student"}
            </h2>
          </div>
          <div className="flex-1 overflow-y-auto p-4">
            {selectedMessages.length === 0 ? (
              <div className="flex flex-col items-center justify-center h-full text-gray-500">
                <FaPaperPlane className="text-6xl text-[#4FD1C5] mb-4" />
                <p className="text-[#4FD1C5]">
                  No messages yet. Start a conversation!
                </p>
              </div>
            ) : (
              selectedMessages.map((message) => (
                <div
                  key={message.id}
                  className={`mb-4 p-3 rounded-lg shadow-sm 
                    ${
                      message.teacher_message
                        ? "bg-gray-100 text-black text-left"
                        : "bg-[rgb(187,222,251,0.2)] text-black text-right"
                    }`}
                >
                  <p className="font-semibold">
                    {message.teacher_message
                      ? "Teacher"
                      : `Student (${message.student_name})`}
                    :
                  </p>
                  <p>{message.teacher_message || message.student_message}</p>
                  <p className="text-xs text-gray-500 text-right">
                    {new Date(message.created_at).toLocaleString("en-US", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false, // Use 24-hour time format
                    })}
                  </p>
                </div>
              ))
            )}
            {/* Scroll reference */}
            <div ref={messagesEndRef} />
          </div>
        </div>

        {/* Input Field and Send Button */}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSendMessage();
          }}
          className="flex items-center gap-2 p-2 bg-white border-gray-300"
        >
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type your message..."
            className="flex-1 border border-gray-300 rounded-lg p-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#4FD1C5]"
          />
          <button
            type="submit"
            // onClick={handleSendMessage}
            className="bg-[#4FD1C5] text-white px-5 py-2 rounded-lg hover:bg-[#4FD1C5] transition-colors duration-300 flex items-center gap-2"
          >
            <FaPaperPlane />
            <span>Send</span>
          </button>
        </form>
      </div>
    </div>
  );
};

export default TeacherChat;
