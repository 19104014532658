import React from 'react'
import AdminLoginForm from '../../components/AdminLoginForm'
import admin from "../../assets/admin.png";

const StudentLogin = () => {
  return (
    <div>
      <AdminLoginForm
       Description="Where you left off on your learning journey. Your personalized dashboard awaits, ready to empower you with the tools and resources you need to succeed."
       Image={admin}
       path='.././studentSignUp'
       forgetpath='.././forgetPass'
       
      />
    </div>
  )
}

export default StudentLogin
