import React from "react";
import Img404 from "./../../assets/Error404.png"
import { Link } from "react-router-dom";

const Error404 = () => {
  return (
    <div>
      <div className="flex items-center xl:p-5 sm:p-0 justify-center">
        <div className="w-full  border rounded-3xl shadow-xl">
          <div className="flex justify-center items-center h-svh">
            <div className="flex flex-col justify-center items-center">
              <div>
                <img src={Img404} alt="" />
              </div>
              <div>
                <p className="text-center w-80 text-xl font-light">
                  Sorry, the content you’re looking for doesn’t exist. Either it
                  was removed, or you mistyped the link.
                </p>
              </div>
            
             <div className="flex space-x-3 cursor-pointer">
             <div>
              <Link to='../home'>
              <p className="border  border-blue-500 text-blue-500 w-fit px-6 py-2 rounded-lg mt-8 font-medium ">Go Back Home</p>
             </Link>
            </div>
            <div>
              <p className="border border-blue-500 text-blue-500 w-fit px-6 py-2 rounded-lg mt-8 font-medium ">Continue</p>
            </div>
             </div>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error404;
