import { ImUpload } from "react-icons/im";
import Diamond from "../../../assets/Diamond.png";
import { NavLink } from "react-router-dom";
import TutorTable from "../../../components/AdminTable/TutorTable";
import axios from "axios";
import apiKey from "../../../config/apiKey";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";

const Student = () => {
  const header = [
    {
      id: 1,
      thead: "Student ",
      thead2: "Class",
      thead3: "Subject ",
      thead6: "Status",
    },
  ];

  const data = [
    {
      id: 1,
      column: "Muhammad Sohail",
      column2: "10th",
      column3: "Chemistry",
      column6: "Active",
    },
    {
      id: 2,
      column: "Muslim Shaikh",
      column2: "9th",
      column3: "Math",
      column6: "Active",
    },
  ];

  const teacher = JSON.parse(localStorage.getItem("edo-teacher"));
  const [profileImage, setProfileImage] = useState(null);
  
  const handleImageUpload = (e) => {
    let formData = new FormData();
    formData.append("photo", e.target.files[0]);
  
    axios
      .post(`${apiKey}/api/edolutions/teacher/teacher-image-upload`, formData, {
        headers: {
          Authorization: `Bearer ${teacher?.token}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: {
            backgroundColor: "#4FD1C5", // Customize background color
            color: "#fff", // Customize text color
          },
        });
  
        // Fetch the updated profile image after successful upload
        axios
          .get(`${apiKey}/api/edolutions/teacher/teacher-profile`, {
            headers: {
              Authorization: `Bearer ${teacher?.token}`,
            },
          })
          .then((res) => {
            if (res.data[0]) {
              setProfileImage(res.data[0].photo_url);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.response.data.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  useEffect(() => {
    axios
      .get(`${apiKey}/api/edolutions/teacher/teacher-profile`, {
        headers: {
          Authorization: `Bearer ${teacher?.token}`,
        },
      })
      .then((res) => {
        if (res.data[0]) {
          // setFormData(res.data[0]);
          setProfileImage(res.data[0].photo_url);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [teacher?.token]);


   // name work
   const userId = {
    id: teacher ? teacher.teacher.id : "",
  };
  const [formUser, setFormUser] = useState({})
  useEffect(() => {
    axios
      .get(`${apiKey}/api/edolutions/teacher/getuser/${userId.id}`, {
        headers: {
          Authorization: `Bearer ${teacher?.token}`,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setFormUser(res.data);
      })
      .catch((err) => {
        console.error(err);
      });

  }, [teacher?.token,userId.id]);

  return (
    <div>
      <div className="flex items-center xl:p-5 sm:p-0 justify-center pb-12 ">
        <div className="flex flex-wrap w-full justify-center border rounded-3xl shadow-xl pb-12">
          <div className=" w-full xl:w-1/3 p-4 ">
            <div className="flex flex-col  items-center justify-center">
              <div className="">
              <img
                  className="rounded-full border w-40 h-40"
                  src={
                    profileImage
                      ? profileImage
                      : `${apiKey}/userProfile/87654321.png`
                  }
                  alt="Profile"
                />
              </div>
              <input
                onChange={handleImageUpload}
                type="file"
                id="uploadBtn"
                className=""
              />
              <label htmlFor="uploadBtn" className=" select-none	">
                <ImUpload className="w-6 h-6 cursor-pointer ml-32 -mt-6" />
              </label>
            </div>

           <div className="flex items-center justify-center text-3xl font-semibold mt-2">
           <p>{formUser.name}</p>
            </div>

            <div className="flex items-center justify-center text-xl mt-5 font-medium text-[#979CA5]">
              <p>Teacher</p>
            </div>

            <div className="mx-4">
              <hr className="h-0.5 mt-4 bg-[#979CA5]" />
            </div>

            <div className="mt-3 mx-4 text-center text-2xl font-semibold">
              <p>Account Settings</p>
            </div>

            <div className="mx-8 xl:block md:flex md:flex-wrap md:justify-center md:gap-8 cursor-pointer">
              <NavLink to="../teacherSetting">
                <div className="flex items-center bg-blue-50 text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5 ">
                  <img src={Diamond} alt="" /> <p>Time Slot</p>
                </div>
              </NavLink>

              {/* <NavLink to="../students" ClassName="active-links">
                <div className="flex items-center border-l-4 border-blue-500 bg-blue-50 text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5 ">
                  <img src={Diamond} alt="" /> <p>My Students</p>
                </div>
              </NavLink> */}
            </div>

            {/* content7 */}
            <div className="mt-14 mx-4 text-center text-2xl font-semibold ">
              <p>My Subscription</p>
            </div>
            {/* content8 */}

            <div className="mx-8 xl:block md:flex md:flex-wrap md:justify-center md:gap-8 cursor-pointer">
              <NavLink to="../teacherPayment">
                <div className=" flex items-center   bg-blue-50 text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5">
                  <img src={Diamond} alt="" /> <p>Payments</p>
                </div>
              </NavLink>

              <NavLink to="">
                <div className=" flex items-center   bg-blue-50 text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5">
                  <img src={Diamond} alt="" /> <p>Invoices</p>
                </div>
              </NavLink>
            </div>
          </div>

          <div className="w-full xl:w-2/3 p-4 border-l-2 ">
            {/* content1 */}
            <div className="mx-4 mt-4">
              <p className="text-2xl font-bold">My Students</p>
              <p className="text-sm font-medium"></p>
            </div>

            {/* content2 */}
            <div className="flex flex-wrap md:justify-start justify-center  space-x-10  border-b-2 text-base font-medium mt-8 mx-14">
              <NavLink
                to=".././students"
                className={({ isActive }) =>
                  isActive
                    ? "text-blue-500 border-b-2 border-blue-500"
                    : undefined
                }
              >
                Your Students
              </NavLink>

              <NavLink
                to=".././request"
                className={({ isActive }) =>
                  isActive
                    ? "text-blue-500 border-b-2 border-blue-500"
                    : undefined
                }
              >
                Requests
              </NavLink>
            </div>

            <div className="ml-4">
              <TutorTable header={header} data={data} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Student;
