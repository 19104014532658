import React from "react";


const Card = (props) => {
  return (
   

    <div className='my-10'>
      <div className="relative flex justify-center px-7">
        <div className="flex flex-wrap  max-w-6xl  justify-between ">
          <div className="w-full lg:w-3/12 md:w-3/12 sm:w-full">
            <h1 className="text-[48px] text-[#1E1E1E] font-bold" data-aos="fade-up">{props.Title}</h1>
            <p className="text-[20px] small-text  font-normal text-[#1E1E1E] mt-2" data-aos="fade-up" >
              {props.discription}
            </p>
          </div>

          <div className="w-full lg:w-9/12  md:w-9/12 sm:w-full" data-aos="fade-up">
            <div>
              <p className="text-[20px] small-text  font-normal text-[#1E1E1E] mt-2" data-aos="fade-up" >
                {props.discription2}
              </p>
              <h1 className="text-[48px] text-[#1E1E1E] font-bold" data-aos="fade-up" >{props.Title2}</h1>
              <div className='float-right'><img data-aos="fade-right" src={props.Image} alt="" /></div>
            </div>
          </div>
        </div>
      </div>


    </div>


  );
};

export default Card;

