import React from 'react'
import StudentTable from '../../../components/AdminTable/StudentTable';

function Student() {
  const header =[{
    head1:'Student Name',
    head2: 'Email',
    head3: 'Phone',
    head4: 'Status',
    head5: 'Delete',
  }]
  


  return (
    <main>
     

      <StudentTable 
       heading={"Students"} 
       SubHeading={""}
       header={header}
       />
    </main>
  )
}

export default Student
