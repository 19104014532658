import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import apiKey from "../../../config/apiKey";
import { FaPaperPlane } from "react-icons/fa"; // Example icons
import Pusher from "pusher-js";
import Echo from "laravel-echo";

const DEFAULT_IMAGE_URL = `${apiKey}/userProfile/87654321.png`;

const ChatMsg = ({ setTotalUnreadCount }) => {
  const student = JSON.parse(localStorage.getItem("edo-student"));
  const user = {
    id: student ? student.student.id : "",
  };
  const [studentId, setStudentId] = useState(null);
  const [messagesByTeacher, setMessagesByTeacher] = useState([]);
  const [selectedTeacherId, setSelectedTeacherId] = useState(null);
  const [selectedTeacherName, setSelectedTeacherName] = useState("");
  const [selectedTeacherImage, setSelectedTeacherImage] = useState("");
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [unreadCounts, setUnreadCounts] = useState({});

  const messagesEndRef = useRef(null);

  useEffect(() => {
    const fetchMessages = () => {
      axios
        .get(`${apiKey}/api/edolutions/student/showmessagestudent/${user.id}`, {
          headers: {
            Authorization: `Bearer ${student?.token}`,
          },
        })
        .then((res) => {
          setStudentId(res.data.student_id);
          setMessagesByTeacher(res.data.messages_by_teacher);

          // Calculate unread counts with the new condition
          const counts = {};
          let totalUnread = 0;
          res.data.messages_by_teacher.forEach((teacher) => {
            const unread = teacher.messages.filter(
              (msg) => msg.read === 0 && msg.teacher_message !== null
            ).length;
            counts[teacher.teacher_id] = unread;
            totalUnread += unread;
          });
          setUnreadCounts(counts);
          setTotalUnreadCount(totalUnread);

          if (selectedTeacherId) {
            const teacherMessages = res.data.messages_by_teacher.find(
              (teacher) => teacher.teacher_id === selectedTeacherId
            );
            setSelectedMessages(
              teacherMessages
                ? teacherMessages.messages.map((msg) => ({
                    ...msg,
                    teacher_name: teacherMessages.teacher_name, // Ensure teacher_name is included
                  }))
                : []
            );
            setSelectedTeacherName(
              teacherMessages ? teacherMessages.teacher_name : ""
            );
            setSelectedTeacherImage(
              teacherMessages
                ? teacherMessages.teacher_photo
                  ? `${apiKey}/userProfile/${teacherMessages.teacher_photo}`
                  : DEFAULT_IMAGE_URL
                : DEFAULT_IMAGE_URL
            );
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };

    fetchMessages();
    // const intervalId = setInterval(fetchMessages, 3000);

    // return () => clearInterval(intervalId);
  }, [user.id, selectedTeacherId, student?.token, setTotalUnreadCount]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [selectedMessages]);

  //broadcasting connection
  useEffect(() => {
    window.Pusher = Pusher;
  
    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: 'b7245c5f531fd8d69ec7', // Pusher app key
      cluster: 'ap2', // Pusher app cluster (e.g., 'eu')
      forceTLS: true, // Pusher uses TLS by default
      encrypted: true, // Ensures connection encryption
      authEndpoint: `${apiKey}/broadcasting/auth`, // Same endpoint for Pusher
      auth: {
        headers: {
          Authorization: `Bearer ${student?.token}`,
        },
      },
    });

    window.Echo.private("chat-message-event.student." + user.id).listen(
      "ChatMessageStudentEvent",
      (e) => {
        setMessagesByTeacher((prevMessages) => {
          return prevMessages.map((teacher) => {
            if (teacher.teacher_id === e.data.teacher_id) {
              // Append the new message to the teacher's messages array
              return {
                ...teacher,
                messages: [...teacher.messages, e.data],
              };
            }
            // Return the teacher unchanged if it's not the correct teacher
            return teacher;
          });
        });

        if (selectedTeacherId === e.data.teacher_id) {
          const teacherMessages = messagesByTeacher.find(
            (teacher) => teacher.teacher_id === e.data.teacher_id
          );

          setSelectedMessages(() => {
            let message = teacherMessages
              ? teacherMessages.messages.map((msg) => ({
                  ...msg,
                  teacher_name: teacherMessages.teacher_name, // Ensure teacher_name is included
                }))
              : [];

            // Return the updated array with the new message appended
            return [
              ...message,
              { ...e.data, teacher_name: teacherMessages.teacher_name },
            ];
          });
        }
      }
    );
  }, [messagesByTeacher, selectedTeacherId, student?.token, user]);

  const handleTeacherClick = (teacherId) => {
    const teacherMessages = messagesByTeacher.find(
      (teacher) => teacher.teacher_id === teacherId
    );
    setSelectedTeacherId(teacherId);
    setSelectedMessages(
      teacherMessages
        ? teacherMessages.messages.map((msg) => ({
            ...msg,
            teacher_name: teacherMessages.teacher_name, // Ensure teacher_name is included
          }))
        : []
    );
    setSelectedTeacherName(teacherMessages ? teacherMessages.teacher_name : ""); // Set selected teacher name
    setSelectedTeacherImage(
      teacherMessages
        ? teacherMessages.teacher_photo
          ? `${apiKey}/userProfile/${teacherMessages.teacher_photo}`
          : DEFAULT_IMAGE_URL
        : DEFAULT_IMAGE_URL
    ); // Set teacher image

    // Call markMessagesAsRead when a student is clicked
    markMessagesAsRead(teacherId);
  };

  const markMessagesAsRead = (teacherId) => {
    axios
      .post(
        `${apiKey}/api/edolutions/student/markMessagesAsReadStd/${studentId}/${teacherId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${student?.token}`,
          },
        }
      )
      .then((response) => {
        // console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSendMessage = () => {
    // const newMsg1 = {
    //   id: Date.now(),
    //   teacher_message: null,
    //   student_message: newMessage,
    //   created_at: new Date().toISOString(),
    //   updated_at: new Date().toISOString(),
    //   teacher_name: selectedMessages[0].teacher_name, // Placeholder, will be updated upon fetching
    // };
    // console.log([...selectedMessages, newMsg1]);
    if (!newMessage.trim() || selectedTeacherId === null) return;

    const newMsg = {
      id: Date.now(),
      teacher_message: null,
      student_message: newMessage,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      teacher_name: selectedMessages[0].teacher_name, // Placeholder, will be updated upon fetching
    };

    setSelectedMessages([...selectedMessages, newMsg]);
    setNewMessage("");

    const messageData = {
      teacher_id: selectedTeacherId,
      student_id: studentId,
      student_message: newMessage,
    };

    axios
      .post(
        `${apiKey}/api/edolutions/student/storemessagestudent`,
        messageData,
        {
          headers: {
            Authorization: `Bearer ${student?.token}`,
          },
        }
      )
      .then((res) => {
        // console.log(res)
        // setSelectedMessages((prev) =>
        //   prev.map((msg) => (msg.id === newMsg.id ? res.data.message : msg))
        // );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const [searchQuery, setSearchQuery] = useState("");
  const filteredTeachers = messagesByTeacher.filter((teacher) =>
    teacher.teacher_name?.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  return (
    <div className="flex h-screen bg-gray-50">
      {/* Teacher List */}
      <div className="w-1/4 bg-white border-r border-gray-300 p-4 overflow-y-auto">
        <h2 className="text-2xl font-bold mb-4 text-gray-700">Teachers</h2>
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search teachers..."
          className="mb-4 w-full border border-gray-300 rounded-lg p-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <h6 className="text-sm text-gray-400">Chats</h6>
        <ul>
          {filteredTeachers.map((teacher) => (
            <li
              key={teacher.teacher_id}
              onClick={() => handleTeacherClick(teacher.teacher_id)}
              className={`p-3 mb-3 cursor-pointer rounded-lg transition-colors duration-300
                ${
                  teacher.teacher_id === selectedTeacherId
                    ? "bg-gray-200 text-blue-600"
                    : "hover:bg-gray-100"
                } flex items-center gap-3`}
            >
              <img
                src={
                  teacher.teacher_photo
                    ? `${apiKey}/userProfile/${teacher.teacher_photo}`
                    : DEFAULT_IMAGE_URL
                }
                onError={(e) => (e.target.src = DEFAULT_IMAGE_URL)} // Set default image on error
                alt={teacher.teacher_name}
                className="w-10 h-10 rounded-full object-cover"
              />
              <span className="font-semibold">{teacher.teacher_name}</span>
              {unreadCounts[teacher.teacher_id] > 0 && (
                <span className="ml-2 text-xs bg-red-500 text-white px-2 py-1 rounded-full">
                  {unreadCounts[teacher.teacher_id]}
                </span>
              )}
            </li>
          ))}
        </ul>
      </div>

      {/* Message Box */}
      <div className="flex-1 flex flex-col bg-gray-200">
        <div className="flex-1 flex flex-col bg-white p-1 shadow-lg overflow-hidden">
          <div className="bg-gray-100 p-4 rounded-t-lg shadow-sm flex items-center gap-3">
            <img
              src={
                selectedTeacherImage ? selectedTeacherImage : DEFAULT_IMAGE_URL
              }
              onError={(e) => (e.target.src = DEFAULT_IMAGE_URL)} // Set default image on error
              alt={selectedTeacherName}
              className="w-12 h-12 rounded-full object-cover"
            />
            <h2 className="text-2xl font-bold text-gray-700">
              {selectedTeacherId
                ? `${selectedTeacherName}`
                : "Select a Teacher"}
            </h2>
          </div>
          <div className="flex-1 overflow-y-auto p-4">
            {selectedMessages.length === 0 ? (
              <div className="flex flex-col items-center justify-center h-full text-gray-500">
                <FaPaperPlane className="text-6xl text-[#4FD1C5] mb-4" />
                <p className="text-[#4FD1C5]">
                  No messages yet. Start a conversation!
                </p>
              </div>
            ) : (
              selectedMessages.map((message) => (
                <div
                  key={message.id}
                  className={`mb-4 p-3 rounded-lg shadow-sm 
                    ${
                      message.student_message
                        ? "bg-gray-100 text-black text-left"
                        : "bg-[rgb(187,222,251,0.2)] text-black text-right"
                    }`}
                >
                  <p className="font-semibold">
                    {message.student_message
                      ? "Student"
                      : `Teacher (${message.teacher_name})`}
                    :
                  </p>
                  <p>{message.student_message || message.teacher_message}</p>
                  <p className="text-xs text-gray-500 text-right">
                    {new Date(message.created_at).toLocaleString("en-US", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false, // Use 24-hour time format
                    })}
                  </p>
                </div>
              ))
            )}
            {/* Scroll reference */}
            <div ref={messagesEndRef} />
          </div>
        </div>

        {/* Input Field and Send Button */}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSendMessage();
          }}
          className="flex items-center gap-2 p-2 bg-white border-gray-300"
        >
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type your message..."
            className="flex-1 border border-gray-300 rounded-lg p-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#4FD1C5]"
          />
          <button
            // onClick={handleSendMessage}
            type="submit"
            className="bg-[#4FD1C5] text-white px-5 py-2 rounded-lg hover:bg-[#4FD1C5] transition-colors duration-300 flex items-center gap-2"
          >
            <FaPaperPlane />
            <span>Send</span>
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChatMsg;
