import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import confirm from "../../assets/confirm.png";
import apiKey from "../../config/apiKey";

const ConfirmPass = () => {
  const [formData, setFormData] = useState({
    password: "",
    password_confirmation: "",
  });
  const [errors, setErrors] = useState({});
  const { token } = useParams();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`${apiKey}/api/edolutions/resetpassword/${token}`, {
        password: formData.password,
        password_confirmation: formData.password_confirmation,
      });
      toast.success("Password reset successfully!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: {
          backgroundColor: "#4FD1C5", // Customize background color
          color: "#fff", // Customize text color
        },
      });
      navigate("/login/loginPage/Login");
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        setErrors(error.response.data.errors);
      } else {
        toast.error("Failed to reset password. Please try again.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  return (
    <div className="flex flex-wrap items-center">
      <div className="w-full sm:w-1/2">
        <div className="flex justify-center">
          <img className="h-screen" src={confirm} alt="Confirm Password" />
        </div>
      </div>
      <div className="w-full sm:w-1/2">
        <div className="flex flex-wrap items-center justify-center p-4 bg-white">
          <div className="w-full sm:w-12/12 p-4 text-center">
            <div className="text-[24px] font-semibold mt-[35px]">
              <p>Confirm Password</p>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap items-center justify-center p-4 bg-white">
          <div className="w-full sm:w-9/12 p-4 text-center">
            <form onSubmit={handleSubmit}>
              <div className="text-start text-[14px] font-medium">
                Password
              </div>
              <input
                className="w-full px-8 py-3 bg-[#F5F5F5] border my-2 rounded-md"
                type="password"
                name="password"
                placeholder="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
              {errors.password && (
                <div className="text-red-500 text-sm">{errors.password}</div>
              )}
              <div className="text-start text-[14px] font-medium">
                Confirm Password
              </div>
              <input
                className="w-full px-8 py-3 bg-[#F5F5F5] border my-2 rounded-md"
                type="password"
                name="password_confirmation"
                placeholder="confirm password"
                value={formData.password_confirmation}
                onChange={handleChange}
                required
              />
              {errors.password_confirmation && (
                <div className="text-red-500 text-sm">{errors.password_confirmation}</div>
              )}
              <button
                className="w-full rounded-md bg-[#5963F5] text-[20px] font-medium text-white text-center py-4 my-2"
                type="submit"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPass;
