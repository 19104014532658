import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import FindJob from "../../pages/FindJob";
import ShowTeachers from "../../pages/ShowTeachers";
import FindTeacher from "../../pages/FindTeacher";
import HowWork from "../../pages/HowWork";
import AboutUs from "../../pages/AboutUs";
import Whatup from "../Whatup";
import HashLoader from "react-spinners/HashLoader";
import ContactUs from "../../pages/ContactUs";
import TeacherMainProfile from "../../pages/TeacherMainProfile";
import Policy from "../../pages/Policy";
import Terms from "../../pages/Terms";
import ReturnPolicy from "../../pages/ReturnPolicy";


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function  RootLayout() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <HashLoader
          color={'#4FD1C5'}
          loading={loading}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }
  

  return (
    <>
      <ScrollToTop />
      <Navbar />
      {/* <ScrollBackToTop /> */}
      <Whatup />
      <Routes>
        <Route path="/" element={<HowWork />} />
        <Route path="/work" element={<HowWork />} />
        <Route path="/showTeacher" element={<ShowTeachers />} />
        <Route path="/findTeacher" element={<FindTeacher />} />
        <Route path="/Teacherprof/:id" element={<TeacherMainProfile/>} />
        <Route path="/privacy" element={<Policy/>} />
        <Route path="/returnpolicy" element={<ReturnPolicy/>} />
        <Route path="/termsconditions" element={<Terms/>} />
  
        <Route path="/job" element={<FindJob />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/contactUs" element={<ContactUs />} />
      </Routes>
      <Footer />
    </>
  );
}

export default RootLayout;
