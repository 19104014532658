import React from "react";
import PhoneInput from "react-phone-input-2";
import emailImage from "../../assets/emailVerify.png";
import { Link } from "react-router-dom";

const EmailVerification = () => {


  return (
    <div className="flex flex-wrap items-center justify-center ">

      <div className="w-full sm:w-1/2 mt-20 ">
        <div className="flex flex-wrap  items-center justify-center p-4 bg-white md-1   ">
          <img className="mt-18" src={emailImage} alt="" />
        </div>
      </div>

      <div className="w-full sm:w-1/2 bg-white " >


       

        <div className="flex flex-wrap  items-center justify-center p-4 md-1 mt-[15vh] ">
          <div className="w-full sm:w-10/12 p-4 text-center">
            <div className="text-center text-[24px] font-semibold">
              <p className="text-[24px] font-semibold">Verification Your Number</p>
              <p className="text-[14px] mb-5">Enter your number and get OTP</p>
            </div>
            <div className="text-[14px] font-semibold">
              <p className="text-start">Phone Number</p>
            </div>
            <div className="outline-none px-1 py-2 focus-none  w-full bg-[#F5F5F5]">
                <PhoneInput
                  className="text-start"
                  containerStyle={{
                    width: '100%',
                    backgroundColor: 'transparent',
                    borderBottom: '1px solid #F5F5F5',
                  }}
                  inputStyle={{
                    backgroundColor: 'transparent',
                    width: '100%',
                    border: 'none',
                    outline: 'none',
                    fontSize: '16px',
                    color: '#000',
                  }}
                  
                  buttonStyle={{ 
                    border: 'none',
                    backgroundColor: 'transparent', 
                  }}
                  country={'us'}
                  placeholder={'Phone'}
                  inputProps={{ required: true, name: 'phone', id: 'phone' }}
                  type={'tel'}
                />
              </div>
            <div className="text-[14px] font-semibold my-5">
              <p className="text-start my-2">OTP</p>
              <div className="flex-1 bg-[#F9FBFF] border border-[#F5F5F5] rounded-lg px-1">
                <input
                  className="outline-none px-1 py-3 focus-none  w-full bg-[#F5F5F5] "
                  placeholder="Enter Your OTP"
                  type="text"
                  name=""
                  id=""
                />
              </div>
            </div>

            <Link to='../teacherLogin'>
              <button className="w-full rounded-md bg-[#5963F5] text-[20px] font-medium text-white text-center py-3 mt-5">
                Verify
              </button>
            </Link>



          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
