import React from 'react'

const Policy = () => {
  return (
    <div className='mt-32 max-w-7xl  w-[90%] mx-auto'>
        <h1 className='text-3xl text-[#008956] mb-6  font-semibold'>Privacy Policy</h1>
        <p className='my-2'>Effective Date: 01-04-2024</p>
        <p className='my-2 text-justify'>This Privacy Policy (“Privacy Policy” or “Policy”) discloses the privacy practices of Edolutions (“Edolutions,” “We,” “Us,” or “Our”) with regard to the collection and use of personal information (“Personal Information”) of our customers, vendors, employees, and users (“You” or “Your”) across the online platform www.edolutions.com (“Website”). This Privacy Policy, together with our Terms of Use, describes our practices regarding how we collect, store, use, share, and secure your Personal Information. It also explains your rights regarding the use, access, and correction of your Personal Information, and how you can contact us or relevant authorities if you have any complaints.</p>
        <p className='text-justify'>By providing your consent to this Privacy Policy and accepting the Terms of Use, you agree to the collection, use, and transfer of your Personal Information as outlined in this Privacy Policy. If you do not agree with the terms of this Policy, please do not use or access our website.</p>
        <p className='text-justify'>We review this Privacy Policy periodically to ensure it remains up to date. If you are a visitor, please note that this Privacy Policy may be subject to change at any time without prior notice. To stay informed, we encourage you to review this Policy from time to time. If you are a registered user, we will notify you before making changes to this Policy and give you the opportunity to review the revised Policy before deciding whether to continue using our services.</p>
        
        <p className='my-2 text-justify mb-8'>At <span>Edolutions </span>  https://edolutions.com, we value and respect your privacy. This privacy policy outlines how we collect, use, share, and protect your personal information.</p>
        
        <section class="mb-8">
            <h2 class="text-2xl text-[#008956] font-semibold mb-4">1. Information We Collect</h2>
            <p class="mb-4">We collect personal information that you provide directly to us when you use our platform, including but not limited to:</p>
            <ul class="list-disc ml-5 list-inside space-y-2">
                <li>Name</li>
                <li>Email Address</li>
                <li>Phone Number</li>
                <li>Educational Background</li>
                <li>Payment Information (where applicable)</li>
            </ul>
            <p class="mt-4">We may also collect non-personal information such as usage data, IP addresses, and cookies to improve your experience.</p>
        </section>

     
        <section class="mb-8">
            <h2 class="text-2xl text-[#008956] font-semibold mb-4">2. How We Use Your Information</h2>
            <p class="mb-4">We use the information we collect for various purposes, including:</p>
            <ul class="list-disc ml-5 list-inside space-y-2">
                <li>To provide and maintain our services</li>
                <li>To improve the user experience on our platform</li>
                <li>To process transactions</li>
                <li>To communicate with you regarding updates, offers, or support</li>
                <li>To comply with legal obligations</li>
            </ul>
        </section>

     
        <section class="mb-8">
            <h2 class="text-2xl text-[#008956] font-semibold mb-4">3. Sharing Your Information</h2>
            <p class="mb-4">We do not sell or rent your personal information to third parties. However, we may share your information with trusted third parties to help us provide and improve our services. This includes:</p>
            <ul class="list-disc ml-5 list-inside space-y-2">
                <li>Service providers (e.g., payment processors)</li>
                <li>Affiliates and partners for joint educational programs</li>
                <li>Legal authorities, if required by law</li>
            </ul>
        </section>

     
        <section class="mb-8">
            <h2 class="text-2xl text-[#008956] font-semibold mb-4">4. Data Security</h2>
            <p>We take appropriate measures to ensure the security of your personal information. However, no online platform is 100% secure, and we cannot guarantee the absolute security of your data.</p>
        </section>

     
        <section class="mb-8">
            <h2 class="text-2xl text-[#008956] font-semibold mb-4">5. Your Rights</h2>
            <p class="mb-4">You have the right to:</p>
            <ul class="list-disc ml-5  list-inside space-y-2">
                <li>Access your personal information</li>
                <li>Request corrections or updates</li>
                <li>Request deletion of your personal data (subject to legal requirements)</li>
                <li>Opt-out of marketing communications</li>
            </ul>
            <p class="mt-4">If you have any concerns about your privacy or wish to exercise your rights, you can contact us at:</p>
            <p class="font-medium">Email: <a href="mailto:info@edolutions.com" class="text-blue-500">info@edolutions.com</a></p>
            <p class="font-medium">Phone: <a href="tel:+923313655666" class="text-blue-500">+92 331 3655666</a></p>
        </section>

             <section class="mb-8">
            <h2 class="text-2xl text-[#008956] font-semibold mb-4">6. Cookies and Tracking Technologies</h2>
            <p>We use cookies and similar technologies to enhance your experience on our platform. You can choose to disable cookies through your browser settings, but this may limit your ability to use certain features.</p>
        </section>

     
        <section class="mb-8">
            <h2 class="text-2xl text-[#008956] font-semibold mb-4">7. Changes to This Privacy Policy</h2>
            <p>We reserve the right to update or modify this privacy policy at any time. Any changes will be effective immediately upon posting on this page. We encourage you to review this page periodically to stay informed.</p>
        </section>

     
        <section class="mb-8">
            <h2 class="text-2xl text-[#008956] font-semibold mb-4">8. Contact Us</h2>
            <p>If you have any questions about this privacy policy or how we handle your personal information, please contact us at:</p>
            <p class="font-medium">Email: <a href="mailto:info@edolutions.com" class="text-blue-500">info@edolutions.com</a></p>
            <p class="font-medium">Phone: <a href="tel:+923313655666" class="text-blue-500">+92 331 3655666</a></p>
        </section>
    
    </div>
  )
}

export default Policy