import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import apiKey from "../../../config/apiKey";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const MyAccount = () => {
  const admin = JSON.parse(localStorage.getItem("edo-admin"));
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const [formData, setFormData] = useState({
    password: "",
    new_password: "",
    new_password_confirmation: "",
  });
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: "",
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading to true when the form is submitted

    try {
      // Send POST request
      const response = await axios.post(
        `${apiKey}/api/edolutions/admin/newpassword`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${admin?.token}`,
          },
        }
      );

      // Show success message
      toast.success("Successfully updated password", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: {
          backgroundColor: "#4FD1C5", // Customize background color
          color: "#fff", // Customize text color
        },
      });
    } catch (err) {
      // Handle errors
      if (err.response && err.response.data && err.response.data.errors) {
        setErrors(err.response.data.errors);
      } else {
        setErrors({ general: "An unexpected error occurred" });
      }
    } finally {
      // Reset loading state
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className="flex items-center xl:p-5 sm:p-0 justify-center">
        <div className="w-full border rounded-3xl shadow-xl p-5">
          <div>
            <div>
              <p className="text-2xl font-bold">Setting</p>
              {/* <p className="text-[#22B7AA] font-light text-sm">Admin Setting</p> */}
            </div>
            <div className=" flex border-b-2 justify-between items-center mt-8">
              <div className="flex flex-wrap md:justify-start md:space-x-12  space-x-5  text-base font-medium ">
                <NavLink
                  to=".././myAccount"
                  className={({ isActive }) =>
                    isActive
                      ? "text-blue-500 border-b-2 border-blue-500"
                      : undefined
                  }
                >
                  My Account
                </NavLink>
                {/* 
                <NavLink to=".././webSetting" className={({ isActive }) => (isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined)}>Website</NavLink>


                <NavLink to=".././settingNoti" className={({ isActive }) => (isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined)}>Notification</NavLink>

                <NavLink to=".././emailTemplate" className={({ isActive }) => (isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined)}>Email Template</NavLink> */}
              </div>
            </div>

            {/* content------------------------------------------------- */}

            <div className="lg:p-6 ">
              <div className="lg:py-2 lg:px-6 ">
                {/* <div className="border-b pb-5">
                  <p className="font-bold text-lg">Profile Details</p>
                </div> */}
                {/* 
                <div className=" md:flex block gap-5 mt-5">
                  <div className="flex flex-col justify-center items-center md:items-start px-5 md:w-2/12  ">
                    <div className="flex">
                      <img
                        className="rounded-full border w-24 h-24"
                        src={Profile}
                        alt=""
                      />
                      <input type="file" id="uploadBtn" className='' />
                      <label htmlFor="uploadBtn" className=" select-none	">
                        <BiPencil className="w-6 h-6 cursor-pointer mt-16" /></label>

                    </div>

                  </div>
                  <div className=" md:w-7/12  p-2">
                    <div className="space-y-3">
                      <div className="md:flex block md:space-y-0 space-y-3 gap-10 w-full">
                        <div className="flex-1 bg-[#F9FBFF] rounded-lg px-1">
                          <input
                            className="outline-none p-1 focus-none  w-full bg-[#F9FBFF] "
                            placeholder="First Name"
                            type="text"
                            name=""
                            id=""
                          />
                        </div>
                        <div className="flex-1 bg-[#F9FBFF] rounded-lg px-1">
                          <input
                            className="outline-none p-1 focus-none  w-full bg-[#F9FBFF] "
                            placeholder="last Name"
                            type="text"
                            name=""
                            id=""
                          />
                        </div>
                      </div>
                      <div className="md:flex block md:space-y-0 space-y-3 gap-10 w-full">
                        <div className="flex-1 bg-[#F9FBFF] rounded-lg px-1">
                          <input
                            className="outline-none p-1 focus-none  w-full bg-[#F9FBFF] "
                            placeholder="Phone No"
                            type="text"
                            name=""
                            id=""
                          />
                        </div>
                        <div className="flex-1 bg-[#F9FBFF] rounded-lg px-1">
                          <input
                            className="outline-none p-1 focus-none  w-full bg-[#F9FBFF] "
                            placeholder="Email"
                            type="text"
                            name=""
                            id=""
                          />
                        </div>
                      </div>
                      <div className="md:flex block md:space-y-0 space-y-3 gap-10 w-full">
                        <div className="flex-1 bg-[#F9FBFF] rounded-lg px-1">
                          <input
                            className="outline-none p-1 focus-none  w-full bg-[#F9FBFF] "
                            placeholder="Country"
                            type="text"
                            name=""
                            id=""
                          />
                        </div>
                        <div className="flex-1 bg-[#F9FBFF] rounded-lg px-1">
                          <input
                            className="outline-none p-1 focus-none  w-full bg-[#F9FBFF] "
                            placeholder="City"
                            type="text"
                            name=""
                            id=""
                          />
                        </div>
                      </div>
                      <div>
                        <div className=" bg-[#F9FBFF] rounded-lg px-1">
                          <input
                            className="outline-none p-1 focus-none  w-full bg-[#F9FBFF] "
                            placeholder="Address"
                            type="text"
                            name=""
                            id=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-end mt-3">
                  <div className=" md:mx-24 mx-5 bg-[#5963F5] text-sm font-semibold rounded-md text-white px-5 py-2">
                    <p>Changes Save</p>
                  </div>
                </div> */}

                <div className="border-b-2 pb-5 mt-5">
                  <p className="font-bold text-lg">Password Change</p>
                </div>

                <div className="md:flex block gap-5 mt-5">
                  <div className="md:w-7/12 p-2">
                    <div className="space-y-3">
                      <div>
                        <div className="bg-[#F9FBFF] rounded-lg px-1 flex items-center">
                          <input
                            className="outline-none p-1 focus-none w-full bg-[#F9FBFF]"
                            placeholder="Enter Current Password"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            onChange={handleChange}
                          />
                          <button
                            type="button"
                            className="ml-2"
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </button>
                        </div>
                      </div>
                      {errors.password && (
                        <div className="text-red-500 text-start text-sm">
                          {`*Invalid Credentials!`}
                        </div>
                      )}

                      <div>
                        <div className="bg-[#F9FBFF] rounded-lg px-1 flex items-center">
                          <input
                            className="outline-none p-1 focus-none w-full bg-[#F9FBFF]"
                            placeholder="Enter New Password"
                            type={showPassword ? "text" : "password"}
                            name="new_password"
                            onChange={handleChange}
                          />
                          <button
                            type="button"
                            className="ml-2"
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </button>
                        </div>
                      </div>

                      <div>
                        <div className="bg-[#F9FBFF] rounded-lg px-1 flex items-center">
                          <input
                            className="outline-none p-1 focus-none w-full bg-[#F9FBFF]"
                            placeholder="Re-Enter New Password"
                            type={showPassword ? "text" : "password"}
                            name="new_password_confirmation"
                            onChange={handleChange}
                          />
                          <button
                            type="button"
                            className="ml-2"
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {errors.new_password && (
                  <div className="text-red-500 text-start text-sm">
                    {errors.new_password.includes(
                      "The new password field confirmation does not match."
                    ) && (
                      <div>
                        *The new password field confirmation does not match.
                      </div>
                    )}
                    {errors.new_password.includes(
                      "The new password must be at least 8 characters."
                    ) && (
                      <div>
                        *The new password must be greater than 8 characters.
                      </div>
                    )}
                    {errors.new_password.includes(
                      "The new password must not be greater than 12 characters."
                    ) && (
                      <div>
                        *The new password must not be less than 12 characters.
                      </div>
                    )}
                  </div>
                )}

                <div className="mt-5 float-right">
                  <p
                    onClick={handleSubmit}
                    className={`text-sm font-medium text-center w-fit px-4 py-2 rounded-lg ${
                      isLoading
                        ? "bg-gray-300 cursor-not-allowed text-gray-600"
                        : "text-white bg-gray-400 border border-[#7E7E7E]"
                    }`}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <svg
                        className="animate-spin h-5 w-5 text-gray-600 inline-block mr-2"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : (
                      "Save Password"
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyAccount;
