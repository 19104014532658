import React, { useState, useEffect } from "react";
import Mastering1 from "../assets/Mastering1.png";
import Mastering2 from "../assets/Mastering2.png";
import laptop from "../assets/Laptop.png";
import chalkboard from "../assets/ChalkboardTeacher.png";
import pencil from "../assets/PencilLine.png";
import bookopen from "../assets/BookOpenText.png";
import bookuser from "../assets/BookOpenUser.png";
import camera from "../assets/VideoCamera.png";

const Mastering = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const images = [
   Mastering1,
   Mastering2
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 1500);

    return () => clearInterval(interval);
  }, [images.length]);
  return (
    <div className="my-12 ">
      <div className="flex items-center justify-center">
        <div className="flex flex-wrap sm:w-9/12 w-full items-center justify-center p-4 ">
          <p className="text-[40px] font-bold text-[#1E1E1E] text-center small-heading medium-heading" data-aos="fade-up">
            Mastering Education, <br /> Anytime, Anywhere
          </p>
        </div>
      </div>
      <div className="flex items-center justify-center mt-8">
        <div className="flex flex-wrap  max-w-6xl  items-center justify-center p-4">
          <div className=" w-full sm:w-4/12 ">
            <div className="flex my-2 " data-aos="fade-right">
              <div className=" mx-3">
               <img className="" src={laptop} alt="" />
              </div>
              <div>
                <p className="text-[18px] font-semibold  text-[#1E1E1E]">Online Tutoring</p>
                {/* <p className="text-[16px] font-normal leading-[24px] text-[#828282]">
                  Math, Physics, Chemistry, Biology, Homework Help and More
                </p> */}
              </div>
            </div>

            <div className="flex my-10 " data-aos="fade-left">
              <div className=" mx-3">
              <img className="" src={chalkboard} alt="" />
              </div>
              <div>
                <p className="text-[18px] font-semibold text-[#1E1E1E]">
                  Primary/Secondary Classes
                </p>
                {/* <p className="text-[16px] font-normal leading-[24px] text-[#828282]">
                  Math, Physics, Chemistry, Biology, Homework Help and More
                </p> */}
              </div>
            </div>

            <div className="flex my-10  " data-aos="fade-right">
              <div className="mx-3">
              <img className="" src={pencil} alt="" />
              </div>
              <div>
                <p className="text-[18px] font-semibold text-[#1E1E1E]">Aptitude Test</p>
                {/* <p className="text-[16px] font-normal leading-[24px] text-[#828282]">
                  Math, Physics, Chemistry, Biology, Homework Help and More
                </p> */}
              </div>
            </div>
          </div>

          <div className="w-full sm:w-4/12  p-4" data-aos="fade-down">
            <img src={images[(imageIndex + 1) % images.length]} alt="" />
          </div>
          <div className=" w-full sm:w-4/12   p-4">
            <div className="flex my-2" data-aos="fade-left" >
              <div className=" mx-3">
              <img className="" src={bookopen} alt="" />
              </div>
              <div>
                <p className="text-[18px] font-semibold text-[#1E1E1E]">At Home Tutoring</p>
                
              </div>
            </div>

            <div className="flex my-10" data-aos="fade-right">
              <div className=" mx-3">
              <img className="" src={bookuser} alt="" />
              </div>
              <div>
                <p className="text-[18px] font-semibold text-[#1E1E1E]">Crash Courses</p>
                {/* <p className="text-[16px] font-normal leading-[24px] text-[#828282]">
                  Math, Physics, Chemistry, Biology, Homework Help and More
                </p> */}
              </div>
            </div>

            <div className="flex my-10" data-aos="fade-left">
              <div className=" mx-3">
              <img className="" src={camera} alt="" />
              </div>
              <div>
              <p className="text-[18px] font-semibold text-[#1E1E1E]">Recorded Lectures</p>
                {/* <p className="text-[16px] font-normal leading-[24px] text-[#828282]">
                  Math, Physics, Chemistry, Biology, Homework Help and More
                </p> */}
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Mastering;
