import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import apiKey from "../../../config/apiKey"; // Assuming you have the base API URL here
import { HiLocationMarker } from "react-icons/hi"; // For location input
import { BiCamera } from "react-icons/bi"; // For camera icon
import { toast } from "react-toastify";
import vector from "../../../assets/Vector 01.png";

const StartClass = () => {
  const [classDetails, setClassDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false); // For start modal
  const [showModalSec, setShowModalSec] = useState(false); // For end modal
  const [endReason, setEndReason] = useState("");
  const teacher = JSON.parse(localStorage.getItem("edo-teacher"));
  const student = JSON.parse(localStorage.getItem("edo-student"));
  const admin = JSON.parse(localStorage.getItem("edo-admin"));
  const [startButtonDisabled,setStartButtonDisabled]= useState(false)
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [location, setLocation] = useState("");
  const [totalTime, setTotalTime] = useState("");
  const [formData, setFormData] = useState({
    location: "",
    snap: null,
    teacher_id: "",
    student_id: "",
    ttr_id: "",
  });
  const handleLogout = () => {
    const userType = admin ? "admin" : teacher ? "teacher" : "student";
    const userToken = admin
      ? admin.token
      : teacher
      ? teacher.token
      : student.token;

    axios
      .post(
        `${apiKey}/api/edolutions/${userType}/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {})
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        localStorage.removeItem(`edo-${userType}`);
        if (userType === "admin") {
          navigate("/admin");
        } else {
          navigate(`/login/${userType}Login`);
        }
      });
  };
  const { token } = useParams();

  const userId = teacher ? teacher.teacher.id : "";
  useEffect(() => {
    const fetchClassDetails = async () => {
      try {
        const response = await axios.get(
          `${apiKey}/api/edolutions/class-details/${token}`
        );

        setClassDetails(response.data.class_details);
        if (response.data.class_status === "created_not_ended") {
         
          setStartButtonDisabled(true);
        } else {
          setStartButtonDisabled(false);
        }
      } catch (err) {
        setError("Teacher Id does not match or you have already conducted a class today");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
  
    if (token) {
      fetchClassDetails();

    }
  }, [token]);
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: files ? files[0] : value,
      teacher_id: userId,
    }));
  };
  const [detail, setDetailModal] = useState(false);
  const [studentData, setStudentData] = useState(null);
  const [ttrId, setttrId] = useState(null);

  const studentDetails = async (id) => {
    setttrId(id);
    // console.log(id);
    try {
      const response = await axios.get(
        `${apiKey}/api/edolutions/create-class-home1/${id}`
      );

      const studentData = response.data.data;
 

      if (!studentData) {
        // Show toast error if no data found
        toast.error("Student class record not found", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return;
      }

      setStudentData(studentData);
      setDetailModal(true);
    } catch (error) {
      toast.error("Student Record Not  found Create class", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
  };
  const handleStartClassSec = async (id) => {
    setttrId(id);

    try {
      // Make API call to stop counter
      const response = await axios.post(
        `${apiKey}/api/edolutions/stop-counter1/${id}`
      );

      const { total_time, location } = response.data;

      setTotalTime(total_time);

      setShowModalSec(true);
      setDetailModal(false);
    } catch (error) {
      toast.error("Class has already ended or another", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  // Time format
  function formatTime(time) {
    let [hours, minutes] = time.split(":");
    let period = "AM";

    hours = parseInt(hours, 10);

    if (hours >= 12) {
      period = "PM";
      if (hours > 12) {
        hours -= 12;
      }
    } else if (hours === 0) {
      hours = 12;
    }

    return `${hours}:${minutes} ${period}`;
  }

  const handleStartClass = (studentId, tbl_id) => {
    setFormData((prev) => ({
      ...prev,
      student_id: studentId,
      location: classDetails.location,
      ttr_id: tbl_id,
    }));
    // setShowModal(true);
  };

  const [btnloading, setBtnLoading] = useState(false); // New loading state

  const handleSubmit = async (studentId, tbl_id) => {
    setBtnLoading(true); // Start loading

    const formDataToSend = {
      ...formData,
      student_id: studentId,
      location: classDetails.location,
      ttr_id: tbl_id,
      teacher_id: userId,
    };

    const newErrors = {};
    if (!formDataToSend.location) {
      newErrors.location = "Location is required.";
    }


    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoading(false); // Stop loading if there are errors
      return;
    }

    try {
      const formattedData = new FormData();
      Object.keys(formDataToSend).forEach((key) => {
        formattedData.append(key, formDataToSend[key]);
      });

      const response = await axios.post(
        `${apiKey}/api/edolutions/create-class`,
        formattedData
      );
      
      toast.success(response.data.message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: {
          backgroundColor: "#4FD1C5",
          color: "#fff",
        },
      });

      studentDetails(classDetails.id);
      handleLogout();
      setShowModal(false);
      setFormData({
        location: "",
        snap: "",
        teacher_id: userId,
        student_id: "",
        ttr_id: "",
      });
    } catch (error) {
      console.error("Error creating class:", error);
      toast.error("Failed to create class. Please try again.", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } finally {
      setBtnLoading(false); // Stop loading after completion
    }
  };

  const handleConfirmEnd = async () => {
    try {
      const endClassResponse = await axios.post(
        `${apiKey}/api/edolutions/end-class/${studentData.ttr_id}`,
        {
          location: formData.location,
          snap: formData.snap,
          end_reason: totalTime < 60 ? endReason : null, // Only send if totalTime < 60
        }
      );

      // Success message
      toast.success("Class ended successfully", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: {
          backgroundColor: "#4FD1C5", // Customize background color
          color: "#fff", // Customize text color
        },
      });
      setShowModalSec(false);
      handleLogout();
      setEndReason("");
    } catch (error) {
      console.error("Error ending class:", error);
    }
  };

  if (loading) return <div>Loading class details...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="flex items-center xl:p-5 sm:p-0 justify-center">
      <div className="">
        {classDetails && userId === classDetails.teacher_id ? (
          <div className="bg-white p-8 rounded-lg shadow-xl border-t-4 border-[#72dad0]">
            <h2 className="text-2xl font-bold text-[#72dad0] mb-4">
              Class Details
            </h2>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 text-gray-800">
              <div>
                <p className="mb-2">
                  <strong>Class Name:</strong>{" "}
                  <span className="text-gray-600">
                    {classDetails.class_name}
                  </span>
                </p>
                <p className="mb-2">
                  <strong>Teacher Name:</strong>{" "}
                  <span className="text-gray-600">
                    {classDetails.teacher_name}
                  </span>
                </p>
                <p className="mb-2">
                  <strong>Student Name:</strong>{" "}
                  <span className="text-gray-600">
                    {classDetails.student_name}
                  </span>
                </p>
              </div>

              <div>
                <p className="mb-2">
                  <strong>Subject:</strong>{" "}
                  <span className="text-gray-600">{classDetails.subjects}</span>
                </p>
                <p className="mb-2">
                  <strong>Payment:</strong>{" "}
                  <span className="text-gray-600">{classDetails.payment}</span>
                </p>
                <p className="mb-2">
                  <strong>Location:</strong>{" "}
                  <span className="text-gray-600">{classDetails.location}</span>
                </p>
              </div>
            </div>
            {/* <div className="border rounded-md mt-5 bg-[rgb(187,222,251,0.1)]  py-2 items-center gap-4 px-5 w-auto">
                            <HiLocationMarker className="text-[#4FD1C5]" />
                            <input
                              type="text"
                              name="location"
                              value={classDetails.location}
                              onChange={handleChange}
                              className="bg-transparent text-sm placeholder-[#4FD1C5] outline-none"
                              placeholder="Location Here"
                            />
                          </div>
                          {errors.location && (
                            <p className="text-red-500 text-sm mt-2">
                              {errors.location}
                            </p>
                          )} */}

            <div className="flex justify-center items-center mt-8 space-x-6">
              {/* Start Class Button */}
              {/* <button
                onClick={() => {
                  // handleStartClass(classDetails.student_id, classDetails.id)
                  handleSubmit(classDetails.student_id, classDetails.id);
                }}
                disabled={studentData ? true : false}
                className={`${
                  studentData
                    ? "bg-[#72dad0] cursor-not-allowed"
                    : "bg-[#72dad0] hover:scale-105"
                } text-white font-bold py-3 px-6 rounded-full shadow-lg transition-all duration-300 transform ${
                  studentData ? "" : "hover:scale-105"
                } max-sm:text-xs`}
              >
                {studentData ? "Class Started" : "Start Class"}
              </button> */}
            

                <button
        onClick={() => handleSubmit(classDetails.student_id, classDetails.id)}

        disabled={startButtonDisabled}
        className={`${
          startButtonDisabled
            ? "bg-[#72dad0] cursor-not-allowed"
            : "bg-[#72dad0] hover:scale-105"
        } text-white font-bold py-3 px-6 rounded-full shadow-lg transition-all duration-300 transform ${
          startButtonDisabled ? "" : "hover:scale-105"
        } max-sm:text-xs`}
      >
        {startButtonDisabled ? "Class Already Started" : "Start Class"}
      </button>
              {/* View / End Button */}
              <button
                onClick={() => studentDetails(classDetails.id) }
                className="bg-gray-200 max-sm:text-xs hover:bg-gray-300 text-gray-800 font-bold py-3 px-6 rounded-full shadow-lg transition-all duration-300 transform hover:scale-105"
              >
                View / End
              </button>
            </div>
          </div>
        ) : (
          <p className="text-gray-600 italic text-center mt-6">
            No class details found
          </p>
        )}

        {/* Start Class Modal */}
        <div>
          {showModal ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
                <div className="relative xl:mx-auto lg:mx-5 xl:w-1/2 lg:w-9/12  ">
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none overflow-y-auto shiftmodel">
                    <div className="relative text-start">
                      <div className="md:flex items-center max-md:flex-col-reverse md:flex-row">
                        <div className="flex flex-col justify-center relative items-center mx-auto order-2 md:order-1 py-12 md:w-1/2 w-auto text-center">
                          <button
                            className="absolute right-0 top-0 p-3 ml-auto flex md:hidden float-right sm:mt-0 "
                            onClick={() => setShowModal(false)}
                          >
                            <span className="text-[#000] bg-white text-3xl px-1">
                              {" "}
                              ×{" "}
                            </span>
                          </button>
                          <div>
                            <p className="text-2xl font-medium">
                              Enter Class Details
                            </p>
                            <p className="text-xs font-light">
                              Provide location and snap to start the class
                            </p>
                          </div>

                          <p
                            onClick={() => {
                              handleSubmit();
                            }}
                            className="bg-[#5963F5] text-white rounded-md px-7 mt-5 py-2"
                          >
                            Confirm
                          </p>
                        </div>
                        <div className="md:w-1/2 order-1 md:order-2">
                          <button
                            className="absolute right-0 p-3 ml-auto md:flex hidden float-right sm:mt-0 "
                            onClick={() => setShowModal(false)}
                          >
                            <span className="text-[#000] bg-white text-3xl px-1">
                              {" "}
                              ×{" "}
                            </span>
                          </button>
                          <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d924237.7096542217!2d66.49595908273504!3d25.192983663770796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33e06651d4bbf%3A0x9cf92f44555a0c23!2sKarachi%2C%20Karachi%20City%2C%20Sindh%2C%20Pakistan!5e0!3m2!1sen!2s!4v1713914556658!5m2!1sen!2s"
                            className="sm:h-[450px] h-[300px] w-full"
                            title="map"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="fast"
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
        </div>
        <div>
          {showModalSec ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative xl:mx-auto lg:mx-5 xl:w-1/2 lg:w-9/12">
                  {/* Modal content */}
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none overflow-y-auto shiftmodel">
                    {/* Close button */}
                    <button
                      className="absolute top-2 right-4 text-2xl font-bold z-10 text-gray-600 hover:text-gray-800 focus:outline-none"
                      onClick={() => setShowModalSec(false)}
                    >
                      &times;
                    </button>

                    {/* Modal Body */}
                    <div className="relative text-start">
                      <div>
                        <div className="md:flex items-center sm:block">
                          <div className="flex flex-col justify-center items-center mx-auto order-2 md:order-1 p-12 md:w-1/2 w-auto text-center">
                            <div className="mb-4">
                              <div className="flex justify-center">
                                <img src={vector} alt="" />
                              </div>
                              <p className="text-2xl font-bold">
                                Class Details
                              </p>
                            </div>
                            <div className="font-semibold text-black text-start">
                              <h1>Time: 1 hour</h1>
                              <h1>Location: {studentData.location}</h1>
                              {/* <h1>Snap</h1> */}
                              <h1>Total Time: {totalTime} minutes</h1>
                            </div>
                            {totalTime < 60 && (
                              <div>
                                <label
                                  htmlFor="endReason"
                                  className=" text-start pl-2 block text-black font-semibold"
                                >
                                  Reason for Ending Early:
                                </label>
                                <input
                                  type="text"
                                  id="endReason"
                                  value={endReason}
                                  onChange={(e) => setEndReason(e.target.value)}
                                  className="w-full px-8 py-3 bg-[#F5F5F5] border  my-2 rounded-md"
                                  required
                                />
                              </div>
                            )}

                            <div className="mt-8">
                              <button
                                className="bg-[#5963F5] text-white rounded-md px-7 mt-5 py-2"
                                onClick={handleConfirmEnd}
                              >
                                Confirm to End
                              </button>
                            </div>
                          </div>
                          <div className="md:w-1/2 order-1 md:order-2">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d924237.7096542217!2d66.49595908273504!3d25.192983663770796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33e06651d4bbf%3A0x9cf92f44555a0c23!2sKarachi%2C%20Karachi%20City%2C%20Sindh%2C%20Pakistan!5e0!3m2!1sen!2s!4v1713914556658!5m2!1sen!2s"
                              className="sm:h-[450px] h-[300px] w-full"
                              title="map"
                              style={{ border: 0 }}
                              allowFullScreen=""
                              loading="fast"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
        </div>

        <div>
          {detail ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full max-w-4xl lg:max-w-3xl md:max-w-2xl mx-4">
                  {/* Content */}
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none overflow-y-auto shiftmodel">
                    {/* Header */}
                    <div className="flex justify-between items-center p-4 border-b border-gray-200">
                      <h3 className="text-xl font-semibold">
                        Student Classes Details
                      </h3>
                      <button
                        className="text-[#000] bg-white text-3xl px-1"
                        onClick={() => setDetailModal(false)}
                      >
                        ×
                      </button>
                    </div>

                    {/* Body */}
                    <div className="relative p-4">
                      {/* Table for large screens */}
                      <table className="min-w-full divide-y divide-gray-200 hidden sm:table">
                        <thead>
                          <tr>
                            {/* <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Snap
                            </th> */}
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Location
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Start Time
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              End Time
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              End Class
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {studentData ? (
                            <tr>
                              {/* <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                <img
                                  src={`${apiKey}/homeSnap/${studentData.snap}`}
                                  alt=""
                                  className="inline-block mr-2 h-12 w-12 object-cover cursor-pointer"
                                  onClick={() =>
                                    handleImageClick(
                                      `${apiKey}/homeSnap/${studentData.snap}`
                                    )
                                  }
                                />
                              </td> */}
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                {studentData.location}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {studentData.start_time}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {studentData.end_time}
                              </td>
                              <td
                                onClick={() => {
                                  handleStartClassSec(studentData.ttr_id);
                             
                                }}
                                className="px-6 py-4 whitespace-nowrap text-sm cursor-pointer"
                              >
                                <p className="bg-red-300 text-center w-fit px-5 rounded-lg py-1">
                                  End
                                </p>
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td
                                colSpan="5"
                                className="px-6 py-4 text-sm text-gray-500 text-center"
                              >
                                No data available
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>

                      {/* Responsive cards for small screens */}
                      <div className="sm:hidden">
                        {studentData ? (
                          <div className="flex flex-col space-y-4">
                            <div className="border rounded-lg p-4 bg-gray-50">
                              <div className="flex justify-between items-center">
                                <img
                                  src={`${apiKey}/homeSnap/${studentData.snap}`}
                                  alt="snap"
                                  className="h-12 w-12 object-cover cursor-pointer"
                                  onClick={() =>
                                    handleImageClick(
                                      `${apiKey}/homeSnap/${studentData.snap}`
                                    )
                                  }
                                />
                                <div className="flex flex-col items-start text-sm">
                                  <p className="font-semibold">Location:</p>
                                  <p>{studentData.location}</p>
                                </div>
                              </div>
                              <div className="mt-2 text-sm">
                                <p>
                                  <span className="font-semibold">
                                    Start Time:
                                  </span>{" "}
                                  {studentData.start_time}
                                </p>
                                <p>
                                  <span className="font-semibold">
                                    End Time:
                                  </span>{" "}
                                  {studentData.end_time}
                                </p>
                              </div>
                              <div
                                onClick={() =>
                                  handleStartClassSec(studentData.ttr_id)
                                }
                                className="mt-4 bg-red-300 text-center py-1 rounded-lg cursor-pointer text-sm"
                              >
                                End Class
                              </div>
                            </div>
                          </div>
                        ) : (
                          <p className="text-center text-gray-500">
                            No data available
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}

          {/* Image Modal
          {selectedImage && (
            <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-75">
              <div className="relative">
                <img
                  src={selectedImage}
                  alt="Selected Snap"
                  className="max-w-full max-h-screen object-contain"
                />
                <button
                  className="absolute top-0 right-0 m-4 text-white text-3xl"
                  onClick={closeImageModal}
                >
                  ×
                </button>
              </div>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default StartClass;
