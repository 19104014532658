import React, { useState, useEffect, useMemo } from "react";
import UserDropdown from "../../../components/AdminDropDown/UserDropdown";
import { MdNotifications, MdMenu, MdMessage } from "react-icons/md";
import Logo2 from "../../../assets/Edolutions 1.png";
import { Link } from "react-router-dom";
import axios from "axios";
import apiKey from "../../../config/apiKey";
import Echo from "laravel-echo";
import Pusher from "pusher-js";


const TopBar = ({ toggleSidebar, totalUnreadCount }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [messageCount, setMessageCount] = useState(0);
  const [formUser, setFormUser] = useState({});
  const [notifications, setNotifications] = useState([]);

  const student = JSON.parse(localStorage.getItem("edo-student"));
  const userId = student ? student.student.id : "";

  // Memoize the user object to avoid recalculating on every render
  const user = useMemo(
    () => ({
      name: formUser.name,
      admin: "Student",
      id: student ? student.student.id : "",
    }),
    [formUser, student]
  );

  // Fetch Messages
  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const res = await axios.get(
          `${apiKey}/api/edolutions/student/showmessagestudent/${userId}`,
          {
            headers: { Authorization: `Bearer ${student?.token}` },
          }
        );
        let totalUnread = 0;
        res.data.messages_by_teacher.forEach((teacher) => {
          const unread = teacher.messages.filter(
            (msg) => msg.read === 0 && msg.teacher_message !== null
          ).length;
          totalUnread += unread;
        });
        setMessageCount(totalUnread);
      } catch (err) {
        console.error("Error fetching messages:", err);
      }
    };
    if (userId) fetchMessages();
  }, [userId, student?.token]);

  // Fetch User Details
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const res = await axios.get(
          `${apiKey}/api/edolutions/student/getuser/${userId}`,
          {
            headers: { Authorization: `Bearer ${student?.token}` },
          }
        );
        setFormUser(res.data);
      } catch (err) {
        console.error("Error fetching user details:", err);
      }
    };
    if (userId) fetchUserDetails();
  }, [userId, student?.token]);

  // Fetch Notifications (both unread count and notifications list)
  const fetchNotifications = async () => {
    try {
      const [unreadCountRes, notificationsRes] = await Promise.all([
        axios.get(
          `${apiKey}/api/edolutions/student/notifications/unread-count/${user.id}`,
          {
            headers: { Authorization: `Bearer ${student?.token}` },
          }
        ),
        axios.get(
          `${apiKey}/api/edolutions/student/notifications/new/${user.id}`,
          {
            headers: { Authorization: `Bearer ${student?.token}` },
          }
        ),
      ]);
      setNotificationCount(unreadCountRes.data.unreadCount);
      setNotifications(notificationsRes.data);
    } catch (err) {
      console.error("Error fetching notifications:", err);
    }
  };

  // Real-time notification count with Laravel Echo (Reverb)
  useEffect(() => {
    if (student?.token && userId) {
      window.Pusher = Pusher;
    
      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: 'b7245c5f531fd8d69ec7', // Pusher app key
        cluster: 'ap2', // Pusher app cluster (e.g., 'eu')
        forceTLS: true, // Pusher uses TLS by default
        encrypted: true, // Ensures connection encryption
        authEndpoint: `${apiKey}/broadcasting/auth`, // Same endpoint for Pusher
        auth: {
          headers: {
            Authorization: `Bearer ${student?.token}`,
          },
        },
      });

      const channel = window.Echo.private(
        `notification-count-event.student.${userId}`
      );
      channel.listen("NotificationCountStudentEvent", e=> {
        setNotificationCount(e.data)
      });

      const messageCountChannel = window.Echo.private(
        `chat-message-count-event.student.${userId}`
      );
      messageCountChannel.listen("ChatMessageCountStudentEvent", e=> {
        setMessageCount(e.data);
        // setNotificationCount(e.data)
      });

      // return () => {
      //   if (window.Echo) {
      //     channel.stopListening("NotificationCountStudentEvent");
      //   }
      // };
    }
  }, [student?.token, userId]);

  // Fetch notifications on component mount
  useEffect(() => {
    if (user.id) fetchNotifications();
  }, [user.id]);

  // Mark all notifications as read
  const markAllCount = async () => {
    try {
      const markAllPromises = notifications.map((notification) => {
        if (!notification.count) {
          return axios.put(
            `${apiKey}/api/edolutions/student/notifications/mark-as-read/${notification.id}`,
            {},
            { headers: { Authorization: `Bearer ${student?.token}` } }
          );
        }
        return null;
      });

      const filteredPromises = markAllPromises.filter(
        (promise) => promise !== null
      );
      await Promise.all(filteredPromises);

      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) => ({
          ...notification,
          count: true,
        }))
      );
      setNotificationCount(0);
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    }
  };

  // Handle screen resize
  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 1280);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const dropdown = [{ path: "./proSetting" }];

  return (
    <div className="bg-[#FFFFFF] px-6 shadow-md py-6 flex items-center justify-between relative z-10">
      <div className="flex items-center justify-between text-xl">
        <div>
          {isSmallScreen && (
            <MdMenu
              className="text-[#232738] w-8 h-8 me-4 cursor-pointer"
              onClick={toggleSidebar}
            />
          )}
        </div>
        <Link to="./profile">
          <div className="w-48 hidden md:block">
            <img src={Logo2} alt="Logo" />
          </div>
        </Link>
      </div>

      <div className="flex items-center gap-2">
        <div className="relative">
          <Link to="./studentChat">
            <button className="text-[#232738] flex items-center space-x-1 group border p-2 rounded-xl">
              <MdMessage className="w-6 h-6" />
              {(totalUnreadCount > 0 || messageCount > 0) && (
                <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs px-1 rounded-full">
                  {totalUnreadCount > 0 ? totalUnreadCount : messageCount}
                </span>
              )}
            </button>
          </Link>
        </div>

        <div className="relative" onClick={markAllCount}>
          <Link to="./notification">
            <button className="text-[#040405] flex items-center space-x-1 group border p-2 rounded-xl">
              <MdNotifications className="w-6 h-6" />
              {notificationCount > 0 && (
                <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs px-1 rounded-full">
                  {notificationCount}
                </span>
              )}
            </button>
          </Link>
        </div>

        <UserDropdown user={user} dropdown={dropdown} />
      </div>
    </div>
  );
};

export default TopBar;
