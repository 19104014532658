import React from "react";
import { Route, Routes } from "react-router-dom";

import TeacherLogin from "../../pages/LoginPages/TeacherLogin";
import StudentLogin from "../../pages/LoginPages/StudentLogin";
import Login from "../../pages/Login";
import StudentSignUp from "../../pages/LoginPages/StudentSignUp";
import TeacherSignUp from "../../pages/LoginPages/TeacherSignUp";
import EmailVerification from "../../pages/LoginPages/EmailVerification";
import Verification from "../../pages/LoginPages/Verfication";
import Forgetpassword from "../../pages/LoginPages/Forgetpassword";
import AdminLogin from "../../pages/LoginPages/AdminLogin";
import ConfirmPass from "../../pages/LoginPages/ConfirmPass";

function LoginLayout() {
  return (
    <div>
      <Routes>
        <Route path="/admin" element={<AdminLogin />} />
        <Route path="/loginPage/:type" element={<Login />} />
        <Route path="/teacherLogin" element={<TeacherLogin />} />
        <Route path="/teacherSignUp" element={<TeacherSignUp />} />
        <Route path="/studentLogin" element={<StudentLogin />} />
        <Route path="/studentSignUp" element={<StudentSignUp />} />
        <Route path="/emailVerification" element={<EmailVerification />} />
        <Route path="/verification" element={<Verification />} />
        <Route path="/forgetPass" element={<Forgetpassword />} />
        <Route path="/confirmPass/:token" element={<ConfirmPass />} />
      </Routes>
    </div>
  );
}

export default LoginLayout;
