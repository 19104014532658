import React, { useState, useEffect } from "react";
import { getHitID } from "../../../config/HitIDStorage";
import axios from "axios";
import apiKey from "../../../config/apiKey";

const StudentCard = () => {
  const ID = getHitID();
  const admin = JSON.parse(localStorage.getItem("edo-admin"));

  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiKey}/api/edolutions/admin/usersStd/${ID}`,
          {
            headers: {
              Authorization: `Bearer ${admin?.token}`,
            },
          }
        );
        // console.log("Fetched Data:", response.data);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (ID) {
      fetchData();
    }
  }, [ID, admin?.token]);
  return (
    <div className="xl:mx-10 mx-0 ">
      <div className="flex flex-wrap items-center justify-between gap-7 my-10">
        <div className="flex items-center space-x-2">
          <div>
            <img
              className="rounded-full border w-20 h-20"
              src={
                data.photo
                  ? `${apiKey}/studentProfile/${data.photo}`
                  : `${apiKey}/userProfile/87654321.png`
              }
              alt={
                data.name
                  ? `${data.name}'s profile`
                  : "Default profile"
              }
            />
          </div>
          <div>
            <p className="font-semibold text-xl">{data.name}</p>
            <p className="font-normal text-base">Student</p>
          </div>
          {/* <div>
            <p className="flex  xl:mx-6 text-yellow-500"><LiaStarSolid/><LiaStarSolid/><LiaStarSolid/><LiaStarSolid/><LiaStarSolid/></p>
          </div> */}
        </div>
        {/* <div>
          <Link to='../studentProfile'>
            <p className="bg-[#4FD1C5] rounded-lg text-white text-xl font-medium py-3 px-8">Account Setting</p>
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default StudentCard;
