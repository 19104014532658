import React from "react";


const Edolcards = (props) => {
  return (
    <div className="my-24">
      <div className="flex items-center justify-center">
        <div className="flex flex-wrap   max-w-6xl  items-center justify-center  p-4">
          <div className="w-full  md:w-6/12">
            <p className="text-[40px] font-bold leading-[40px] text-[#1E1E1E] small-heading medium-heading" data-aos="fade-left">{props.Title}</p>
            <div className="text-pretty  mt-4 ">

              <p className="text-[20px] small-text  font-normal leading-[27px] text-[#1E1E1E] mt-2" data-aos="fade-down">
                {props.Description}</p>


              <p className="text-[20px] small-text font-normal leading-[27px] text-[#1E1E1E] mt-2" data-aos="fade-down">
                {props.Description2}</p>


              <p className="text-[20px] small-text  font-normal leading-[27px] text-[#1E1E1E] mt-2" data-aos="fade-down">
                {props.Description3}</p>

            </div>
            <div className='float-left'><img data-aos="fade-right" src={props.Image1} alt="" /></div>
          </div>


          <div className="w-full  md:w-6/12">
          <p className="text-[40px] font-bold leading-[40px] text-[#1E1E1E] small-heading medium-heading" data-aos="fade-left">{props.Title1}</p>
          <p className="text-[20px] small-text  font-normal leading-[27px] text-[#1E1E1E] mt-2" data-aos="fade-down">
          {props.Description1}</p>
            <div className='float-right'><img data-aos="fade-right" src={props.Image} alt="" /></div>
          </div>
          
        </div>
      </div>
        <div className="w-full px-10 my-10">
          <p className="text-[20px] small-text  font-normal leading-[27px] text-[#1E1E1E] mt-2" data-aos="fade-down">
          {props.paragraph}</p>
      </div>
    </div>




  );
};

export default Edolcards;

