import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";

const Card = ({ card }) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        className="flex  gap-2 items-center cursor-pointer"
        onClick={() => navigate(-1)}
      >
        <BiArrowBack className="h-5 w-5" />
        <p className="text-[14px]">Back</p>
      </div>

      {card &&
        card.map((index) => (
          <div className="xl:mx-10 mx-0 ">
            <div className="flex flex-wrap items-center justify-between gap-7 my-7">
              <div className="">
                <div>
                  <img
                    className="rounded-full border w-20 h-20"
                    src={index.profile}
                    alt=""
                  />
                </div>
                <div>
                  <p className="font-semibold text-xl">{index.name}</p>
                  <p className="font-normal text-base">{index.charge}</p>
                </div>
              </div>

              <div className="">
                <Link
                  to={index.path}
                  className="bg-[#59F5DE] hover:bg-blue-600 text-white  text-xl font-medium py-3 px-8 rounded-lg focus:outline-none focus:shadow-outline"
                >
                  Cancel Request
                </Link>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default Card;
