import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import users from "../../assets/user.png";
import { IoIosArrowDown } from "react-icons/io";
import axios from "axios";
import apiKey from "../../config/apiKey";

export default function DashboardDropdown({ user, dropdown }) {
  const teacher = JSON.parse(localStorage.getItem("edo-teacher"));
  const student = JSON.parse(localStorage.getItem("edo-student"));
  const admin = JSON.parse(localStorage.getItem("edo-admin"));
  const [showDropdown, setShowDropDown] = useState(false);
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    const userType = admin ? "admin" : teacher ? "teacher" : "student";
    const userToken = admin
      ? admin.token
      : teacher
      ? teacher.token
      : student.token;

    axios
      .post(
        `${apiKey}/api/edolutions/${userType}/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {})
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        localStorage.removeItem(`edo-${userType}`);
        if (userType === "admin") {
          navigate("/admin");
        } else {
          navigate(`/login/${userType}Login`);
        }
      });
  };

  const handleOptionClick = () => {
    setShowDropDown(false);
  };

  useEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  return (
    <span className="relative">
      <div onClick={() => setShowDropDown(!showDropdown)}>
        <div
          className="flex items-center space-x-2 text-[#232738] border-2 p-2 px-4 mr-5 rounded-xl cursor-pointer"
          onClick={() => setOpen(!open)}
        >
          <img src={users} alt="" className="h-8 md:h-6" />

          {user && (
            <div className="">
              <p className="text-[12px] capitalize font-bold md:text-[9px]">
                {user.name}
              </p>
              <p className="text-[10px]">{user.admin}</p>
            </div>
          )}

          <IoIosArrowDown
            className={`transition-transform ${open ? "rotate-180" : ""}`}
          />
        </div>
      </div>
      <div
        className={
          (showDropdown ? "block " : "hidden ") +
          "absolute top-13 left-32 -translate-x-[90%] bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-44"
        }
      >
        {dropdown &&
          dropdown.map((data) => (
            <div
              key={data.path}
              className="text-sm py-2 text-[#232738] px-3 font-semibold block w-full whitespace-nowrap bg-transparent text-blueGray-700"
              onClick={(e) => {
                e.preventDefault();
                handleOptionClick();
              }}
            >
              <NavLink to={data.path} className="flex gap-2 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className="fill-[#4FD1C5]"
                  height="16"
                  width="25"
                >
                  <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                </svg>

                <span className="">Edit Profile</span>
              </NavLink>
            </div>
          ))}

        <div
          className="text-sm py-2 text-[#232738] px-3 font-semibold block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          onClick={(e) => e.preventDefault()}
        >
          <button onClick={handleLogout} className="flex gap-2 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className="fill-[#4FD1C5]"
              height="16"
              width="25"
            >
              <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z" />
            </svg>

            <span className="">Logout</span>
          </button>
        </div>
      </div>
    </span>
  );
}
