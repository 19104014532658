import React, { useState, useEffect } from "react";
import hand from "../../../assets/waving-hand-sign_1f44b 1.png";
import v1 from "../../../assets/v1.png";
import { Link } from "react-router-dom";
import { MdPlayArrow } from "react-icons/md";
import StudentDas1 from "../../../assets/StudentDas1.png";
import StudentDas2 from "../../../assets/StudentDas2.png";
import StudentDas3 from "../../../assets/StudentDas3.png";
import Banner from "../../../assets/Group 75.png";
import axios from "axios";
import apiKey from "../../../config/apiKey";

const StudentMainBody = () => {
  const student = JSON.parse(localStorage.getItem("edo-student"));
  // name
  const user = {
    id: student ? student.student.id : "",
  };
  const [formUser, setFormUser] = useState({});
  useEffect(() => {
    axios
      .get(`${apiKey}/api/edolutions/student/getuser/${user.id}`, {
        headers: {
          Authorization: `Bearer ${student?.token}`,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setFormUser(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [student?.token, user.id]);

  const userId = student ? student.student.id : "";
  const [loading, setLoading] = useState(true); // Add loading state
  const [data, setData] = useState(0);
  const [dataTable, setDataTable] = useState([]);
  const [uniqueTeacherCount, setUniqueTeacherCount] = useState(0);
  useEffect(() => {
    // Replace 'your_api_endpoint' with the actual endpoint URL

    const fetchData = async () => {
      setLoading(true); // Start loading before data fetch
      try {
        const response = await axios.get(
          `${apiKey}/api/edolutions/student/read-notifications-all/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${student?.token}`,
            },
          }
        );
        // Extract the data array from the response
        const notifications = response.data.data;
        setData(response.data.data.length);
        setDataTable(response.data.data);

        // Create a Set to store unique teacher_id values
        const uniqueTeacherIds = new Set();

        // Iterate through notifications and add teacher_id to the Set
        notifications.forEach((notification) => {
          uniqueTeacherIds.add(notification.teacher_id);
        });

        // Set the count of unique teacher_ids
        setUniqueTeacherCount(uniqueTeacherIds.size);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Stop loading after data fetch
      }
    };

    if (userId) {
      fetchData();
    }
  }, [userId, student?.token]);

  const getLastRows = (n) => {
    return dataTable.slice(-n);
  };

  return (
    <div className="relative my-4 flex flex-col min-w-0 break-words w-full mb-6 shadow-lg  rounded-lg p-4 border-0">
      <div className="bg-gray-200 ">
        <div className="container-fluid  ">
          <div className=" ">
            <div className="bg-white   px-4 py-4 ">
              <div className="flex items-center space-x-2">
                <img src={hand} alt="" />
                <p className="text-[18px] font-bold capitalize">
                  Hey {formUser.name}
                </p>
              </div>
              <div>
                <p className="text-[24px] font-bold mt-2 ">
                  Dashboard{" "}
                  <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#2DD0A5] to-purple-400">
                    Home
                  </span>
                </p>
              </div>
              <div className="flex justify-center mt-10">
                <img src={Banner} alt="" />
              </div>

              {loading ? (
                <div>
                  <div colSpan="9" className="py-5 px-1 text-center">
                    <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-200 bg-opacity-5 backdrop-blur-sm z-50">
                      <div className="w-12 h-12 border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin border-t-[#2DD0A5]"></div>
                      <p className="mt-4 text-lg font-semibold text-[#2dd0a5]">
                        Edolution...
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-wrap items-center justify-evenly mt-10 ">
                  <div className="flex items-center space-x-3 px-3 py-8 ">
                    <div>
                      {" "}
                      <img src={StudentDas1} alt="" />
                    </div>
                    <div>
                      <p className="text-sm text-[#ACACAC] font-normal">
                        Enroll Subjects
                      </p>
                      <p className="text-3xl font-semibold">{data}</p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-3 lg:border-l-2 border-0  px-8 py-8">
                    <div>
                      {" "}
                      <img src={StudentDas2} alt="" />
                    </div>
                    <div>
                      <p className="text-sm text-[#ACACAC] font-normal">
                        Total Teacher
                      </p>
                      <p className="text-3xl font-semibold">
                        {uniqueTeacherCount}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-3 lg:border-l-2 border-0 px-8 py-8">
                    <div>
                      {" "}
                      <img src={StudentDas3} alt="" />
                    </div>
                    <div>
                      <p className="text-sm text-[#ACACAC] font-normal">
                        Classes Complete
                      </p>
                      <p className="text-3xl font-semibold">0</p>
                    </div>
                  </div>
                </div>
              )}

              <div className="flex items-center justify-between px-6 mt-10">
                <div>
                  <p className="lg:text-[24px] md:text-[22px]  font-bold text-[#121212]">
                    My Teachers
                  </p>
                </div>

                <Link to="/student/myTutoring">
                  <div className="flex items-center space-x-2 cursor-pointer">
                    <p className="lg:text-[20px] md:text-[15px] font-semibold text-[#2BACE2]">
                      View all
                    </p>
                    <p className="text-[#000]">
                      <MdPlayArrow />
                    </p>
                  </div>
                </Link>
              </div>

              <div className="flex flex-col mt-8">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className=" overflow-hidden  border-gray-200 ">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-[20px] font-medium text-[#94918A] tracking-wider w-2/12"
                            >
                              Teacher
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3  text-[20px] font-medium text-[#94918A]  tracking-wider w-2/12"
                            >
                              Subject
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3  text-[20px] font-medium text-[#94918A]  tracking-wider w-2/12"
                            >
                              Class
                            </th>

                            <th
                              scope="col"
                              className="px-6 py-3  text-[20px] font-medium text-[#94918A] tracking-wider w-2/12"
                            >
                              Day
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3  text-[20px] font-medium text-[#94918A] tracking-wider w-2/12"
                            >
                              Type
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {getLastRows(3).map((item, index) => (
                            <tr key={index}>
                              <th className="flex items-center space-x-4">
                                <div>
                                  <img src={v1} alt="" />
                                </div>
                                <div className="text-start">
                                  <p className="text-[20px] font-medium capitalize">
                                    {item.teacher_name}
                                  </p>
                                </div>
                              </th>
                              <th>{item.subjects}</th>
                              <th>{item.class_name}</th>
                              <th className="capitalize">
                                {item.day_name ? item.day_name : "Undefined"}
                              </th>

                              <th className="text-[12px]">
                                <button className="border text-sm rounded-lg px-1 bg-[#FAFAF8] capitalize">
                                  {item.type_name}
                                </button>
                              </th>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentMainBody;
