import React from "react";
import logo from "../assets/logo.png";
import { FaFacebook } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <div className="flex items-center justify-center p-4 mt-10">
        <div className="flex flex-wrap w-full justify-center">
          <div className="w-full sm:w-2/4 p-4">
            <Link to="/">
              <img className="w-[256px] h-[43.78]" src={logo} alt="" />
            </Link>
            <p></p>
          </div>
          <div className="w-full sm:w-2/4 p-4 ">
            {/* <div>
              <ul className="flex flex-wrap justify-evenly gap-4 cursor-pointer">
                <li className="text-center text-[20px] font-bold text-[#722DC0]">
                  Teacher
                </li>
                <li className="text-center text-[20px] font-bold text-[#722DC0]">
                  Student
                </li>
                <li className="text-center text-[20px] font-bold text-[#722DC0]">
                  How it Works
                </li>
                <li className="text-center text-[20px] font-bold text-[#722DC0]">
                  Insights
                </li>
              </ul>
            </div>
            <hr className="mt-4" /> */}
            <div>
              <ul className="flex flex-wrap justify-evenly gap-4 cursor-pointer">
                <Link to='/returnpolicy' className="text-center text-lg">
                Return Policy
                </Link>
                <Link to='/privacy' className="text-center text-lg">
                Privacy Policy
                </Link>
                <li className="text-center text-lg">
                FAQs
                </li>
                <li className="text-center text-lg">
                <Link to= '../contactUs'>
                Contact us
                </Link>
                </li>
                <Link to='/termsconditions' className="text-center text-lg">
                Terms & Conditions
                </Link>
              </ul>
            </div>

          </div>
        </div>
      </div>

      {/* below */}

      <div className="flex items-center justify-center px-6">
        <div className="w-full">
          <hr />
        </div>
      </div>

      <div className="flex items-center justify-center p-1">
        <div className="flex flex-wrap w-full justify-between items-center">
          <div className="w-full sm:w-2/4 p-4 ">
            <p className="text-[20px] font-semibold text-[#7D7D7D]">
              Edoultions 2024 All rights Reserved
            </p>
          </div>
          <div className="w-full sm:w-2/4 p-4">
            <div className="flex md:float-right items-center justify-center text-[24px]">

              <a
                href="https://www.facebook.com/share/8AAhadg1svt7Mq7N/?mibextid=LQQJ4d"
                target="_blank"
                rel="noopener noreferrer"
                className="mx-4 text-[#767676]"
              >
                <FaFacebook />
              </a>



              <a
                href="https://www.linkedin.com/company/edolutions/"
                target="_blank"
                rel="noopener noreferrer"
                className="mx-4 text-[#767676]"
              >
                <FaLinkedin />
              </a>
{/* 
              <a
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                className="mx-4 text-[#767676]"
              >
                <FaSquareXTwitter />
              </a> */}

              <a
                href="https://www.instagram.com/edolutionsonline?igsh=MWI1NGk2bmlpazdrZQ=="
                target="_blank"
                rel="noopener noreferrer"
                className="mx-4 text-[#767676]"
              >
                <FaSquareInstagram  />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
