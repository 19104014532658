import React, { useState } from 'react'
import { FaWandMagicSparkles } from 'react-icons/fa6';
import { NavLink } from "react-router-dom";



const NotificationSetting = () => {

  const [data] = useState([
    {
      name:'New Teacher',
      lable:"New teacher enrollment"
    },
    {
      name:'New Student',
      lable:"New student enrollment"
    },
    {
      name:'Message',
      lable:"New message alert"
    },
    {
      name:'Support',
      lable:"New Support Call"
    },
  ])
  return (

    <>
      <div className="flex items-center xl:p-5 sm:p-0 justify-center ">
        <div className="w-full border rounded-3xl shadow-xl p-5">
          <div>

            <div>
              <p className='text-2xl font-bold'>Setting</p>
              <p className='text-[#22B7AA] font-light text-sm'>Admin Setting</p>
            </div>
            <div className=" flex border-b-2 justify-between items-center mt-8">
              <div className='flex flex-wrap md:justify-start md:space-x-12  space-x-5  text-base font-medium '>

                <NavLink to=".././myAccount" className={({ isActive }) => (isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined)}>My Account</NavLink>

                {/* <NavLink to=".././webSetting" className={({ isActive }) => (isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined)}>Website</NavLink>

             

                <NavLink to=".././settingNoti" className={({ isActive }) => (isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined)}>Notification</NavLink>

                <NavLink to=".././emailTemplate" className={({ isActive }) => (isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined)}>Email Template</NavLink> */}

              </div>

              
            </div>

              {/* content--------------------------- */}
<div className='px-10'>


              <div className="border-b pb-5 my-10">
          <p className="font-bold text-lg">Notification</p>
          <p className="font-normal text-sm">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </p>
        </div>
              {data && data.map((index)=>(
            <div className='grid grid-cols-2 gap-4 my-4 bg-[#A3E7E11C] p-4'>

                <div className='flex gap-3'>
                  <div>
                  <FaWandMagicSparkles className='h-10 w-8' />
                </div>
                <div>
                  <h1 className='text-[#2D3748] text-[14px] font-semibold'>
                    {index.name}
                  </h1>
                  <p className='text-[#2D3748] text-[12px]'>{index.lable}</p>
                </div>
              </div>

              <label className="inline-flex items-center mb-5 cursor-pointer justify-end">
                <input type="checkbox" value="" className="sr-only peer" />
                <div className="relative w-11 h-6 bg-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-500 dark:peer-focus:ring-white-500 rounded-full peer dark:bg-gray-300 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-blue-500 after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-blue-500 after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-blue-500 peer-checked:bg-blue-500"></div>

              </label>

            </div>

))}
</div>
          </div>

        </div>
      </div>
    </>

  );
}

export default NotificationSetting
