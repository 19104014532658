import React, { useState } from 'react'
import { BiUpload } from 'react-icons/bi';
import { FaWandMagicSparkles } from 'react-icons/fa6';
import { NavLink } from "react-router-dom";



const EmailTemplate = () => {
  const [showModal, setShowModal] = useState(false);


  return (

    <>
      <div className="flex items-center xl:p-5 sm:p-0 justify-center ">
        <div className="w-full border rounded-3xl shadow-xl p-5">
          <div>

            <div>
              <p className='text-2xl font-bold'>Setting</p>
              <p className='text-[#22B7AA] font-light text-sm'>Admin Setting</p>
            </div>
            <div className=" flex border-b-2 justify-between items-center mt-8">
              <div className='flex flex-wrap md:justify-start md:space-x-12  space-x-5  text-base font-medium '>

                <NavLink to=".././myAccount" className={({ isActive }) => (isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined)}>My Account</NavLink>

                {/* <NavLink to=".././webSetting" className={({ isActive }) => (isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined)}>Website</NavLink>

              

                <NavLink to=".././settingNoti" className={({ isActive }) => (isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined)}>Notification</NavLink>

                <NavLink to=".././emailTemplate" className={({ isActive }) => (isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined)}>Email Template</NavLink> */}

              </div>


            </div>

            {/* content--------------------------- */}
            <div className="lg:p-8 p-2 h-screen">
              <div className="lg:py-2 lg:px-5 p-1">
                <div className="md:flex block space-y-3 justify-between border-b-2 pb-5">
                  <div className="">
                    <p className="font-bold text-lg">Email Template</p>
                    <p className="font-normal text-sm">
                      Lorem Ipsum is simply dummy text of the printing and typesetting
                      industry.
                    </p>
                  </div>

                  <div className=" border border-[#5963F5] flex items-center text-sm font-semibold rounded-md w-fit text-white px-5 py-2 cursor-pointer " onClick={() => setShowModal(true)}>
                    <p>< BiUpload className="w-6 h-6 text-[#5963F5]" /></p>
                    <p className="text-[#5963F5] pl-2">Upload</p>
                  </div>
                </div>

                <div className=" space-y-3 mt-8">
                  <div className=" md:flex block  justify-between items-center p-2 bg-teal-200 bg-opacity-10">
                    <div className="flex items-center gap-5 px-2">
                      <div>
                        <p>
                          <FaWandMagicSparkles className='h-10 w-8' />
                        </p>
                      </div>
                      <div>
                        <p className="text-md font-semibold">Template Name</p>
                        <p className="text-sm font-light"> Email Template</p>
                      </div>
                    </div>

                    <div className="px-10 flex justify-start">
                      <div className="">
                        <label className="inline-flex items-center cursor-pointer justify-end">
                          <input type="checkbox" value="" className="sr-only peer " />
                          <div className="relative w-11 h-6 bg-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue dark:peer-focus:ring-blue-500 rounded-full peer dark:bg-gray-300 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-blue-500 after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-blue-500 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-blue-500 peer-checked:bg-blue-500"></div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-end mt-8">
                  <div className=" bg-[#5963F5] text-sm font-semibold rounded-md text-white px-5 py-2">
                    <p>Changes Save</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        {/* modal---------------------- */}
        {
          showModal ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative mx-auto w-1/2 p-10">
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex item-center flex-col  bg-white outline-none focus:outline-none overflow-y-auto shiftmodel">
                    {/*header*/}
                    <div className=" justify-center items-center p-5 border-solid border-blueGray-200 rounded-t">

                      <button
                        className=" ml-auto  float-right text-2xl" onClick={() => setShowModal(false)} >
                        <span className="text-[#000]"> × </span>
                      </button>
                    </div>





                    <div className="text-center mt-10">
                      <h1 className="text-[24px] font-semibold text-[#000]">Upload Email Template</h1>
                      <p className="text-[12px] font-semibold text-[#2D3748]"> Lorem Ipsum is simply dummy text of the printing.</p>
                    </div>


                    {/*body*/}
                    <div className="relative px-10 text-start ">
                      <div className="flex justify-between p-3 items-center text-[12px]  bg-[#BBDEFB1A] 
                      px-10 mt-5 ">
                        <p className='text-[#B5B7C0]'>file</p>

                        <input type="file" id="uploadBtn" className=''/>
                        <label htmlFor="uploadBtn" className="text-[#66BCE8] cursor-pointer select-none	">Browser</label>
                      </div>


                    </div>
                    <div className="text-center my-10">
                      <button className="font-semibold text-white text-[20px]  border-2 rounded-lg bg-[#5963F5] px-6 cursor-pointer py-2" >Upload </button>
                    </div>
                    </div>
                  </div>
                </div>
             
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null
        }

      </div>
    </>

  );
}

export default EmailTemplate
