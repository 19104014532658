import React from "react";
import tutors from "../assets/Tutors.png";

const Tutors = () => {
  return (
    <div>
      
      <div className="flex items-center justify-center mt-20">
        <div className="flex flex-wrap sm:w-9/12 items-center justify-center p-4 ">
          <p className="text-[40px] font-bold text-[#1E1E1E] text-center small-heading medium-heading" data-aos="fade-down">
            What we look for in Our Tutors
          </p>
        </div>
      </div>

      <div className="flex justify-center">
        <div className="w-9/12 max-w-screen-lg">
          <div className="flex flex-wrap items-center justify-evenly p-6">
            <div className="w-[271px] my-2 p-2 text-[20px] font-semibold text-black bg-white text-start" data-aos="fade-right">
            <p>• Commitment</p>
            </div>
            <div className="w-[271px] my-2 p-2 text-[20px] font-semibold text-black bg-white text-start" data-aos="fade-down">
           <p>• Passion</p>
            </div>
            <div className="w-[271px] my-2 p-2 text-[20px] font-semibold text-black bg-white text-start" data-aos="fade-left">
              <p>• Experience</p>
            </div>
            <div className="w-[271px] my-2 p-2 text-[20px] font-semibold text-black bg-white text-start" data-aos="fade-right" >
              <p>• Reliability</p>
            </div>
            <div className="w-[271px] my-2 p-2 text-[20px] font-semibold text-black bg-white text-start" data-aos="fade-up">
              <p>• Communication</p>
            </div>
            <div className="w-[271px] my-2 p-2 text-[20px] font-semibold text-black bg-white text-start" data-aos="fade-left">
             <p>• Adaptability</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-center mt-[-55px]">
        <div className="flex flex-wrap sm:w-9/12 items-center justify-center p-4 ">
          <img src={tutors} alt="" data-aos="flip-left" />
        </div>
      </div>
      {/* <img src={landanimate3} className='w-[310px] absolute top-[1500px] left-[-20px] hidden xl:block' alt="" /> */}


      {/* <img src={landanimate} className='w-[222px] -rotate-[140deg] absolute right-[-50px] top-[1050px] hidden xl:block  'alt="" /> */}
    </div>
  );
};

export default Tutors;
