import React, { useState, useEffect } from 'react';
import Book from "../../assets/Book.png";
import v1 from "../../assets/v1.png";
import v2 from "../../assets/v2.png";
import v3 from "../../assets/v3.png";
import group from "../../assets/Group 300.png";
import { CiStar } from "react-icons/ci";
import { BsSave } from "react-icons/bs";
import { GiTeacher } from "react-icons/gi";
import { BiUser } from "react-icons/bi";
import { MdKeyboardArrowRight, MdPlayArrow } from 'react-icons/md';
import { PiChalkboardTeacherFill } from 'react-icons/pi';


const Dashboard = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {

    const calculateProgress = () => {

      const calculatedProgress = 50;
      return calculatedProgress;
    };


    const calculatedProgress = calculateProgress();


    setProgress(calculatedProgress);
  }, []);
  return (
    <>
      <div className="relative my-4 flex flex-col min-w-0 break-words w-full mb-6 shadow-lg  rounded-lg p-4 border-0" >
        <body className="bg-gray-200 ">
          <div className="container-fluid  ">
            <div className="grid grid-cols-1 sm:grid-cols-3 ">
              <div className="bg-white   px-4 py-4 col-span-1 sm:col-span-2 md:col-span-2 lg:col-span-2 xl:col-span-2">
                <div className="flex items-center space-x-1">
                  <img src={Book} alt="" />
                  <p className="text-[18px] font-bold">Hey Irfan Ahsan</p>
                </div>
                <div>
                  <p className="text-[24px] font-bold mt-2 ">
                    Dashboard{" "}
                    <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#2DD0A5] to-purple-400">
                      Home
                    </span>
                  </p>
                </div>
                <div className="text-[15px] font-medium text-[#979CA5]">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 md:grid-cols-1 gap-4 mt-5">
                  <div className="shadow-xl p-8 rounded-xl sm:row-span-2 lg:col-span-2 md:col-span-4 ">
                    <div className="flex items-center justify-between">
                      <p className="text-[24px]  font-bold">Total Teachers</p>
                      <p className="text-[32px] font-bold">20</p>

                    </div>
                  </div>

                  {/* Replace the following div with the new one */}
                  <div className="shadow-xl p-8 rounded-xl sm:row-span-4 lg:row-span-6 lg:col-span-2 sm:col-span-2 md:row-span-2 md:col-span-4">
                    <div className='flex flex-col space-y-24 '>
                      <div>
                        <p className="text-[24px] font-bold">Product Analytics</p>
                        <p className="text-[14px] font-bold text-[#979CA5] ">
                          Middle/Pro
                        </p>
                      </div>

                      <div className="progress-bar-with-content">
                        <div className="flex items-center justify-between">
                          <p className="text-sm font-bold">Progress</p>
                          <p className="text-sm font-bold text-[#4FD1C5]">{progress}%</p>
                        </div>
                        <div className="w-full bg-gray-200 rounded-lg">
                          <div className="bg-[#4FD1C5] rounded-lg py-2" style={{ width: `${progress}%` }}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End of replaced div */}

                  <div className="shadow-xl p-8 rounded-xl  sm:row-span-2 lg:col-span-2 md:row-span-4 md:col-span-4">
                    <div className="flex items-center justify-between">
                      <div>
                        <p className="text-[24px] font-bold">Total Students</p>
                      </div>
                      <div>
                        <p className="text-[32px] font-bold">120</p>
                      </div>
                    </div>
                    <img src={group} alt="" className='float-end' />
                  </div>
                </div>

                <div className="flex items-center justify-between px-6 mt-10">
                  <div>
                    <p className="lg:text-[24px] md:text-[22px]  font-bold text-[#121212]">
                      Featured Top Rated Teachers
                    </p>
                  </div>

                  <div className="flex items-center space-x-2 cursor-pointer">
                    <p className="lg:text-[20px] md:text-[15px] font-semibold text-[#2BACE2]">
                      View all
                    </p>
                    <p className="text-[#000]">
                      <MdPlayArrow />
                    </p>
                  </div>
                </div>

                <div className="flex flex-col mt-8">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className=" overflow-hidden  border-gray-200 ">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-[20px] font-medium text-[#94918A] tracking-wider w-4/12"
                              >
                                Techers name
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3  text-[20px] font-medium text-[#94918A]  tracking-wider w-2/12"
                              >
                                Joining
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3  text-[20px] font-medium text-[#94918A]  tracking-wider w-2/12"
                              >
                                Rate
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3  text-[20px] font-medium text-[#94918A] tracking-wider w-2/12"
                              >
                                Type
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3  text-[20px] font-medium text-[#94918A] tracking-wider w-2/12"
                              >
                                Save
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            <tr>
                              <th className="flex items-center space-x-4">
                                <div>
                                  <img src={v1} alt="" />
                                </div>
                                <div className="text-start">
                                  <p className="text-[20px] font-medium">
                                    Teacher Name
                                  </p>
                                  <p className="text-[14px] font-medium text-[#94918A]">
                                    Subject
                                  </p>
                                </div>
                              </th>
                              <th>Feb 12</th>
                              <th>
                                <div className="flex items-center justify-center space-x-1">
                                  <p className="text-yellow-400 ">
                                    <CiStar />
                                  </p>
                                  <p>4.8</p>
                                </div>
                              </th>
                              <th className="text-[12px]">
                                <button className="border rounded-lg px-1 bg-[#FAFAF8]">
                                  Online Tuition
                                </button>
                              </th>
                              <th>
                                <div className="flex justify-center">
                                  <BsSave />
                                </div>
                              </th>
                            </tr>
                            <tr>
                              <th className="flex items-center space-x-4 my-2">
                                <div>
                                  <img src={v2} alt="" />
                                </div>
                                <div className="text-start">
                                  <p className="text-[20px] font-medium">
                                    Teacher Name
                                  </p>
                                  <p className="text-[14px] font-medium text-[#94918A]">
                                    Subject
                                  </p>
                                </div>
                              </th>
                              <th>Feb 12</th>
                              <th>
                                <div className="flex items-center justify-center space-x-1">
                                  <p className="text-yellow-400">
                                    <CiStar />
                                  </p>
                                  <p>4.8</p>
                                </div>
                              </th>
                              <th className="text-[12px]">
                                <button className="border rounded-lg px-1 bg-[#FAFAF8]">
                                  Online Tuition
                                </button>
                              </th>
                              <th>
                                <div className="flex justify-center">
                                  <BsSave />
                                </div>
                              </th>
                            </tr>
                            <tr>
                              <th className="flex items-center space-x-4">
                                <div>
                                  <img src={v3} alt="" />
                                </div>
                                <div className="text-start">
                                  <p className="text-[20px] font-medium">
                                    Teacher Name
                                  </p>
                                  <p className="text-[14px] font-medium text-[#94918A]">
                                    Subject
                                  </p>
                                </div>
                              </th>
                              <th>Feb 12</th>
                              <th>
                                <div className="flex items-center justify-center space-x-1">
                                  <p className="text-yellow-400">
                                    <CiStar />
                                  </p>
                                  <p>4.8</p>
                                </div>
                              </th>
                              <th className="text-[12px]">
                                <button className="border rounded-lg px-1 bg-[#FAFAF8]">
                                  Online Tuition
                                </button>
                              </th>
                              <th>
                                <div className="flex justify-center">
                                  <BsSave />
                                </div>
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white border-l-2 p-4  col-span-1 sm:col-span-1 md:col-span-1 lg:col-span-1 xl:col-span-1 px-8 py-5 overflow-x-auto cursor-pointer">
                <div className="flex justify-between items-center px-4">
                  <p className="text-[14px] font-bold text-[#232738]">
                    Classes Ongoing
                  </p>
                  <p className="text-[14px] font-bold text-[#232738]">...</p>
                </div>

                <div className="mt-5 space-y-2">
                  <div className=" rounded border   px-3 py-2 flex items-center space-x-4 
                   hover:text-[#4FD1C5] ">
                    <div className="text-[42px]">
                      <GiTeacher />
                    </div>
                    <div>
                      <p className="text-sm font-semibold">Primary Class()</p>
                    </div>
                  </div>
                  <div className="border px-3 py-2 rounded-lg flex items-center space-x-4 
                   hover:text-[#4FD1C5] ">
                    <div className="text-[42px]">
                      <GiTeacher />
                    </div>
                    <div>
                      <p className="text-sm font-semibold">
                        Primary Class() <br />
                        (Online)
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex justify-between items-center px-4 mt-5">
                  <p className="text-[14px] font-bold text-[#232738]">
                    Today Completed Classes
                  </p>
                  <p className="text-[14px] font-bold text-[#232738]">...</p>
                </div>

                <div className="mt-5 space-y-2">
                  <div className="border px-3 py-2 rounded-lg flex items-center space-x-4 
                   hover:text-[#4FD1C5] ">
                    <div className="text-[42px]">
                      <PiChalkboardTeacherFill />
                    </div>
                    <div className="space-y-1">
                      <p className="text-sm font-semibold">
                        Class Name(Details)
                      </p>
                      <div className="flex justify-between text-[10px]">
                        <p>09.20 PM</p>
                        <p>04 Jan</p>
                      </div>
                    </div>
                  </div>

                  <div className="border  px-3 py-2 rounded-lg flex items-center space-x-4  hover:text-[#4FD1C5]">
                    <div className="text-[42px]">
                      <PiChalkboardTeacherFill />
                    </div>
                    <div className="space-y-1">
                      <p className="text-sm font-semibold">
                        Class Name(Details)
                      </p>
                      <div className="flex justify-between text-[10px]">
                        <p>09.20 PM</p>
                        <p>04 Jan</p>
                      </div>
                    </div>
                  </div>

                  <div className="border  px-3 py-2 rounded-lg flex items-center space-x-4  hover:text-[#4FD1C5]">
                    <div className="text-[42px]">
                      <PiChalkboardTeacherFill />
                    </div>
                    <div className="space-y-1">
                      <p className="text-sm font-semibold">
                        Class Name(Details)
                      </p>
                      <div className="flex justify-between text-[10px]">
                        <p>09.20 PM</p>
                        <p>04 Jan</p>
                      </div>
                    </div>
                  </div>

                  <div className="border  px-3 py-2 rounded-lg flex items-center space-x-4  hover:text-[#4FD1C5]">
                    <div className="text-[42px]">
                      <PiChalkboardTeacherFill />
                    </div>
                    <div className="space-y-1">
                      <p className="text-sm font-semibold">
                        Class Name(Details)
                      </p>
                      <div className="flex justify-between text-[10px]">
                        <p>09.20 PM</p>
                        <p>04 Jan</p>
                      </div>
                    </div>
                  </div>

                  <div className="border  px-3 py-2 rounded-lg flex items-center space-x-4 hover:text-[#4FD1C5]">
                    <div className="text-[42px]">
                      <PiChalkboardTeacherFill />
                    </div>
                    <div className="space-y-1">
                      <p className="text-sm font-semibold">
                        Class Name(Details)
                      </p>
                      <div className="flex justify-between text-[10px]">
                        <p>09.20 PM</p>
                        <p>04 Jan</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-between items-center px-4 mt-5">
                  <p className="text-[14px] font-bold text-[#232738]">
                    Last 12 hours classes List
                  </p>
                  <p className="text-[14px] font-bold text-[#232738]">...</p>
                </div>

                <div className="border px-3 py-3 gap-5  rounded-lg mt-5 ">
                  <div className="flex items-center justify-between">
                    <div className="text-[35px] border bg-gray-200 rounded-full p-1">
                      <BiUser />
                    </div>
                    <div className="text-[12px]">
                      <p>Jan2,12:31</p>
                    </div>
                  </div>

                  <div className="flex items-center justify-between mt-7">
                    <div>
                      <p className="text-[12px] font-semibold text-[#232738]">James Robinson</p>
                      <p className="text-[10px] font-normal text-[#232738]">I need some maintenac...</p>
                    </div>
                    <div>
                      <MdKeyboardArrowRight className='h-8 w-8' />
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </body>
      </div>

    </>
  )
}

export default Dashboard
