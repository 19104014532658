import img from "../assets/EdoluitonBanner.png";
import img2 from "../assets/EdoluitonBanner2.png";
import React, { useState, useEffect } from "react";
import { FaFile } from "react-icons/fa";
import { LiaStarSolid } from "react-icons/lia";
import { BiArrowBack } from "react-icons/bi";
import { IoChevronDownOutline, IoChevronUp } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import apiKey from "../config/apiKey";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  FaFacebook,
  FaWhatsapp,
  FaLinkedin,
  FaInstagram,
  FaMapMarkerAlt,
  FaLink,
  FaClipboard,
} from "react-icons/fa";

const TeacherMainProfile = () => {
  const student = JSON.parse(localStorage.getItem("edo-student"));
  const navigate = useNavigate();
  const [teacherDetails, setTeacherDetails] = useState({});
  const [teacherDetails1, setTeacherDetails1] = useState({});
  const [bySubjectsData, setBySubjectsData] = useState({});
  const [bySkillData, setBySkillData] = useState([]);
  const [byTimeSlotData, setByTimeSlotData] = useState([]);
  const [isAlwaysAvailable, setIsAlwaysAvailable] = useState(false);
  const { id } = useParams(); // Fetch id parameter from URL

  const [loading, setLoading] = useState(true); // Add loading state
  const [expandedClasses, setExpandedClasses] = useState({});
  const [visibleCount, setVisibleCount] = useState(4);
  const [showAll, setShowAll] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false); // Modal state

  // Fetch teacher details when component mounts
  useEffect(() => {
    setLoading(true); // Start loading before data fetch
    axios
      .get(`${apiKey}/api/edolutions/teacherdetail/${id}`)
      .then((response) => {
        setTeacherDetails(response.data.teacherDetails);
        setBySubjectsData(response.data.bySubjectsData);
        setBySkillData(response.data.skillsData);
        setByTimeSlotData(response.data.timeSlotData);
        setIsAlwaysAvailable(response.data.timeSlotData.length === 0);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false); // Stop loading after data fetch
      });
    axios
      .get(`${apiKey}/api/edolutions/teacherdetail1/${id}`)
      .then((response) => {
        setTeacherDetails1(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false); // Stop loading after data fetch
      });
  }, [id]);
  const handleInstagramShare = () => {
    navigator.clipboard.writeText(window.location.href); // Copy the URL to clipboard
    toast.success("Profile link copied!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      style: {
        backgroundColor: "#4FD1C5", // Customize background color
        color: "#fff", // Customize text color
      },
    });
  };

  const toggleClass = (className) => {
    setExpandedClasses((prev) => ({
      ...prev,
      [className]: !prev[className],
    }));
  };

  const formatTime = (time) => {
    const [hour, minute] = time.split(":");
    const suffix = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour % 12 || 12;
    return `${formattedHour}:${minute} ${suffix}`;
  };

  const handleToggleView = () => {
    if (showAll) {
      setVisibleCount(4); // Reset to initial visible count
    } else {
      setVisibleCount(byTimeSlotData.length); // Show all time slots
    }
    setShowAll((prevState) => !prevState);
  };

  // Function to share profile on social media platforms
  const handleShare = (platform) => {
    const profileUrl = window.location.href; // Get the current page URL

    let shareUrl = "";
    switch (platform) {
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          profileUrl
        )}`;
        break;
      case "whatsapp":
        shareUrl = `https://wa.me/?text=${encodeURIComponent(profileUrl)}`;
        break;
      case "linkedin":
        shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
          profileUrl
        )}`;
        break;
      case "instagram":
        shareUrl = `https://www.instagram.com/`; // Instagram doesn't have direct sharing via URL
        toast.info("Instagram sharing needs to be done manually.");
        return;
      default:
        return;
    }
    window.open(shareUrl, "_blank");
  };

  // Custom loader logic
  if (loading) {
    return (
      <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-200 bg-opacity-5 backdrop-blur-sm z-50">
        <div className="w-12 h-12 border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin border-t-[#2DD0A5]"></div>
        <p className="mt-4 text-lg font-semibold text-[#2DD0A5]">
          Edolution...
        </p>
      </div>
    );
  }

  return (
    <div className="min-h-screen mt-20">
      {/* Banner Section */}
      <div
        className="relative h-80 mb-48 bg-cover bg-center"
        style={{ backgroundImage: `url(${img})` }}
      >
        {/* Profile Picture and Info */}
        <div className="absolute top-36 max-w-6xl md:w-[80%] mx-auto left-0 right-0 transform bg-white shadow-md p-4 rounded-lg">
          <div
            className="h-40 bg-cover bg-center"
            style={{ backgroundImage: `url(${img2})` }}
          >
            <div
              className="flex bg-white w-1/12 gap-2 py-2 items-center cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <BiArrowBack className="h-5 w-5" />
              <p className="text-[14px]">Back</p>
            </div>
            <div className="h-40"></div>
          </div>
          <div className="flex flex-col items-start gap-5">
            <img
              src={
                teacherDetails1?.photo
                  ? `${apiKey}/userProfile/${teacherDetails1.photo}`
                  : `${apiKey}/userProfile/87654321.png`
              }
              alt={teacherDetails1.user_name}
              className="w-20 h-20 ml-4 -mt-16 md:w-32 md:h-32 rounded-full object-cover"
            />
            <div className="flex justify-between w-full">
              <div className="ml-4">
                <h1 className="text-xl md:text-2xl font-bold text-gray-800">
                  {teacherDetails1.user_name}
                </h1>
                <p className="flex text-yellow-400 items-center">
                  <LiaStarSolid />
                  <LiaStarSolid />
                  <LiaStarSolid />
                  <LiaStarSolid />
                  <LiaStarSolid />
                  <span className="text-black">5.0</span>
                </p>
                <div>
                  <h3>
                    Fee per hour:{" "}
                    {teacherDetails.online_charges
                      ? `${teacherDetails.online_charges} PKR`
                      : teacherDetails.home_charges
                      ? `${teacherDetails.home_charges} PKR`
                      : "Not Available"} 
                  </h3>
                </div>
                <p className="text-sm text-gray-500 mt-2 items-center  flex">
                  <span>
                    <FaMapMarkerAlt />
                  </span>
                  {teacherDetails1?.location
                    ? `${teacherDetails1.location}`
                    : "No Location Available"}
                </p>
              </div>
              <div className="ml-auto flex  flex-col md:flex-row justify-center items-center gap-2 max-md:space-x-2">
                {/* Share Profile Button */}
                <button
                  className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg"
                  onClick={() => setIsShareModalOpen(true)} // Open modal
                >
                  Share Profile
                </button>

                <Link to="/login/studentLogin" className="my-2 flex ">
                  <button className="bg-blue-600 hover:bg-blue-700 text-white  px-6 py-2 rounded-lg">
                    Free Demo
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Share Modal */}
      {isShareModalOpen && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Share this profile</h2>

            {/* Social Media Buttons Row */}
            <div className="flex justify-between space-x-3">
              {/* Facebook Share */}
              <button
                onClick={() => handleShare("facebook")}
                className="bg-blue-600 text-white flex items-center justify-center w-1/4 py-2 px-2 rounded-lg"
              >
                <FaFacebook className=" " size={24} />
              </button>

              {/* WhatsApp Share */}
              <button
                onClick={() => handleShare("whatsapp")}
                className="bg-green-500 text-white flex items-center justify-center w-1/4 py-2 px-2 rounded-lg"
              >
                <FaWhatsapp className=" " size={24} />
              </button>

              {/* LinkedIn Share */}
              <button
                onClick={() => handleShare("linkedin")}
                className="bg-blue-700 text-white flex items-center justify-center w-1/4 py-2 px-2 rounded-lg"
              >
                <FaLinkedin className=" " size={24} />
              </button>

              {/* Instagram Share (Copy to Clipboard) */}
              <button
                onClick={handleInstagramShare}
                className="bg-gray-600 text-white flex items-center justify-center w-1/4 py-2 px-2 rounded-lg"
              >
                <FaLink className=" " size={24} />
              </button>
            </div>

            {/* Close button */}
            <button
              onClick={() => setIsShareModalOpen(false)} // Close modal
              className="mt-4 text-gray-500 w-full"
            >
              Close
            </button>
          </div>
        </div>
      )}
      {/* Main Content Section */}
      <div className="max-w-6xl md:w-[83%] mx-auto px-4 py-6 grid grid-cols-1 lg:grid-cols-3 gap-6 mt-16">
        {/* Left Column (Profile Info) */}
        <div className="lg:col-span-2 space-y-6">
          <div className="bg-white shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-4">About</h2>
            <p className="text-gray-700 text-sm">{teacherDetails.bio}</p>
          </div>

          <div className="bg-white shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-4">Work Experience</h2>
            <div className="mb-4 flex flex-col gap-1">
              <h3 className="font-bold">{teacherDetails.title}</h3>
              <p className="text-sm text-gray-500">{teacherDetails.company}</p>
              <p className="text-sm text-gray-500">
                {teacherDetails.start_month} {teacherDetails.start_year} -{" "}
                {teacherDetails.end_month} {teacherDetails.end_year}
              </p>
              {teacherDetails && (
                <p className="text-sm font-light mb-3">
                  Job Description: {teacherDetails.description}
                </p>
              )}
            </div>
          </div>

          <div className="bg-white shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-4">Education</h2>
            <div>
              <p className="text-sm font-semibold">{teacherDetails.degree}</p>
              <p className="text-sm font-light">{teacherDetails.school}</p>
              <p className="text-sm font-light mb-3">
                Graduation Year: {teacherDetails.graduation_year}
              </p>
            </div>
          </div>
          <div className="border bg-white rounded-lg shadow-md p-5">
            <p className="text-xl font-semibold">Skills</p>
            <div className="flex flex-wrap gap-2 mt-3">
              {bySkillData.map((skill) => (
                <p
                  key={skill.id}
                  className=" border-2 border-[#1dcc9e] rounded-xl p-2 text-sm "
                >
                  {skill.skill_name}
                </p>
              ))}
            </div>
          </div>
        </div>

        {/* Right Column (Subjects and Skills) */}
        <div className="space-y-6">
          <div className="border rounded-lg bg-white shadow-sm p-5">
            <p className="text-2xl font-semibold mb-3">Subjects</p>
            <div className="flex items-center text-base font-semibold space-x-1 mb-2">
              <p className="text-lg">
                <FaFile />
              </p>
              <p>Main Classes & Subjects</p>
            </div>
            <div>
              {Object.keys(bySubjectsData).map((className) => (
                <div key={className}>
                  <div
                    className="cursor-pointer flex items-center"
                    onClick={() => toggleClass(className)}
                  >
                    <p className="text-base font-semibold">{className}</p>
                    <span className="ml-2">
                      {expandedClasses[className] ? (
                        <IoChevronUp />
                      ) : (
                        <IoChevronDownOutline />
                      )}
                    </span>
                  </div>
                  {expandedClasses[className] && (
                    <ul>
                      {bySubjectsData[className].map((subject) => (
                        <li className="text-sm" key={subject}>
                          {subject}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          </div>

       
          <div className="border rounded-3xl shadow-xl p-5">
            <p className="text-2xl font-semibold mb-2">Time</p>
            {byTimeSlotData && byTimeSlotData.length > 0 ? (
              <div>
                {byTimeSlotData.slice(0, visibleCount).map((timeSlot) => (
                  <div key={timeSlot.id}>
                    <p className="text-sm font-semibold capitalize">
                      {timeSlot.day_name}:
                    </p>
                    <p className="text-sm font-normal">
                      Start Time: {formatTime(timeSlot.start_time)} End Time:{" "}
                      {formatTime(timeSlot.end_time)}
                    </p>
                  </div>
                ))}
                {byTimeSlotData.length > 4 && (
                  <button
                    className="mt-3 text-blue-500 font-semibold"
                    onClick={handleToggleView}
                  >
                    {showAll ? "Show Less" : "View More"}
                  </button>
                )}
              </div>
            ) : (
              <p>No time slots available</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeacherMainProfile;
