import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { NavLink } from "react-router-dom";
import apiKey from "../../../config/apiKey";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";

const OtherDetails = () => {
  const teacher = JSON.parse(localStorage.getItem("edo-teacher"));
  const [isLoading, setIsLoading] = useState(false); // Add isLoading state
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [skills, setSkills] = useState([]);
  const [formData, setFormData] = useState({
    skill_id: [],
    currently_working: "no",
    bio: "",
    degree: "",
    school: "",
    graduation_year: "",
    title: "",
    company: "",
    start_month: "",
    start_year: "",
    end_month: "",
    end_year: "",
    description: "",
  });

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading to true when the form is submitted

    try {
      // Send POST request
      const response = await axios.post(
        `${apiKey}/api/edolutions/teacher/add-users-details`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${teacher?.token}`,
          },
        }
      );

      // Show success message
      toast.success(response.data.message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: {
          backgroundColor: "#4FD1C5", // Customize background color
          color: "#fff", // Customize text color
        },
      });
    } catch (err) {
      // Handle errors
      if (err.response && err.response.data && err.response.data.data) {
        setErrors(err.response.data.data);
      } else {
        setErrors({ general: "An unexpected error occurred" });
      }
    } finally {
      // Reset loading state
      setIsLoading(false);
    }
  };

  const [loading, setLoading] = useState(true); // Add loading state
  useEffect(() => {
    setLoading(true); // Start loading before data fetch
    axios
      .get(`${apiKey}/api/edolutions/teacher/skills`, {
        headers: {
          Authorization: `Bearer ${teacher?.token}`,
        },
      })
      .then((res) => {
        setSkills(res.data);
      })
      .catch((err) => {
        console.error(err);
      });

    axios
      .get(`${apiKey}/api/edolutions/teacher/users-details`, {
        headers: {
          Authorization: `Bearer ${teacher?.token}`,
        },
      })
      .then((res) => {
        if (res.data[0]) setFormData(res.data[0]);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [teacher?.token]);

  const options = skills.map((item) => ({
    value: item.id,
    label: item.skill_name,
  }));
  // const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChangeSub = (selectedOptions) => {
    // setSelectedOptions(selectedOptions);
    const selectedValues = selectedOptions.map((option) => option.value);
    setFormData({
      ...formData,
      skill_id: selectedValues,
    });
  };
  // console.log(selectedOptions);
  return (
    <>
      <div className="flex items-center xl:p-5 sm:p-0 justify-center">
        <div className="w-full border rounded-3xl shadow-xl p-5 px-10">
          <div className="mt-3  flex  items-center">
            <div>
              {" "}
              <p className="text-2xl font-semibold ">Settings</p>
            </div>
          </div>

          {/* tableee----------- */}
          <div className="flex flex-wrap md:justify-start justify-center  space-x-5  border-b-2 text-base font-medium my-5">
            <NavLink
              to=".././profileSettingTech"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              Profile Settings
            </NavLink>

            <NavLink
              to=".././subjectTech"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              Subjects & WP
            </NavLink>

            <NavLink
              to=".././otherDetail"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              Other Details
            </NavLink>

            <NavLink
              to=".././security"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              Privacy & Security
            </NavLink>

            {/* <NavLink
              to=".././techerNotiSetting"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              Notification
            </NavLink> */}
          </div>
          {/* end---------- */}

          {loading ? (
            <div>
              <div colSpan="9" className="py-5 px-1 text-center">
                <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-200 bg-opacity-5 backdrop-blur-sm z-50">
                  <div className="w-12 h-12 border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin border-t-[#2DD0A5]"></div>
                  <p className="mt-4 text-lg font-semibold text-[#2dd0a5]">
                    Edolution...
                  </p>
                </div>
              </div>
            </div>
          ) : skills.length === 0 ? (
            <div>
              <div colSpan="9" className="py-5 px-1 text-center">
                <p className="text-gray-500">No Home Tutoring Available</p>
              </div>
            </div>
          ) : (
            <>
              <div className="flex justify-between">
                <div className="mt-5">
                  <p className="text-[22px] font-semibold ">Other Details</p>
                </div>
                <div className="flex space-x-5 mt-5 cursor-pointer ">
                  <button
                    onClick={handleSubmit}
                    className={`text-[11px] text-[#5963F5] border border-[#5963F5] px-6 py-2 rounded-lg ${
                      isLoading ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <svg
                        className="animate-spin h-5 w-5 text-[#5963F5]"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
              <div>
                <p className="text-xl mt-4 font-semibold ">Personal</p>
                <p className="text-lg mt-3 font-semibold ">Your Bio</p>
              </div>

              <div className="md:w-1/2 w-full ">
                <form onSubmit={handleSubmit}>
                  <div className="md:flex block md:space-y-0 space-y-3 mt-5 gap-10 w-full">
                    <div className="flex-1 bg-[#F9FBFF] border border-[#F9FBFF] rounded-lg px-1">
                      <textarea
                        rows={4}
                        className="outline-none px-1 py-2 focus-none  w-full bg-[#F9FBFF] "
                        placeholder="Add a short bio to showcase your best self."
                        type="text"
                        name="bio"
                        id="bio"
                        value={formData.bio}
                        onChange={handleChange}
                      />
                      {errors.bio && (
                        <div className="text-red-500 text-start text-sm">
                          {`*Insert Bio!`}
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
              <div className="md:w-1/2 w-full">
                <div className="mt-5">
                  <p className="text-lg font-semibold ">Select your Skills</p>
                </div>
                <form action="">
                  <div className="md:flex block md:space-y-0 space-y-3 mt-5 gap-10 w-full">
                    <div className="flex-1 bg-[#F9FBFF] border border-[#F9FBFF] rounded-lg px-1">
                      <Select
                        className="text-[#B5B7C0] text-sm dropdown-select w-full mt-2 rounded-lg outline-none bg-[#F9FBFF] "
                        options={options}
                        isMulti={true}
                        defaultValue={options.filter((option) =>
                          formData.skill_id.includes(option.value)
                        )}
                        onChange={handleChangeSub}
                        closeMenuOnSelect={false}
                        styles={{
                          option: (provided) => ({
                            ...provided,
                            color: "#000",
                          }),
                          multiValue: (provided) => ({
                            ...provided,
                            backgroundColor: "#1E90FF",
                            borderRadius: "0.25rem",
                          }),
                          multiValueLabel: (provided) => ({
                            ...provided,
                            color: "#fff",
                          }),
                          multiValueRemove: (provided) => ({
                            ...provided,
                            color: "#fff", // Remove icon color
                            ":hover": {
                              backgroundColor: "#1E90FF", // Hover color for remove icon
                              color: "#fff", // Hover color for remove icon
                            },
                          }),
                        }}
                      />
                      {errors.skill_id && (
                        <div className="text-red-500 text-start text-sm">
                          {`*Select Skill!`}
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
              <div className="md:w-1/2 w-full">
                <div className="mt-5">
                  <p className="text-xl font-semibold ">Education</p>
                  <p className="text-lg font-semibold mt-5 ">Add a degree</p>
                  <p className="text-lg font-semibold mt-3 ">Degree</p>
                </div>
                <form action="">
                  <div className="md:flex block md:space-y-0 space-y-3 mt-5  w-1/2">
                    <div className="flex-1 bg-[#F9FBFF] border border-[#F9FBFF] rounded-lg px-1">
                      <input
                        className="outline-none px-1 py-2 focus-none  bg-[#F9FBFF] "
                        placeholder="Enter Your Degree"
                        type="text"
                        name="degree"
                        id="degree"
                        value={formData.degree || ""}
                        onChange={handleChange}
                      />
                      {errors.degree && (
                        <div className="text-red-500 text-start text-sm">
                          {`*Select Your Degree!`}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mt-5">
                    <p className="text-lg font-semibold ">School</p>
                  </div>
                  <div className="md:flex block md:space-y-0 space-y-3 mt-5  w-1/2">
                    <div className="flex-1 bg-[#F9FBFF] border border-[#F9FBFF] rounded-lg px-1">
                      <input
                        className="outline-none px-1 py-2 focus-none  bg-[#F9FBFF] "
                        placeholder="Enter Your School"
                        type="text"
                        name="school"
                        id="school"
                        value={formData.school || ""}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="mt-5">
                    <p className="text-lg font-semibold ">Graduation Year</p>
                  </div>
                  <div className="md:flex block md:space-y-0 space-y-3 mt-5  w-1/2">
                    <div className="flex-1 bg-[#F9FBFF] border border-[#F9FBFF] rounded-lg px-1">
                      <input
                        className="outline-none px-1 py-2 focus-none  bg-[#F9FBFF] "
                        placeholder="Enter Your Year"
                        type="text"
                        name="graduation_year"
                        id="graduation_year"
                        value={formData.graduation_year || ""}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="mt-5">
                <p className="text-xl font-semibold  mt-5">Work History</p>
                <p className="text-lg font-semibold mt-3">Add a job </p>
              </div>

              <div className="md:w-1/2 w-full ">
                <form action="">
                  <div className="md:flex block md:space-y-0 space-y-3 mt-5 gap-10 w-full">
                    <div className="flex-1 ">
                      <label htmlFor="" className="font-semibold">
                        Title
                      </label>
                      <input
                        className="outline-none mt-2 px-1 py-2 focus-none border border-[#F9FBFF] rounded-lg w-full bg-[#F9FBFF]"
                        placeholder="Enter your position"
                        type="text"
                        name="title"
                        id="title"
                        value={formData.title || ""}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="flex-1  ">
                      <label htmlFor="" className="font-semibold">
                        Company
                      </label>
                      <input
                        className="outline-none px-1 py-2 mt-2 focus-none border border-[#F9FBFF] rounded-lg w-full bg-[#F9FBFF] "
                        placeholder="Company name"
                        type="text"
                        name="company"
                        id="company"
                        value={formData.company || ""}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </form>

                <div className="flex mt-5">
                  <input
                    defaultChecked={formData.currently_working === "yes"}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        currently_working: e.target.checked ? "yes" : "no",
                      })
                    }
                    type="checkbox"
                    className="mr-3 w-5"
                  />

                  <p className="text-lg font-semibold">I currently work here</p>
                </div>
              </div>

              <div className="md:w-1/2 w-full ">
                <form action="">
                  <p className="text-lg font-semibold mt-5">Start Date</p>
                  <div className="md:flex block md:space-y-0 space-y-3 mt-5 gap-10 w-full">
                    <div className="flex-1 bg-[#F9FBFF] border border-[#F9FBFF] rounded-lg px-1">
                      <select
                        className="outline-none px-1 py-2 focus-none w-full bg-[#F9FBFF]"
                        placeholder="Month"
                        type="text"
                        name="start_month"
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Month
                        </option>
                        {monthNames.map((name, index) => (
                          <option key={index} value={name} selected={formData.start_month === name}>
                            {name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="flex-1 bg-[#F9FBFF] border border-[#F9FBFF] rounded-lg px-1">
                      <input
                        className="outline-none px-1 py-2 focus-none  w-full bg-[#F9FBFF] "
                        placeholder="year"
                        type="text"
                        name="start_year"
                        id="start_year"
                        value={formData.start_year || ""}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <p className="text-lg font-semibold mt-5">End Date</p>
                  <div className="md:flex block md:space-y-0 space-y-3 mt-5 gap-10 w-full">
                    <div className="flex-1 bg-[#F9FBFF] border border-[#F9FBFF] rounded-lg px-1">
                      <select
                        className="outline-none px-1 py-2 focus-none  w-full bg-[#F9FBFF] "
                        placeholder="Month"
                        type="text"
                        name="end_month"
                        onChange={handleChange}
                      >
                        <option selected>Month</option>
                        {monthNames.map((name, index) => (
                          <option key={index} value={name} selected={formData.end_month === name}>
                            {name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="flex-1 bg-[#F9FBFF] border border-[#F9FBFF] rounded-lg px-1">
                      <input
                        className="outline-none px-1 py-2 focus-none  w-full bg-[#F9FBFF] "
                        placeholder="Year"
                        type="text"
                        name="end_year"
                        id="end_year"
                        value={formData.end_year || ""}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <p className="text-lg font-semibold mt-5">Decription</p>
                  <div className="md:flex block md:space-y-0 space-y-3 mt-5 gap-10 w-full">
                    <div className="flex-1 bg-[#F9FBFF] border border-[#F9FBFF] rounded-lg px-1">
                      <textarea
                        rows={6}
                        className="outline-none px-1 py-2 focus-none  w-full bg-[#F9FBFF] "
                        placeholder="Job decription"
                        type="text"
                        name="description"
                        id="description"
                        value={formData.description || ""}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>

      {/* modal */}
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative mx-auto md:w-1/2">
              {/* {/content/} */}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none overflow-y-auto shiftmodel p-5 ">
                {/* {/header/} */}
                <div>
                  <button
                    className=" ml-auto float-right text-2xl"
                    onClick={() => setShowModal(false)}
                  >
                    <span> × </span>
                  </button>
                </div>

                <div className="flex justify-center">
                  <h1 className="text-2xl font-medium">Add Degree</h1>
                </div>

                <div className="lg:w-2/3 w-full m-auto">
                  <div>
                    <p className="text-lg mt-4 font-medium">Add a Job</p>
                  </div>

                  <div className="md:flex block md:space-y-0 space-y-3 mt-5 gap-4 w-full">
                    <div className="flex-1">
                      <label htmlFor="">
                        Title
                        <input
                          className="outline-none px-1 mt-2 py-1 focus-none  w-full bg-[#F9FBFF]  rounded-lg "
                          placeholder="Enter your position"
                          type="text"
                          name=""
                          id="ttle"
                        />
                      </label>
                    </div>
                    <div className="flex-1">
                      <label htmlFor="">
                        Company
                        <input
                          className="outline-none px-1 mt-2 py-1 focus-none  w-full bg-[#F9FBFF]  rounded-lg  "
                          placeholder="Company Name"
                          type="text"
                          name=""
                          id="cmp"
                        />
                      </label>
                    </div>
                  </div>

                  <div>
                    <p className="text-md mt-5">Start Date</p>
                  </div>

                  <div className="md:flex block md:space-y-0 space-y-3  gap-4 w-full">
                    <div className="flex-1">
                      <select className="text-[#B5B7C0] text-sm dropdown-select w-full mt-2 rounded-lg outline-none bg-[#F9FBFF] px-1 py-1.5">
                        <option value="">Month</option>
                        <option value="english">English</option>
                        <option value="physics">Physics</option>
                        <option value="chemistry">Chemistry</option>
                      </select>
                    </div>
                    <div className="flex-1">
                      <input
                        className="outline-none px-1 mt-2 py-1  focus-none  w-full bg-[#F9FBFF]  rounded-lg  "
                        placeholder="Year"
                        type="text"
                        name=""
                        id="yr"
                      />
                    </div>
                  </div>

                  <div>
                    <p className="text-md mt-5">End Date</p>
                  </div>

                  <div className="md:flex block md:space-y-0 space-y-3  gap-4 w-full">
                    <div className="flex-1">
                      <select className="text-[#B5B7C0] text-sm dropdown-select w-full mt-2 rounded-lg outline-none bg-[#F9FBFF] px-1 py-1.5">
                        <option value="">Month</option>
                        <option value="english">English</option>
                        <option value="physics">Physics</option>
                        <option value="chemistry">Chemistry</option>
                      </select>
                    </div>
                    <div className="flex-1">
                      <input
                        className="outline-none px-1 mt-2 py-1  focus-none  w-full bg-[#F9FBFF] rounded-lg  "
                        placeholder="Year"
                        type="text"
                        name=""
                        id="yrw"
                      />
                    </div>
                  </div>

                  <div>
                    <p className="text-md mt-5">Description</p>
                  </div>

                  <div className="md:flex block md:space-y-0 space-y-3  gap-4 w-full">
                    <textarea
                      name=""
                      rows={4}
                      className="outline-none px-1 mt-2 py-1  focus-none  w-full
                     bg-[#F9FBFF] rounded-lg"
                    ></textarea>
                  </div>

                  <div className="flex justify-center mt-6">
                    <p className="bg-blue-500 w-fit hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg ml-2 focus:outline-none focus:shadow-outline">
                      Save
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {/* modal-end */}
    </>
  );
};

export default OtherDetails;
