import React from "react";
import Image from '../assets/Table.png';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const ContactForm = () => {
  return (
    <div className="my-10">
      <div className="flex items-center justify-center">
        <div className="flex flex-wrap w-full items-center justify-center">


          <div className="w-full lg:w-2/5 md:w-2/5 sm:w-full px-4">
            <img data-aos="fade-right" src={Image} alt="Table" className="max-w-full" />
          </div>


          <div className="w-full lg:w-2/5 md:w-2/5 sm:w-full px-4">
            <p className="text-[36px] font-bold leading-10 text-[#101828]" data-aos="fade-left">
              Get in touch
            </p>
            <p className="text-[20px] leading-10 mt-2 text-[#475467]" data-aos="fade-left">
              Our friendly team would love to hear from you.
            </p>
            <div className="mt-4">
              <form className="space-y-4">
                <div className="flex gap-4">
                  <div className="w-full">
                    <label htmlFor="firstname" className="block mb-1">First name</label>
                    <input
                      type="text"
                      id="firstName"
                      placeholder="First name"
                      required
                      className="border border-[#D0D5DD] px-4 py-3 rounded-lg w-full"
                    />
                  </div>

                  <div className="w-full">
                    <label htmlFor="lastName" className="block mb-1">Last name</label>
                    <input
                      type="text"
                      id="lastName"
                      placeholder="Last name"
                      required
                      className="border border-[#D0D5DD] py-3 px-4 rounded-lg w-full"
                    />
                  </div>
                </div>

                <div className="w-full">
                  <label htmlFor="email" className="block mb-1">Email</label>
                  <input
                    type="email"
                    id="email"
                    placeholder="you@gmail.com"
                    required
                    className="border border-[#D0D5DD] py-3 px-4 rounded-lg w-full"
                  />
                </div>

                <div className="w-full">
                  <label htmlFor="phone" className="block mb-1">Phone number</label>
                  <div className="w-full px-4 py-2 bg-[#fff] border-[#D0D5DD] border rounded-md text-start">
                    <PhoneInput
                      className="text-start"
                      containerStyle={{
                        width: "100%",
                        backgroundColor: "transparent",
                        borderBottom: "1px solid #F5F5F5",
                      }}
                      inputStyle={{
                        backgroundColor: "transparent",
                        width: "100%",
                        border: "none",
                        outline: "none",
                        fontSize: "16px",
                        color: "#000",
                      }}
                      buttonStyle={{
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      country={"pk"}
                      placeholder={"Phone"}
                      inputProps={{ required: true }}
                      type={"tel"}
                      name="phone"
                    />
                  </div>
                </div>

                <div className="w-full">
                  <label htmlFor="message" className="block mb-1">Message</label>
                  <textarea
                    id="message"
                    placeholder="Your Message"
                    required
                    className="border border-[#D0D5DD] p-2 rounded-lg w-full"
                    rows="4"
                  />
                </div>

                <div className="flex gap-3 items-center mb-10">
                  <input
                    type="checkbox"
                    id="agree"
                    className="form-checkbox h-4 w-4 text-[#09A48C] rounded"/>

                  <label htmlFor="agree" className="text-sm text-[#475467]">
                  You agree to our friendly <span className="border-b-2 border-[#475467] ">privacy policy.</span> 
                  </label>
                </div>

                <div className="w-full text-center ">
                  <button
                    type="submit"
                    className="bg-[#09A48C] w-full font-semibold text-white py-3 px-4 mt-6 mb-[3rem] rounded-lg border border-[#7F56D9]"
                  >
                    Send message
                  </button>
                </div>
              </form>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default ContactForm;
