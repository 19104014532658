import React, { useState } from "react";
import { TbBallpen } from "react-icons/tb";
import "react-phone-input-2/lib/style.css";
import { NavLink } from "react-router-dom";

const SaveDetail = () => {
  const [showModal, setShowModal] = useState(false)
  const [educationModal, setEducationModal] = useState(false)
  const [workModal, setWorkModal] = useState(false)


  return (
    <>
      <div className="flex items-center xl:p-5 sm:p-0 justify-center">
        <div className="w-full border rounded-3xl shadow-xl p-5 px-10">
          <div className="mt-3  flex  items-center">
            <div>
              {" "}
              <p className="text-2xl font-semibold ">Settings</p>
            </div>
          </div>


          {/* tableee----------- */}
          <div className="flex flex-wrap md:justify-start justify-center  space-x-5  border-b-2 text-base font-medium my-5">



            <NavLink to=".././profileSettingTech" className={({ isActive }) => (isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined)}>Profile Settings</NavLink>

            <NavLink to=".././subjectTech" className={({ isActive }) => (isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined)}>Subjects & WP</NavLink>

            <NavLink to=".././otherDetail" className='border-blue-500 border-b-2'>Other Details</NavLink>

            <NavLink to=".././security" className={({ isActive }) => (isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined)}>Privacy & Security</NavLink>

            {/* <NavLink to=".././techerNotiSetting" className={({ isActive }) => (isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined)}>Notification</NavLink> */}
          </div>
          {/* end---------- */}


          <div className="mt-5">
            <p className="text-[22px] font-semibold ">Other Details</p>
          </div>



          <div className="mt-5 md:w-1/2 w-full  flex justify-between items-center">
            <div className=" flex border border-[#B5B7C0] rounded-2xl p-5 w-full justify-between">
              <div className="">
                <p className="text-[14px] font-semibold">Personal</p>
                <p className="text-[12px] text-[#B5B7C0] font-semibold">Your Bio, Your Skills</p>
              </div>
              <div className="flex text-blue-500 gap-x-3 text-[20px] items-center cursor-pointer">
                <div onClick={() => setShowModal(true)}>
                  <TbBallpen />
                </div>

              </div>
            </div>
          </div>

          <div>
            <div className="mt-5 md:w-1/2 w-full  flex justify-between items-center">
              <div className=" flex border border-[#B5B7C0] rounded-2xl p-5 w-full justify-between">
                <div className="">
                  <p className="text-[14px] font-semibold">Education</p>
                  <p className="text-[12px] text-[#B5B7C0] font-semibold">Add Degree</p>
                </div>
                <div className="flex text-blue-500 gap-x-3 text-[20px] items-center cursor-pointer">
                  <div onClick={() => setEducationModal(true)}>
                    <TbBallpen />
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="mt-5 md:w-1/2 w-full  flex justify-between items-center">
              <div className=" flex border border-[#B5B7C0] rounded-2xl p-5 w-full justify-between">
                <div className="">
                  <p className="text-[14px] font-semibold">Work History</p>
                  <p className="text-[12px] text-[#B5B7C0] font-semibold">Add Job</p>
                </div>
                <div className="flex text-blue-500 gap-x-3 text-[20px] items-center cursor-pointer">
                  <div onClick={() => setWorkModal(true)}>
                    <TbBallpen />
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      {/* modal */}
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative mx-auto md:w-1/2">
              {/* {/content/} */}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none overflow-y-auto shiftmodel p-5 ">
                {/* {/header/} */}
                <div className="flex items-start justify-center  border-solid border-blueGray-200 rounded-t">
                  <button
                    className=" ml-auto  float-right text-2xl"
                    onClick={() => setShowModal(false)}
                  >
                    <span> × </span>
                  </button>
                </div>

                <div className="flex justify-center">
                  <h1 className="text-2xl font-medium">Personal Detail</h1>
                </div>

                <div className="lg:w-2/3 w-full m-auto">


                  <div>
                    <p className="text-lg mt-2 font-medium">Your Bio</p>
                    <div>
                      <textarea
                        className=" text-sm dropdown-select w-full mt-2 rounded-lg outline-none bg-[#F9FBFF] px-5 py-2"
                        placeholder="Add a Short bio to showcase your best self."
                        rows={4}
                        id="bio" />
                    </div>
                  </div>

                  <div>
                    <p className="text-lg mt-2 font-medium">Select Your Skills</p>
                    <div>
                      <select className="text-[#B5B7C0] text-sm dropdown-select w-full mt-2 rounded-lg outline-none bg-[#F9FBFF] px-5 py-2">
                        <option value="">Select Skills</option>
                        <option>Painting</option>
                        <option>Photography</option>
                        <option>Calligraphy</option>
                      </select>
                    </div>
                  </div>



                  <div className="flex justify-center my-5 mt-8 cursor-pointer">
                    <p className="bg-blue-500 w-fit hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg ml-2 focus:outline-none focus:shadow-outline">Save</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {/* modal-end */}

      {/* Educatio-modal */}
      {educationModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative mx-auto md:w-2/5">
              {/* {/content/} */}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none overflow-y-auto shiftmodel p-5 ">
                {/* {/header/} */}
                <div className="flex items-start justify-center  border-solid border-blueGray-200 rounded-t">
                  <button
                    className=" ml-auto  float-right text-2xl"
                    onClick={() => setEducationModal(false)}
                  >
                    <span> × </span>
                  </button>
                </div>

                <div className="flex justify-center">
                  <h1 className="text-2xl font-medium">Eduaction Detail</h1>
                </div>

                <div className="lg:w-2/3 w-full m-auto">


                  <form action="">
                    <div className="mt-5">
                      <p className="text-lg font-semibold ">Degree</p>
                    </div>
                    <div className="md:flex block md:space-y-0 space-y-3 mt-5  w-full">
                      <div className="flex-1 bg-[#F9FBFF] border border-[#F9FBFF] rounded-lg px-1">
                        <input
                          className="outline-none px-1 py-2 focus-none  bg-[#F9FBFF] "
                          placeholder="Enter Your Degree"
                          type="text"
                          name=""
                          id="deg"
                        />
                      </div>

                    </div>
                    <div className="mt-5">
                      <p className="text-lg font-semibold ">School</p>
                    </div>
                    <div className="md:flex block md:space-y-0 space-y-3 mt-5  w-full">
                      <div className="flex-1 bg-[#F9FBFF] border border-[#F9FBFF] rounded-lg px-1">
                        <input
                          className="outline-none px-1 py-2 focus-none  bg-[#F9FBFF] "
                          placeholder="Enter Your School"
                          type="text"
                          name=""
                          id="school"
                        />
                      </div>

                    </div>
                    <div className="mt-5">
                      <p className="text-lg font-semibold ">Graduation Year</p>
                    </div>
                    <div className="md:flex block md:space-y-0 space-y-3 mt-5  w-full">
                      <div className="flex-1 bg-[#F9FBFF] border border-[#F9FBFF] rounded-lg px-1">
                        <input
                          className="outline-none px-1 py-2 focus-none  bg-[#F9FBFF] "
                          placeholder="Enter Your Year"
                          type="text"
                          name=""
                          id="yr"
                        />
                      </div>

                    </div>


                  </form>


                  <div className="flex justify-center my-5 mt-8 cursor-pointer">
                    <p className="bg-blue-500 w-fit hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg ml-2 focus:outline-none focus:shadow-outline">Save</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {/* modal-end */}


      {/* Work-modal */}
      {workModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative mx-auto md:w-1/2">
              {/* {/content/} */}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none overflow-y-auto shiftmodel p-5 ">
                {/* {/header/} */}
                <div className="flex items-start justify-center  border-solid border-blueGray-200 rounded-t">
                  <button
                    className=" ml-auto  float-right text-2xl"
                    onClick={() => setWorkModal(false)}
                  >
                    <span> × </span>
                  </button>
                </div>

                <div className="flex justify-center mb-3">
                  <h1 className="text-2xl font-medium">Work Detail</h1>
                </div>

                <div className="lg:w-2/3 w-full m-auto">

                  <div className=" w-full ">
                    <form action="">
                      <h1 className="text-lg font-semibold">Add a Job</h1>
                      <div className="md:flex block md:space-y-0 space-y-2 mt-3 gap-10 w-full">
                        <div className="flex-1 ">
                          <label htmlFor="" className="font-semibold">Title</label>
                          <input
                            className="outline-none mt-2 px-1 py-2 focus-none border border-[#F9FBFF] rounded-lg w-full bg-[#F9FBFF]"
                            placeholder="Enter your position"
                            type="text"
                            name=""
                            id="ps"
                          />
                        </div>

                        <div className="flex-1  ">
                          <label htmlFor="" className="font-semibold">Company</label>
                          <input
                            className="outline-none px-1 py-2 mt-2 focus-none border border-[#F9FBFF] rounded-lg w-full bg-[#F9FBFF] "
                            placeholder="Company name"
                            type="text"
                            name=""
                            id="cpy"
                          />
                        </div>
                      </div>
                    </form>

                    <div className="flex mt-2">
                      <input type="checkbox" className="mr-3 w-3" />
                      <p className="text-md font-semibold">I currently work here</p>
                    </div>
                  </div>

                  <div className="w-full ">
                    <form action="">
                      <p className="text-md font-semibold mt-3">Start Date</p>
                      <div className="md:flex block md:space-y-0 space-y-3 mt-2 gap-10 w-full">
                        <div className="flex-1 bg-[#F9FBFF] border border-[#F9FBFF] rounded-lg px-1">
                          <select
                            className="outline-none px-1 py-2 focus-none  w-full bg-[#F9FBFF] "
                            placeholder="Month"
                            type="text">
                            <option>Month</option>
                            <option>January</option>
                            <option>February</option>
                            <option>March</option>
                            <option>April</option>
                            <option>May</option>
                            <option>June</option>
                            <option>July</option>
                            <option>August</option>
                            <option>September</option>
                            <option>October</option>
                            <option>November</option>
                            <option>December</option>
                          </select>
                        </div>
                        <div className="flex-1 bg-[#F9FBFF] border border-[#F9FBFF] rounded-lg px-1">
                          <input
                            className="outline-none px-1 py-2 focus-none  w-full bg-[#F9FBFF] "
                            placeholder="year"
                            type="text"
                            name=""
                            id="yrq"
                          />
                        </div>
                      </div>

                      <p className="text-md font-semibold mt-3">End Date</p>
                      <div className="md:flex block md:space-y-0 space-y-3 mt-2 gap-10 w-full">
                        <div className="flex-1 bg-[#F9FBFF] border border-[#F9FBFF] rounded-lg px-1">
                          <select
                            className="outline-none px-1 py-2 focus-none  w-full bg-[#F9FBFF] "
                            placeholder="Month"
                            type="text">
                            <option>Month</option>
                            <option>January</option>
                            <option>February</option>
                            <option>March</option>
                            <option>April</option>
                            <option>May</option>
                            <option>June</option>
                            <option>July</option>
                            <option>August</option>
                            <option>September</option>
                            <option>October</option>
                            <option>November</option>
                            <option>December</option>
                          </select>
                        </div>
                        <div className="flex-1 bg-[#F9FBFF] border border-[#F9FBFF] rounded-lg px-1">
                          <input
                            className="outline-none px-1 py-2 focus-none  w-full bg-[#F9FBFF] "
                            placeholder="Year"
                            type="text"
                            name=""
                            id="yrw"
                          />
                        </div>


                      </div>

                      <p className="text-md font-semibold mt-3">Decription</p>
                      <div className='md:flex block md:space-y-0 space-y-3 mt-2 gap-10 w-full'>
                        <div className="flex-1 bg-[#F9FBFF] border border-[#F9FBFF] rounded-lg px-1">
                          <textarea rows={3}
                            className="outline-none px-1 py-2 focus-none  w-full bg-[#F9FBFF] "
                            placeholder="Job decription"
                            type="text"
                            name=""
                            id="jdesc"
                          />
                        </div>
                      </div>

                    </form>
                  </div>


                  <div className="flex justify-center my-3 mt-5 cursor-pointer">
                    <p className="bg-blue-500 w-fit hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg ml-2 focus:outline-none focus:shadow-outline">Save</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {/* modal-end */}

    </>
  );
};

export default SaveDetail;
