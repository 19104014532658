import React, { useState, useEffect, useMemo } from "react";
import UserDropdown from "../../components/AdminDropDown/UserDropdown";
import { MdNotifications, MdMenu } from "react-icons/md";
import Logo2 from "../../assets/Edolutions 1.png";
import { Link } from "react-router-dom";
import axios from "axios";
import apiKey, { apiKeyHttp } from "../../config/apiKey";
import Echo from "laravel-echo";
import Pusher from "pusher-js";

const Navbar = ({ toggleSidebar }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const admin = JSON.parse(localStorage.getItem("edo-admin"));
  const userId = admin ? admin.admin.id : "";

  // Memoize the user object to avoid recalculating on every render
  const user = useMemo(
    () => ({
      name: admin ? admin.admin.name : "",
      admin: "Admin",
      id: userId,
    }),
    [admin]
  );

  // Fetch Notifications
  const fetchNotifications = async () => {
    try {
      const [unreadCountRes, notificationsRes] = await Promise.all([
        axios.get(`${apiKey}/api/edolutions/admin/notifications/unread/count`, {
          headers: { Authorization: `Bearer ${admin?.token}` },
        }),
        axios.get(`${apiKey}/api/edolutions/admin/notifications`, {
          headers: { Authorization: `Bearer ${admin?.token}` },
        }),
      ]);
      setNotificationCount(unreadCountRes.data.unreadCount);
      setNotifications(notificationsRes.data);
    } catch (err) {
      console.error("Error fetching notifications:", err);
    }
  };

  // Real-time notification count with Laravel Echo (Reverb)
  useEffect(() => {
    if (admin?.token && userId) {
      window.Pusher = Pusher;
    
      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: 'b7245c5f531fd8d69ec7', // Pusher app key
        cluster: 'ap2', // Pusher app cluster (e.g., 'eu')
        forceTLS: true, // Pusher uses TLS by default
        encrypted: true, // Ensures connection encryption
        authEndpoint: `${apiKey}/broadcasting/auth`, // Same endpoint for Pusher
        auth: {
          headers: {
            Authorization: `Bearer ${admin?.token}`,
          },
        },
      });

      const channel = window.Echo.private(
        `notification-count-channel.admin.${userId}`
      );
      channel.listen("NotificationCountAdminEvent", e => setNotificationCount(e.data));

      return () => {
        if (window.Echo) {
          channel.stopListening("NotificationCountAdminEvent");
        }
      };
    }
  }, [admin?.token, userId]);

  // Fetch notifications on component mount
  useEffect(() => {
    if (userId) fetchNotifications();
  }, [userId]);

  // Mark all notifications as read
  const markAllCount = async () => {
    try {
      await axios.put(
        `${apiKey}/api/edolutions/admin/notifications/admincount`,
        {},
        {
          headers: { Authorization: `Bearer ${admin?.token}` },
        }
      );

      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) => ({
          ...notification,
          admincount: true,
        }))
      );
      setNotificationCount(0);
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    }
  };

  // Handle screen resize
  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 1030);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="bg-[#FFFFFF] px-6 shadow-md py-6 flex items-center justify-between relative z-10">
      <div className="flex items-center justify-between text-xl">
        <div>
          {isSmallScreen && (
            <MdMenu
              className="text-[#232738] w-8 h-8 me-4 cursor-pointer"
              onClick={toggleSidebar}
            />
          )}
        </div>
        <Link to="./home">
          <div className="w-48 hidden md:block">
            <img src={Logo2} alt="Logo" />
          </div>
        </Link>
      </div>

      <div className="flex items-center gap-2">
        <div className="relative" onClick={markAllCount}>
          <Link to="./allNotification">
            <button className="text-[#040405] flex items-center space-x-1 group border p-2 rounded-xl">
              <MdNotifications className="w-6 h-6" />
              {notificationCount > 0 && (
                <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs px-1 rounded-full">
                  {notificationCount}
                </span>
              )}
            </button>
          </Link>
        </div>

        <UserDropdown user={user} />
      </div>
    </div>
  );
};

export default Navbar;
