import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import TeacherCard from "./TeacherCard";
import TeacherDas1 from "../../../assets/TeacherDas1.png";
import TeacherDas2 from "../../../assets/TeacherDas2.png";
import TeacherDas3 from "../../../assets/TeacherDas3.png";
import { getHitID } from "../../../config/HitIDStorage";
import axios from "axios";
import apiKey from "../../../config/apiKey";

const TeacherDash = () => {
  const ID = getHitID();
  const admin = JSON.parse(localStorage.getItem("edo-admin"));
  const [loading, setLoading] = useState(true); // Add loading state
  const [data, setData] = useState({ all: 0, home: 0, online: 0 });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading before data fetch
      try {
        const [allStudentsRes, homeStudentsRes, onlineStudentsRes] =
          await Promise.all([
            axios.get(
              `${apiKey}/api/edolutions/admin/read-notifications-all/${ID}`,
              {
                headers: {
                  Authorization: `Bearer ${admin?.token}`,
                },
              }
            ),
            axios.get(
              `${apiKey}/api/edolutions/admin/read-notifications-home/${ID}`,
              {
                headers: {
                  Authorization: `Bearer ${admin?.token}`,
                },
              }
            ),
            axios.get(
              `${apiKey}/api/edolutions/admin/read-notifications/${ID}`,
              {
                headers: {
                  Authorization: `Bearer ${admin?.token}`,
                },
              }
            ),
          ]);

        setData({
          all: allStudentsRes.data.data.length,
          home: homeStudentsRes.data.data.length,
          online: onlineStudentsRes.data.data.length,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Stop loading after data fetch
      }
    };

    if (ID) {
      fetchData();
    }
  }, [ID, admin?.token]);

  return (
    <>
      <div>
        <div className="flex items-center xl:p-5 sm:p-0 justify-center">
          <div className="w-full border rounded-3xl shadow-xl p-5">
            <TeacherCard />

            <div className="flex flex-wrap md:justify-start justify-center space-x-5 border-b-2 text-base font-medium ">
              <NavLink
                to=""
                className={({ isActive }) =>
                  isActive
                    ? "text-blue-500 border-b-2 border-blue-500"
                    : undefined
                }
              >
                Dashboard
              </NavLink>

              <NavLink
                to=".././teacherMessage"
                className={({ isActive }) =>
                  isActive
                    ? "text-blue-500 border-b-2 border-blue-500"
                    : undefined
                }
              >
                View Profile
              </NavLink>

              <NavLink
                to=".././onlineTutoring"
                className={({ isActive }) =>
                  isActive
                    ? "text-blue-500 border-b-2 border-blue-500"
                    : undefined
                }
              >
                Online Tutoring
              </NavLink>

              <NavLink
                to=".././homeTutoring"
                className={({ isActive }) =>
                  isActive
                    ? "text-blue-500 border-b-2 border-blue-500"
                    : undefined
                }
              >
                Home Tutoring
              </NavLink>

              <NavLink
                to=".././teacherChat"
                className={({ isActive }) =>
                  isActive
                    ? "text-blue-500 border-b-2 border-blue-500"
                    : undefined
                }
              >
                Messages
              </NavLink>
            </div>

            <div>
              <div className="h-screen">
                <div className="mt-5 px-5">
                  <p className="text-2xl font-semibold">Dashboard</p>
                </div>

                {loading ? (
                  <div>
                    <div colSpan="9" className="py-5 px-1 text-center">
                      <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-200 bg-opacity-5 backdrop-blur-sm z-50">
                        <div className="w-12 h-12 border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin border-t-[#2DD0A5]"></div>
                        <p className="mt-4 text-lg font-semibold text-[#2dd0a5]">
                          Edolution...
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-wrap items-center justify-center mt-10 gap-5">
                    <div className="flex items-center space-x-3 px-10 py-8 ">
                      <div>
                        <img src={TeacherDas3} alt="" />
                      </div>
                      <div>
                        <p className="text-sm text-[#ACACAC] font-normal">
                          Home Tuitions
                        </p>
                        <p className="text-3xl font-semibold">{data.home}</p>
                      </div>
                    </div>
                    <div className="flex items-center space-x-3 lg:border-l-2 border-0  px-10 py-8">
                      <div>
                        <img src={TeacherDas1} alt="" />
                      </div>
                      <div>
                        <p className="text-sm text-[#ACACAC] font-normal">
                          Online Classes
                        </p>
                        <p className="text-3xl font-semibold">{data.online}</p>
                      </div>
                    </div>
                    <div className="flex items-center space-x-3 lg:border-l-2 border-0 px-10 py-8">
                      <div>
                        <img src={TeacherDas2} alt="" />
                      </div>
                      <div>
                        <p className="text-sm text-[#ACACAC] font-normal">
                          Total Students
                        </p>
                        <p className="text-3xl font-semibold">{data.all}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeacherDash;
