import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { FaArrowLeft, FaRegUserCircle } from "react-icons/fa";

const TeacherNotification = () => {


  const navigate = useNavigate();

  return (
    <div>
      <div>
        <div className="flex items-center xl:p-5 sm:p-0 justify-center">
          <div className="w-full border rounded-3xl shadow-xl p-5">
            <div>
              <div>

                <FaArrowLeft className='mb-5 cursor-pointer ' onClick={() => navigate(-1)} />
              </div>
              <p className='text-2xl font-bold'>Notification</p>
              <p className='text-[#22B7AA] font-light text-sm'>Teacher Name Notification</p>
            </div>
            <div className=" flex border-b-2 justify-between items-center mt-8">
              <div className='flex flex-wrap md:justify-start md:space-x-12  space-x-5  text-base font-medium '>
                <NavLink to=".././teacherNotification" className={({ isActive }) => (isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined)}>All</NavLink>

                <NavLink to=".././teacherNewNoti" className={({ isActive }) => (isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined)}>New</NavLink>

                <NavLink to=".././teacherUnreadNoti" className={({ isActive }) => (isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined)}>Unread</NavLink>
              </div>
              <div>
                <p className='text-[12px] bg-[#FAFAF8] text-[#7E7E7E] font-semibold border-[#7E7E7E] p-1 rounded-lg cursor-pointer mb-2'>Mark All As Read</p>
              </div>
            </div>

            <div className='grid grid-cols-4 gap-4 my-4 bg-[#A3E7E11C] p-4'>

              <div className='flex gap-2'>
                <div>
                  <FaRegUserCircle className='h-8 w-8' />
                </div>
                <div>
                  <p className='text-[#2D3748] text-[14px] font-semibold'>Ali </p>
                  <span className='text-[12px] text-[#2D3748] '>Online CLass</span>
                </div>
              </div>
              <div className='flex gap-5 text-[12px] text-[#2D3748] items-center'>
                <p>Thursday</p>
                <p>4:30 PM</p>

              </div>
              <div className='text-end'>
                <button className='border rounded-sm text-[12px] text-[#828282] px-5 py-1 font-semibold  bg-[#4FD1C50D]'>New</button>
              </div>

              <div className='flex gap-4 justify-end'>

                <div>
                  <button className='border rounded-sm text-[12px] text-[#828282] px-5 py-1 font-semibold  bg-[#4FD1C50D]'>Accept</button>
                </div>

                <div>
                  <button className='border rounded-sm text-[12px] text-[#828282] px-5 py-1 font-semibold  bg-[#4FD1C50D]'>Delete</button>
                </div>
              </div>

            </div>


          </div>
        </div>
      </div>
    </div>
  )
}

export default TeacherNotification
