import React, { useState, useEffect } from "react";
import { FaRegUserCircle } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import axios from "axios";
import apiKey from "../../../config/apiKey";

function UnreadNotification() {
  const [notifications, setNotifications] = useState([]);
  const student = JSON.parse(localStorage.getItem("edo-student"));
  const userId = student ? student.student.id : "";

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(
          `${apiKey}/api/edolutions/student/notifications/unread/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${student?.token}`,
            },
          }
        );
        setNotifications(response.data);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, [userId,student?.token]);

  const markAsRead = async (notificationId) => {
    try {
      await axios.put(
        `${apiKey}/api/edolutions/student/notifications/mark-as-read/${notificationId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${student?.token}`,
          },
        }
      );
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification.id === notificationId
            ? { ...notification, read: true }
            : notification
        )
      );
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const markAllAsRead = () => {
    notifications.forEach((notification) => {
      if (!notification.read) {
        markAsRead(notification.id);
      }
    });
  };

  return (
    <div className="flex items-center xl:p-5 sm:p-0 justify-center">
      <div className="w-full border rounded-3xl shadow-xl p-5">
        <div>
          <p className="text-2xl font-bold">Notifications</p>
          {/* <p className="text-[#22B7AA] font-light text-sm">
            Student Name Notification
          </p> */}
        </div>
        <div className="flex border-b-2 justify-between items-center mt-8">
          <div className="flex flex-wrap md:justify-start md:space-x-12 space-x-5 text-base font-medium">
            <NavLink
              to=".././notification"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              All
            </NavLink>

            <NavLink
              to=".././newNotification"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              New
            </NavLink>

            {/* <NavLink
              to=".././unreadNotification"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              Unread
            </NavLink> */}
          </div>
          {/* <div>
            <p
              className="text-[12px] bg-[#FAFAF8] text-[#7E7E7E] font-semibold border-[#7E7E7E] p-1 rounded-lg cursor-pointer mb-2"
              onClick={markAllAsRead}
            >
              Mark All As Read
            </p>
          </div> */}
        </div>
        {notifications.map((notification) => (
          <div
            key={notification.id}
            className="grid  gap-4 my-4 bg-[#A3E7E11C] p-4"
          >
            <div className="flex gap-2">
              <div>
                <FaRegUserCircle className="h-8 w-8" />
              </div>
              <div>
                <p className="text-[#2D3748] capitalize text-[14px] font-semibold">
                  {notification.message}
                </p>
                <span className="text-[12px] text-[#2D3748]">
                  {new Date(notification.created_at).toLocaleString()}
                </span>
              </div>
            </div>

            {/* <div className="text-end">
              <button
                className={`border rounded-sm text-[12px] px-5 py-1 font-semibold ${
                  notification.read ? "bg-[#4FD1C5] text-white" : "bg-[#E1E1E1]"
                } mx-2`}
                onClick={() => markAsRead(notification.id)}
                disabled={notification.read}
              >
                {notification.read ? "Accepted" : "Click When Read"}
              </button>
            </div> */}
          </div>
        ))}
      </div>
    </div>
  );
}

export default UnreadNotification;
