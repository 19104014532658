import React, { useState } from 'react'
import { TbBoxMultiple } from "react-icons/tb";
import { LuMonitorUp } from 'react-icons/lu';
import { PiUpload } from 'react-icons/pi';
import { NavLink } from "react-router-dom";



const WebsiteSetting = () => {
  const [showModal, setShowModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);

  return (

    <>
      <div className="flex items-center xl:p-5 sm:p-0 justify-center">
        <div className="w-full border rounded-3xl shadow-xl p-5">
          <div>

            <div>
              <p className='text-2xl font-bold'>Setting</p>
              <p className='text-[#22B7AA] font-light text-sm'>Admin Setting</p>
            </div>
            
            <div className=" flex border-b-2 justify-between items-center mt-8">
              <div className='flex flex-wrap md:justify-start md:space-x-12  space-x-5  text-base font-medium '>

                <NavLink to=".././myAccount" className={({ isActive }) => (isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined)}>My Account</NavLink>

                {/* <NavLink to=".././webSetting" className={({ isActive }) => (isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined)}>Website</NavLink>

              

                <NavLink to=".././settingNoti" className={({ isActive }) => (isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined)}>Notification</NavLink>

                <NavLink to=".././emailTemplate" className={({ isActive }) => (isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined)}>Email Template</NavLink> */}

              </div>
            </div>

            {/* content------------------------------------------- */}

            <div className="lg:p-8 ">
              <div className="lg:py-2 lg:px-5 ">
                <div className="border-b-2 pb-5">
                  <p className="font-bold text-lg">Website Setting</p>
                  <p className="font-normal text-sm">
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry.
                  </p>
                </div>

                <div className=" space-y-3 mt-8">
                  <div className=" md:flex block space-y-3  justify-between items-center p-2 bg-teal-200 bg-opacity-10">
                    <div className="flex items-center gap-5 px-2">
                      <div>
                        <p>
                          <LuMonitorUp className="w-8 h-8" />
                        </p>
                      </div>
                      <div>
                        <p className="text-md font-semibold">Upload The Banners</p>
                        <p className="text-sm font-light"> Lorem Ipsum is simply dummy text of the printing.</p>
                      </div>
                    </div>
                    <div className="px-10 flex justify-start cursor-pointer">
                      <div className="bg-[#5963F5] w-fit text-sm font-semibold rounded-md text-white px-5 py-2">
                        
                        <button onClick={() => setShowModal(true)}>Upload</button>
                      </div>
                    </div>
                  </div>

                  <div className=" md:flex block space-y-3  justify-between items-center p-2 bg-teal-200 bg-opacity-10">
                    <div className="flex items-center gap-5 px-2">
                      <div>
                        <p>
                          <PiUpload className="w-8 h-8" />
                        </p>
                      </div>
                      <div>
                        <p className="text-md font-semibold">Upload The Logo</p>
                        <p className="text-sm font-light"> Lorem Ipsum is simply dummy text of the printing.</p>
                      </div>
                    </div>
                    <div className="px-10 flex justify-start cursor-pointer">
                    <div className="bg-[#5963F5] w-fit text-sm font-semibold rounded-md text-white px-5 py-2">
                        
                        <button onClick={() => setShowSecondModal(true)}>Upload</button>
                      </div>
                    </div>
                  </div>

                  <div className=" md:flex block space-y-3  justify-between items-center p-2 bg-teal-200 bg-opacity-10">
                    <div className="flex items-center gap-5 px-2">
                      <div>
                        <p>
                          <TbBoxMultiple className="w-8 h-8" />
                        </p>
                      </div>
                      <div>
                        <p className="text-md font-semibold">Invite Your Teammate</p>
                        <p className="text-sm font-light"> Lorem Ipsum is simply dummy text of the printing.</p>
                      </div>
                    </div>
                    <div className="px-10 flex justify-start cursor-pointer">
                      <div className="bg-[#5963F5] w-fit text-sm font-semibold rounded-md text-white px-5 py-2">
                        <p>invite</p>
                      </div>
                    </div>
                  </div>

                </div>


                <div className="flex justify-end mt-8">
                  <div className=" bg-[#5963F5] text-sm font-semibold rounded-md text-white px-5 py-2">
                    <p>Changes Save</p>
                  </div>
                </div>


              </div>
            </div>

          </div>

        </div>



 {/* modal---------------------- */}
 {
          showModal ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative mx-auto w-1/2 p-10">
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex item-center flex-col  bg-white outline-none focus:outline-none overflow-y-auto shiftmodel">
                    {/*header*/}
                    <div className=" justify-center items-center p-5 border-solid border-blueGray-200 rounded-t">

                      <button
                        className=" ml-auto  float-right text-2xl" onClick={() => setShowModal(false)} >
                        <span className="text-[#000]"> × </span>
                      </button>
                    </div>





                    <div className="text-center mt-10">
                      <h1 className="text-[24px] font-semibold text-[#000]">Upload Your Banners</h1>
                      <p className="text-[12px] font-semibold text-[#2D3748]"> Lorem Ipsum is simply dummy text of the printing.</p>
                    </div>


                    {/*body*/}
                    <div className="relative px-10 text-start ">
                      <div className="flex justify-between p-3 items-center text-[12px]  bg-[#BBDEFB1A] 
                      px-10 mt-5 ">
                        <p className='text-[#B5B7C0]'>Landing Page</p>

                        <input type="file" id="uploadBtn" className=''/>
                        <label htmlFor="uploadBtn" className="text-[#66BCE8] cursor-pointer select-none	">Browser</label>
                      </div>

                      <div className="flex justify-between p-3 items-center text-[12px]  bg-[#BBDEFB1A] 
                      px-10 mt-5 ">
                        <p className='text-[#B5B7C0]'>Find Teacher Page</p>

                        <input type="file" id="uploadBtn" className=''/>
                        <label htmlFor="uploadBtn" className="text-[#66BCE8] cursor-pointer select-none	">Browser</label>
                      </div>

                      <div className="flex justify-between p-3 items-center text-[12px]  bg-[#BBDEFB1A] 
                      px-10 mt-5 ">
                        <p className='text-[#B5B7C0]'>Find Teacher Job Page</p>

                        <input type="file" id="uploadBtn" className=''/>
                        <label htmlFor="uploadBtn" className="text-[#66BCE8] cursor-pointer select-none	">Browser</label>
                      </div>


                    </div>
                    <div className="text-center my-10">
                      <button className="font-semibold text-white text-[20px]  border-2 rounded-lg bg-[#5963F5] px-6 cursor-pointer py-2" >Upload </button>
                    </div>
                    </div>
                  </div>
                </div>
             
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null
        }


{/* modal-Second--------------------- */}
 {
          showSecondModal ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative mx-auto w-1/2 p-10">
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex item-center flex-col  bg-white outline-none focus:outline-none overflow-y-auto shiftmodel">
                    {/*header*/}
                    <div className=" justify-center items-center p-5 border-solid border-blueGray-200 rounded-t">

                      <button
                        className=" ml-auto  float-right text-2xl" onClick={() => setShowSecondModal(false)} >
                        <span className="text-[#000]"> × </span>
                      </button>
                    </div>





                    <div className="text-center mt-10">
                      <h1 className="text-[24px] font-semibold text-[#000]">Upload Your Logo</h1>
                      <p className="text-[12px] font-semibold text-[#2D3748]"> Lorem Ipsum is simply dummy text of the printing.</p>
                    </div>


                    {/*body*/}
                    <div className="relative px-10 text-start ">
                      <div className="flex justify-between p-3 items-center text-[12px]  bg-[#BBDEFB1A] 
                      px-10 mt-5 ">
                        <p className='text-[#B5B7C0]'>File </p>

                        <input type="file" id="uploadBtn" className=''/>
                        <label htmlFor="uploadBtn" className="text-[#66BCE8] cursor-pointer select-none	">Browser</label>
                      </div>

                      

                     


                    </div>
                    <div className="text-center my-10">
                      <button className="font-semibold text-white text-[20px]  border-2 rounded-lg bg-[#5963F5] px-6 cursor-pointer py-2" >Upload </button>
                    </div>
                    </div>
                  </div>
                </div>
             
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null
        }

      </div>
    </>

  );
}

export default WebsiteSetting
