import React from "react";
import leaf from "../assets/leaf.png";


const ChooseMe = () => {
  return (
    <div>
      <div className="bg-[#A3E7E11C] mt-12 ">
        <div className="flex items-center justify-center">
          <div className="flex flex-wrap sm:w-9/12 items-center justify-center p-4 ">
            <p className="text-[36px] mt-10 font-bold text-[#722DC0] text-center small-heading medium-heading" data-aos="fade-left">
            Why Choose Us?
            </p>
          </div>
        </div>

        <div className="flex flex-wrap  justify-center">
          <div className="flex flex-wrap w-full  max-w-5xl justify-center p-4 mt-5">
            <div className="w-72 p-4 my-3">
              <div className="w-[51px]">
                <img src={leaf} alt="" data-aos="flip-left" />
              </div>
              <div>
                
                <p className="text-[16px] text-[#544837] font-medium mt-3" data-aos="fade-left">
                Personalized Learning: Each student receives tailored lessons designed to suit their academic needs and learning style
                </p>
              </div>
            </div>

            <div className="w-72  p-4 my-3">
              <div className="w-[51px]">
                <img src={leaf} alt="" data-aos="flip-right" />
              </div>
              <div>
               
                <p className="text-[16px] text-[#544837] font-medium mt-3" data-aos="fade-right">
                Flexible Scheduling: Choose from online or in-person sessions, allowing you to learn whenever and wherever it’s most convenient.
                </p>
              </div>
            </div>

            <div className="w-72  p-4 my-3">
              <div className="w-[51px]">
                <img src={leaf} alt="" data-aos="flip-left" />
              </div>
              <div>
                
                <p className="text-[16px] text-[#544837] font-medium mt-3" data-aos="fade-left">
                Qualified Tutors: Our rigorous selection process ensures that only the most qualified, dedicated tutors are part of our platform.
                </p>
              </div>
            </div>

            <div className="w-72  p-4 my-3">
              <div className="w-[51px]">
                <img src={leaf} alt="" data-aos="flip-right" />
              </div>
              <div>
                
                <p className="text-[16px] text-[#544837] font-medium mt-3" data-aos="fade-right">
                Secure and Easy Payments: We provide secure payment options and continuous support, making your learning experience stress-free.
                </p>
              </div>
            </div>

            <div className="w-72  p-4 my-3">
              <div className="w-[51px]">
                <img src={leaf} alt="" data-aos="flip-right" />
              </div>
              <div>
                
                <p className="text-[16px] text-[#544837] font-medium mt-3" data-aos="fade-right">
                24/7 Support: Our support team is always ready to assist you, whether you have questions about scheduling, finding a tutor, or anything else.
                </p>
              </div>
            </div>
          </div>
        </div>

        
      </div>
    </div>
  );
};

export default ChooseMe;
