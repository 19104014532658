import { React, useState, useEffect, useCallback, useRef } from "react";
import { ImUpload } from "react-icons/im";
import Diamond from "../../../assets/Diamond.png";
import { NavLink } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import apiKey from "../../../config/apiKey";
import axios from "axios";
import { toast } from "react-toastify";

const StuProfile = () => {
  const student = JSON.parse(localStorage.getItem("edo-student"));
  const [isLoading, setIsLoading] = useState(false); // Add isLoading state
  const [errors, setErrors] = useState({});
  const [classes, setClasses] = useState([]);
  const [errorsUser, setErrorsUser] = useState({});
  
  const user = {
    email: student ? student.student.email : "",
    id: student ? student.student.id : "",
  };

  const [formData, setFormData] = useState({
        name: "",
        phone: "",
        email: user.email,
        institute: "",
        address:  "",
        country:  "",
        city:  "",
        state: "",
        zip: "",
        class_id:"",
        
        
  });
  const [formUser, setFormUser] = useState({
    name: "",
    phone: "",


  });
  const autocompleteRef = useRef(null); // Reference to the autocomplete input field

  // Function to get user location from autocomplete selection
  const handlePlaceSelect = useCallback(() => {
    const autocomplete = new window.google.maps.places.Autocomplete(autocompleteRef.current, {
      componentRestrictions: { country: 'pk' }, // Restrict to Pakistan
      fields: ['address_components', 'formatted_address'], // Specify the fields you need
    });
  
    // Add listener for place selection
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      const addressComponents = place.address_components;

      const country = addressComponents.find((component) =>
        component.types.includes("country")
      )?.long_name;
      const city = addressComponents.find((component) =>
        component.types.includes("locality")
      )?.long_name;
      const region = addressComponents.find((component) =>
        component.types.includes("administrative_area_level_1")
      )?.long_name;
      const postal = addressComponents.find((component) =>
        component.types.includes("postal_code")
      )?.long_name;

      setFormData((prevData) => ({
        ...prevData,
        
        
        address:  place.formatted_address || "",
      
       
      }));
    });
  }, []);

  useEffect(() => {
    if (window.google) {
      // Initialize Google Places Autocomplete when component mounts
      handlePlaceSelect();
    }
  }, [handlePlaceSelect]);
  useEffect(() => {
    axios
      .get(`${apiKey}/api/edolutions/classes`, {
        
      })
      .then((res) => {
        setClasses(res.data);
  
      })
      .catch((err) => {
        console.error(err);
      });
    },[])
  
  const handleChangeUser = (e) => {
    setFormData
    ({
      ...formData
      ,
      [e.target.name]: e.target.value,
    });

    setErrorsUser({
      ...errorsUser,
      [e.target.name]: "",
    });
  };
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: "",
    });
  };
  const handleChangeClass = (e) => {
    const { name, value } = e.target;

    // Update the state with the new value
    setFormData((prev) => ({
      ...prev,
      [name]: value, // Update the specific field based on name
    }));

    // Update the state with the new value
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: "",
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading to true on form submit
    const updatedFormUser = { ...formUser, updated: "1",
      class_id:formData.class_id,
     };
    try {
      const postRequests = [
        axios.post(
          `${apiKey}/api/edolutions/student/student-profile`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${student?.token}`,
            },
          }
        ),
        axios.put(
          `${apiKey}/api/edolutions/student/updateuser/${user.id}`,
          updatedFormUser,
          {
            headers: {
              Authorization: `Bearer ${student?.token}`,
            },
          }
        ),
      ];

      const [postResponse1, postResponse2] = await Promise.all(postRequests);

      // console.log(postResponse1);
      toast.success("Profile updated successfully", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: {
          backgroundColor: "#4FD1C5", // Customize background color
          color: "#fff", // Customize text color
        },
      });

      // console.log(postResponse2);
      toast.success("User updated successfully", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: {
          backgroundColor: "#4FD1C5", // Customize background color
          color: "#fff", // Customize text color
        },
      });
    } catch (err) {
      if (err.response) {
        if (err.response.data.errors) {
          // Set errors for specific fields
          setErrorsUser(err.response.data.errors);
        }
        if (err.response.data.data) {
          // Set general errors
          setErrors(err.response.data.data);
        }
      } else {
        setErrors({ general: "An unexpected error occurred" });
      }
    } finally {
      setIsLoading(false); // Reset loading state after the request completes
    }
  };
  const [loading, setLoading] = useState(true); // Add loading state
  // useEffect(() => {
  //   setLoading(true); // Start loading before data fetch
  
  //   // First, fetch the user data
  //   axios
  //     .get(`${apiKey}/api/edolutions/student/getuser/${user.id}`, {
  //       headers: {
  //         Authorization: `Bearer ${student?.token}`,
  //       },
  //     })
  //     .then((res) => {
     
  //       setFormUser(res.data);
  
  //       // Check if the 'updated' field is 1
  //       if (res.data.updated === 1) {
  //         // If updated === 1, fetch the student-profile data
  //         axios
  //           .get(`${apiKey}/api/edolutions/student/student-profile`, {
  //             headers: {
  //               Authorization: `Bearer ${student?.token}`,
  //             },
  //           })
  //           .then((res) => {
  //             if (res.data[0]) {
  //               console.log(res.data)
  //               setFormData(res.data);
  //                // Store student-profile data in formData
  //             }
  //           })
  //           .catch((err) => {
  //             console.error("Error fetching student-profile:", err);
  //           });
  //       }
  //     })
  //     .catch((err) => {
  //       console.error("Error fetching user:", err);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // }, [student?.token, user.id]);
  



  useEffect(() => {
    setLoading(true); // Start loading before data fetch
  
    // Fetch user data first
    axios
      .get(`${apiKey}/api/edolutions/student/getuser/${user.id}`, {
        headers: {
          Authorization: `Bearer ${student?.token}`,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setFormUser(res.data);
     
        
        
        
  
        // Check if 'updated' field is 1
        if (res.data.updated === 1) {
          // Fetch the student-profile data if updated === 1
          axios
            .get(`${apiKey}/api/edolutions/student/student-profile`, {
              headers: {
                Authorization: `Bearer ${student?.token}`,
              },
            })
            .then((res) => {
              if (res.data[0]) {
                // Map API response to formData structure
                const profileData = res.data[0];
                setFormData((prevData) => ({
                  ...prevData,
                  email: profileData.email || "", 
                  institute: profileData.institute || "",
                  address: profileData.address || "",
                  country: profileData.country || "",
                  city: profileData.city || "",
                  state: profileData.state || "",
                  zip: profileData.zip || "",
                  class_id: profileData.class_id || "",
                }));
              }
            })
           
            .catch((err) => {
              console.error("Error fetching student-profile:", err);
            });
        }
        else{
          const profileData = res.data;
                setFormData((prevData) => ({
                  ...prevData,
                class_id: profileData.class_id || "",
                }));

        }
      })
      .catch((err) => {
        console.error("Error fetching user:", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [student?.token, user.id]);
  

  // image

  
  
  
  
  const [profileImage, setProfileImage] = useState(null);
  const handleImageUpload = (e) => {
    let formData = new FormData();
    formData.append("photo", e.target.files[0]);

    axios
      .post(`${apiKey}/api/edolutions/student/student_image`, formData, {
        headers: {
          Authorization: `Bearer ${student?.token}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: {
            backgroundColor: "#4FD1C5", // Customize background color
            color: "#fff", // Customize text color
          },
        });

        // Fetch the updated profile image after successful upload
        axios
          .get(`${apiKey}/api/edolutions/student/student-profile`, {
            headers: {
              Authorization: `Bearer ${student?.token}`,
            },
          })
          .then((res) => {
            if (res.data[0]) {
              setProfileImage(res.data[0].photo_url);
              // console.log(profileImage);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.response.data.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
  useEffect(() => {
    axios
      .get(`${apiKey}/api/edolutions/student/student-profile`, {
        headers: {
          Authorization: `Bearer ${student?.token}`,
        },
      })
      .then((res) => {
        console.log(res)
        if (res.data[0]) {
          setProfileImage(res.data[0].photo_url);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [student?.token]);

  return (
    <div>
      <div className="flex items-center xl:p-5 sm:p-0 justify-center pb-12 ">
        <div className="flex flex-wrap w-full justify-center border rounded-3xl shadow-xl pb-12">
          <div className=" w-full xl:w-1/3 p-4 ">
            <div className="flex flex-col  items-center justify-center">
              <div className="">
                <img
                  className="rounded-full border w-40 h-40"
                  src={
                    profileImage
                      ? profileImage
                      : `${apiKey}/userProfile/87654321.png`
                  }
                  alt="Profile"
                />
              </div>
              <input
                type="file"
                id="uploadBtn"
                className=""
                onChange={handleImageUpload}
              />
              <label htmlFor="uploadBtn" className=" select-none	">
                <ImUpload className="w-6 h-6 cursor-pointer ml-32 -mt-6" />
              </label>
            </div>

            <div className="flex items-center justify-center text-3xl mt-3 font-semibold">
              <p>{formUser.name}</p>
              
            </div>

            <div className="flex items-center justify-center text-xl mt-2 font-medium text-[#979CA5]">
              <p>Student</p>
            </div>

            <div className="mx-4">
              <hr className="h-0.5 mt-4 bg-[#979CA5]" />
            </div>

            <div className="mt-3 mx-4 text-2xl font-semibold text-center">
              <p>Account Settings</p>
            </div>

            <div className="mx-8 xl:block md:flex md:flex-wrap md:justify-center md:gap-8 cursor-pointer">
              <NavLink to="">
                <div className="  flex items-center  bg-blue-50  border-l-4 border-[#5963F5]  text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5">
                  <img src={Diamond} alt="" /> <p>Edit Profile</p>
                </div>
              </NavLink>

              {/* <NavLink to="../teacherList">
                <div className=" flex items-center  bg-blue-50 text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5">
                  <img src={Diamond} alt="" /> <p>My Teacher</p>
                </div>
              </NavLink> */}
            </div>

            {/* content7 */}
            <div className="mt-14 mx-4 text-2xl font-semibold text-center">
              <p>My Subscription</p>
            </div>
            {/* content8 */}

            <div className="mx-8 xl:block md:flex md:flex-wrap md:justify-center md:gap-8 cursor-pointer">
              <NavLink to="../studentPayment">
                <div className=" flex items-center  bg-blue-50 text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5">
                  <img src={Diamond} alt="" /> <p>Payments</p>
                </div>
              </NavLink>

              <NavLink to="">
                <div className=" flex items-center  bg-blue-50 text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5">
                  <img src={Diamond} alt="" /> <p>Invoices</p>
                </div>
              </NavLink>
            </div>
          </div>

          {loading ? (
            <div>
              <div colSpan="9" className="py-5 px-1 text-center">
                <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-200 bg-opacity-5 backdrop-blur-sm z-50">
                  <div className="w-12 h-12 border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin border-t-[#2DD0A5]"></div>
                  <p className="mt-4 text-lg font-semibold text-[#2dd0a5]">
                    Edolution...
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full xl:w-2/3 p-4 border-l-2 ">
              {/* content1 */}
              <div className="mx-4 mt-12">
                <p className="text-2xl font-bold">Profile Details</p>
                {/* <p className="text-sm font-medium">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p> */}
              </div>
              <div className="mx-4">
                <hr className="h-0.5 mt-4 bg-[#979CA5]" />
              </div>
              {/* content2 */}

              <div className="w-full mt-12">
                <div className="w-full mt-12">
                  <form className="grid grid-cols-1 sm:grid-cols-2 gap-4 mx-12">
                    <div>
                      <input
                        type="text"
                        placeholder="Full Name"
                        className="border h-10 w-full rounded-lg px-4 bg-gray-50 focus:border-blue-500 focus:outline-none"
                        name="name"
                        value={formUser.name}
                        onChange={handleChangeUser}
                      />
                      {errorsUser.name && (
                        <div className="text-red-500 text-start text-sm">
                          {errorsUser.name[0]}
                        </div>
                      )}
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Email"
                        className="border h-10 w-full rounded-lg px-4 bg-gray-50 focus:border-blue-500 focus:outline-none"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        disabled={!(formData.email === "")}
                      />
                      {errors.email && (
                        <div className="text-red-500 text-start text-sm">
                          {`*email is required!`}
                        </div>
                      )}
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Instituted Name"
                        className="border h-10 w-full rounded-lg px-4 bg-gray-50 focus:border-blue-500 focus:outline-none"
                        name="institute"
                        value={formData.institute}
                        onChange={handleChange}
                      />
                      {errors.institute && (
                        <div className="text-red-500 text-start text-sm">
                          {`*Institute name is required!`}
                        </div>
                      )}
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Country"
                        className="border h-10 w-full rounded-lg px-4 bg-gray-50 focus:border-blue-500 focus:outline-none"
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                      />
                      {errors.country && (
                        <div className="text-red-500 text-start text-sm">
                          {`*Country name is required!`}
                        </div>
                      )}
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="City"
                        className="border h-10 w-full rounded-lg px-4 bg-gray-50 focus:border-blue-500 focus:outline-none"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                      />
                      {errors.city && (
                        <div className="text-red-500 text-start text-sm">
                          {`*City name is required!`}
                        </div>
                      )}
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Zip"
                        className="border h-10 w-full rounded-lg px-4 bg-gray-50 focus:border-blue-500 focus:outline-none"
                        name="zip"
                        value={formData.zip}
                        onChange={handleChange}
                      />
                      {errors.zip && (
                        <div className="text-red-500 text-start text-sm">
                          {`*Zip Code is required!`}
                        </div>
                      )}
                    </div>
                    <div className="col-span-2">
                      {/* <input
                        type="text"
                        placeholder="Address"
                        className="border h-10 w-full rounded-lg px-4 bg-gray-50 focus:border-blue-500 focus:outline-none"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                      />
                      {errors.address && (
                        <div className="text-red-500 text-start text-sm">
                          {`*Address is required!`}
                        </div>
                      )} */}
                     
                            <input
                              id="location-input"
                              className="border h-10 w-full rounded-lg px-4 bg-gray-50 focus:border-blue-500 focus:outline-none"
                              placeholder="Address"
                              type="text"
                              name="address"
                              value={formData.address}
                              onChange={handleChange}
                              ref={autocompleteRef} // Bind input to autocomplete
                              onClick={handlePlaceSelect}
                              onSubmit={(e) => e.preventDefault()}
                              
                            />
                        
                    </div>
                    <div className="col-span-2">
                 
                
        
              
                    <select
  className="border h-10 w-full rounded-lg px-4 bg-gray-50 focus:border-blue-500 focus:outline-none"
  onChange={handleChangeClass}
  name="class_id"
  value={formData.class_id || ''} // Set the initial value based on formUser.class_id
  containerStyle={{
    width: "100%",
    backgroundColor: "transparent",
    borderBottom: "1px solid #F5F5F5",
  }}
  inputStyle={{
    backgroundColor: "transparent",
    width: "100%",
    border: "none",
    outline: "none",
    fontSize: "16px",
    color: "#000",
  }}
  buttonStyle={{
    border: "none",
    backgroundColor: "transparent",
  }}
>
  <option value="">Select Class</option> {/* Set an empty string for default selection */}
  {classes.map((classItem, index) => (
    <option key={index} value={classItem.id}>
      {classItem.class_name}
    </option>
  ))}
</select>

              </div>
                    <div className="col-span-1">
                      <PhoneInput
                        containerStyle={{ position: "relative" }}
                        dropdownStyle={{ top: "auto", bottom: "100%" }}
                        className="outline-none focus-none w-full bg-[#F9FBFF]"
                        placeholder="Phone"
                        country={"pk"}
                        inputProps={{ required: true }}
                        type="tel"
                        name="phone"
                        value={formUser.phone}
                        onChange={(e) => setFormUser({ ...formUser, phone: e })}
                      />
                      {errorsUser.phone && (
                        <div className="text-red-500 text-start text-sm">
                          {errorsUser.phone[0]}
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>

              <div>
                <button
                  className={`border border-blue-500 w-fit p-2 rounded-lg mt-8 font-medium mx-12 cursor-pointer ${
                    isLoading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={handleSubmit}
                  disabled={isLoading} // Disable the button when loading
                >
                  {isLoading ? (
                    <svg
                      className="animate-spin h-5 w-5 text-blue-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    "Save Profile"
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StuProfile;
