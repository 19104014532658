import React from "react";
import { NavLink } from "react-router-dom";

const Notification = () => {
  return (
    <>
      <div className="flex items-center xl:p-5 sm:p-0 justify-center">
        <div className="w-full border rounded-3xl shadow-xl p-5">
          <div>
            <div>
              <p className="text-2xl font-bold">Notification</p>
              <p className="text-[#22B7AA] font-light text-sm">
                Admin Notification
              </p>
            </div>
            <div className=" flex border-b-2 justify-between items-center mt-8">
              <div className="flex flex-wrap md:justify-start md:space-x-12  space-x-5  text-base font-medium ">
                <NavLink
                  to=".././allNotification"
                  className={({ isActive }) =>
                    isActive
                      ? "text-blue-500 border-b-2 border-blue-500"
                      : undefined
                  }
                >
                  All
                </NavLink>

                <NavLink
                  to=".././newNotification"
                  className={({ isActive }) =>
                    isActive
                      ? "text-blue-500 border-b-2 border-blue-500"
                      : undefined
                  }
                >
                  New
                </NavLink>

                <NavLink
                  to=".././unreadNotification"
                  className={({ isActive }) =>
                    isActive
                      ? "text-blue-500 border-b-2 border-blue-500"
                      : undefined
                  }
                >
                  Unread
                </NavLink>
              </div>
              <div>
                <p className="text-[12px] bg-[#FAFAF8] text-[#7E7E7E] font-semibold border-[#7E7E7E] p-1 rounded-lg cursor-pointer mb-2">
                  Mark All As Read
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
