import React from "react";
import Pro from "../../../assets/profile2.png";
import MasterCard from "../../../assets/MasterCard.png"
import { ImUpload } from "react-icons/im";
import Diamond from "../../../assets/Diamond.png";
import { Link, NavLink } from "react-router-dom";


const StudentPayment = () => {

  return ( 
    <div>
      <div className="flex items-center xl:p-5 sm:p-0 justify-center pb-12 ">
        <div className="flex flex-wrap w-full justify-center border rounded-3xl shadow-xl pb-12">
        <div className=" w-full xl:w-1/3 p-4 ">
            {/* content1 */}
            <div className="flex flex-col  items-center justify-center">
              <div className="">
                <img
                  className="rounded-full border w-40 h-40"
                  src={Pro}
                  alt=""
                />
              </div>
              <div className="flex justify-end relative left-16 -top-6">
                <ImUpload className="w-5 h-5" />
              </div>
            </div>
            {/* content2 */}

            <div className="flex items-center justify-center text-4xl font-semibold">
              <p>Saad Saleem</p>
            </div>
            {/* content3 */}

            <div className="flex items-center justify-center text-xl mt-5 font-medium text-[#979CA5]">
              <p>Student</p>
            </div>
            {/* content4 */}

            <div className="flex items-center justify-center ">
              <Link to='.././studentDash'>
                <button className="border bg-[#5653FC] text-white text-xl font-medium rounded-lg px-8 py-3 mt-5"> Go To Dashboard
                </button>
              </Link>
            </div>
            <div className="mx-4">
              <hr className="h-0.5 mt-4 bg-[#979CA5]" />
            </div>
           
            <div className="mt-3 mx-4 text-2xl font-semibold text-center">
              <p>Account Setting</p>
            </div>
          
            <div className="mx-8 xl:block md:flex md:flex-wrap md:justify-center md:gap-8 cursor-pointer">
             

              <NavLink to=''>
                <div className="  flex items-center  bg-blue-50  border-l-4 border-[#5963F5]  text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5">
                  <img src={Diamond} alt="" /> <p>Edit Profile</p>
                </div>
              </NavLink>
            


              <NavLink to=''>
                <div className=" flex items-center border-l-4 border-[#5963F5]  bg-blue-50 text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5">
                  <img src={Diamond} alt="" /> <p>My Students</p>
                </div>
              </NavLink>

              <NavLink to='../teacherNotification'>
                <div className=" flex items-center border-l-4 border-[#5963F5]  bg-blue-50 text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5">
                  <img src={Diamond} alt="" /> <p>Notification</p>
                </div>
              </NavLink>

              <NavLink to=''>
                <div className=" flex items-center border-l-4 border-[#5963F5]  bg-blue-50 text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5">
                  <img src={Diamond} alt="" /> <p>Messages</p>
                </div>
              </NavLink>
            </div>

           
            <div className="mt-14 mx-4 text-2xl font-semibold ">
              <p>My Subscription</p>
            </div>
           


            <div className="mx-8 xl:block md:flex md:flex-wrap md:justify-center md:gap-8 cursor-pointer">
              <NavLink to=''>
                <div className=" flex items-center border-l-4  border-[#5963F5]  bg-blue-50 text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5">
                  <img src={Diamond} alt="" /> <p>Payments</p>
                </div>
              </NavLink>

              <NavLink to='../studentPayment'>
                <div className=" flex items-center border-l-4  border-[#5963F5]  bg-blue-50 text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5">
                  <img src={Diamond} alt="" /> <p>Invoices</p>
                </div>
              </NavLink>

            </div>
          </div>


          <div className="w-full xl:w-2/3 border-l-2 ">
                        {/* content1 */}
                        <div className="mx-8 mt-12 flex flex-wrap items-center justify-between space-y-5">
                            <div>
                                <p className="text-2xl font-bold">Payment</p>
                                <p className="text-[12px] font-medium">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting
                                    industry.
                                </p>
                            </div>
                            <div>
                              <Link to='../paymentDetail'>
                                <p className="border border-blue-500 w-fit rounded-lg px-5 py-2 cursor-pointer font-medium "> + {" "}Add Card</p>
                              </Link>
                            </div>
                        </div>
                        <div className="mx-4">
                            <hr className="h-0.5 mt-4 bg-[#979CA5]" />
                        </div>
                        {/* content2 */}


                        <div className='flex justify-between mt-12 rounded-xl sm:w-full md:w-1/2 mx-8 border px-16 py-14 border-blue-500'>
                            <div><img src={MasterCard} alt="" /></div>
                            <div className='font-medium text-lg'>
                                <p>Mastercard</p>
                                <p>. . . . 0000</p>
                            </div>
                        </div>





                    </div>
        </div>
      </div>
    </div>
  );
};

export default StudentPayment;
