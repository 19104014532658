import React from "react";
import board1 from "../assets/board1.png";
import board2 from "../assets/board2.png";
import board3 from "../assets/board3.png";
import board4 from "../assets/board4.png";

const Boards = () => {
  return (
    <div className="my-10 ">
      <div className="flex items-center justify-center mt-10">
        <div className="flex flex-wrap w-9/12 items-center justify-center p-4 ">
          <p className="small-heading  text-[32px] font-bold text-[#1D1D1B]" data-aos="flip-down">
            Education Board
          </p>
        </div>
      </div>
      <div className="flex items-center justify-center ">
        <div className="flex flex-wrap w-9/12 items-center justify-center overflow-x-hidden p-4  gap-10">
          <div className="w-[192px]">
            <img
              src={board1}
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              alt=""
            />
          </div>
          <div className="w-[252px] h-[122]">
            <img
              src={board2}
              data-aos="flip-right"
              data-aos-easing="ease-out-cubic"
              alt=""
            />
          </div>
          <div className="w-[144px]">
            <img
              src={board3}
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              alt=""
            />
          </div>
          <div className="w-[151px] md:ml-[55px]">
            <img
              src={board4}
              data-aos="flip-right"
              data-aos-easing="ease-out-cubic"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Boards;
