import React from 'react';

const ReturnPolicy = () => {
  return (
    <div className='mt-32 max-w-7xl w-[90%] mx-auto'>
      <h1 className='text-3xl text-[#008956] mb-6 font-semibold'>Return Policy</h1>
      
      <section className="mb-8">
        <h2 className="text-2xl text-[#008956] font-semibold mb-4">1. Non-Refundable Payments</h2>
        <p className='my-2 text-justify'>
          All payments made for classes are final and non-refundable. Once a payment is processed, it cannot be returned or credited back to the customer for any reason.
        </p>
      </section>
      
      <section className="mb-8">
        <h2 className="text-2xl text-[#008956] font-semibold mb-4">2. Class Cancellations</h2>
        <p className='my-2 text-justify'>
          In the unlikely event that a class is canceled by Edolutions, customers will be notified promptly. In such cases, customers may be offered a transfer to a future class or an alternative course, at Edolutions' discretion.
        </p>
      </section>
      
      <section className="mb-8">
        <h2 className="text-2xl text-[#008956] font-semibold mb-4">3. Transfer of Classes</h2>
        <p className='my-2 text-justify'>
          If you are unable to attend a class, you may request to transfer your registration to another individual. Please contact our support team at least 48 hours before the class starts to initiate this process.
        </p>
      </section>
      
      <section className="mb-8">
        <h2 className="text-2xl text-[#008956] font-semibold mb-4">4. Policy Updates</h2>
        <p className='my-2 text-justify'>
          Edolutions reserves the right to modify this return policy at any time. Any changes will be communicated through our website.
        </p>
      </section>
      
      <section className="mb-8">
        <h2 className="text-2xl text-[#008956] font-semibold mb-4">5. Contact Us</h2>
        <p className='my-2 text-justify'>
          If you have any questions regarding this return policy, please contact us at:
        </p>
        <p className="font-medium">
          Email: <a href="mailto:info@edolutions.com" className="text-blue-500">info@edolutions.com</a>
          <div className='font-medium'> Address: {" "}
            <a className='text-blue-500'
                href="https://www.google.com/maps?q=C+19+D+4th+Floor+DHA+Phase+5+Badar+Commercial" 
                target="_blank" 
                rel="noopener noreferrer"
            >
                C 19 D 4th Floor DHA Phase 5 Badar Commercial
            </a>
        </div>
        </p>
      </section>
      
      <p className='my-2 text-justify'>
        Thank you for understanding our return policy. We appreciate your trust in Edolutions for your educational needs!
      </p>
    </div>
  );
};

export default ReturnPolicy;
