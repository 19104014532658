import React from "react";
import check from "../assets/check.png";

const Tutoring = () => {
  return (
    <>

    <div >
      <div className="flex items-center justify-center">
        <div className="flex-wrap   sm:w-9/12 items-center justify-center p-4 ">
          <p className="text-[40px] font-bold text-[#1E1E1E] text-center small-heading medium-heading " data-aos="fade-down">
            Why Choose Edolutions for Tutoring?
          </p>
          <p className="text-[20px] text-center  text-[#1E1E1E] small-text" data-aos="fade-up">
            Your Path to Effective and Rewarding Teaching
          </p>
        </div>
      </div>
      <div className="mt-10" >
        <div className="flex flex-wrap w-full items-center justify-center mt-5">

          <div className=" border-r pl-10 sm:w-1/2 lg:w-72 p-2" data-aos="flip-left">
            <div>
              <div className="flex justify-center">
                <img className="w-[62px]" src={check} alt="" />
              </div>
              <div className="flex justify-center">
                <p className="text-[20px]  font-semibold text-center mt-3">
                  Find students effortlessly
                </p>
              </div>
            </div>
          </div>

          <div className="border-r pl-10 sm:w-1/2 lg:w-72 p-2 " data-aos="flip-right">
            <div>
              <div className="flex justify-center">
                <img className="w-[62px]" src={check} alt="" />
              </div>
              <div className="flex justify-center">
                <p className="text-[20px]  font-semibold text-center mt-3">
                  Set your own <br />
                  rate
                </p>
              </div>
            </div>
          </div>

          <div className="border-r pl-10 sm:w-1/2 lg:w-72 p-2" data-aos="flip-left">
            <div>
              <div className="flex justify-center">
                <img className="w-[62px]" src={check} alt="" />
              </div>
              <div className="flex justify-center">
                <p className="text-[20px]  font-semibold text-center mt-3">
                Teach on your own schedule
                </p>
              </div>
            </div>
          </div>

          <div className=" sm:w-1/2 lg:w-72 p-2" data-aos="flip-left">
            <div>
              <div className="flex justify-center">
                <img className="w-[62px]" src={check} alt="" />
              </div>
              <div className="flex justify-center">
                <p className="text-[20px]  font-semibold text-center mt-3 ">
                Grow professionally
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default Tutoring;
