import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import apiKey from "../config/apiKey";

const QRCodeDisplay = ({ classId }) => {
  const [qrCode, setQrCode] = useState(null);
  const [loading, setLoading] = useState(true);
  const svgContainerRef = useRef(null);

  const generateQRCode = async () => {
    try {
      const response = await axios.get(`${apiKey}/api/edolutions/generate-qr/${classId}`, {
        responseType: 'text'
      });
      setQrCode(response.data);
    } catch (error) {
      console.error("Error generating QR code:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    generateQRCode();
  }, [classId]);

  // Function to convert SVG to PNG and trigger download
  const downloadQRCode = (format = 'png') => {
    const svgElement = svgContainerRef.current.querySelector('svg');
    if (!svgElement) return;

    const svgData = new XMLSerializer().serializeToString(svgElement);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      // Create a download link
      const link = document.createElement('a');
      link.download = `qr-code.${format}`;
      link.href = canvas.toDataURL(`image/${format}`);
      link.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  return (
    <div className="p-6 w-full max-w-sm mx-auto text-center">
      <h3 className="text-xl font-semibold text-gray-700 mb-4">
        QR Code for Class
      </h3>

      {loading ? (
        <p className="text-gray-500">Generating QR Code...</p>
      ) : qrCode ? (
        <div className="mt-6 bg-white p-4 rounded-lg ">
          <div
            ref={svgContainerRef}
            className="inline-block"
            dangerouslySetInnerHTML={{ __html: qrCode }}
          />
          <button
            onClick={() => downloadQRCode('png')}
            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Download QR code
          </button>
        
        </div>
      ) : (
        <p className="text-red-500">Failed to generate QR code.</p>
      )}
    </div>
  );
};

export default QRCodeDisplay;
