import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import RootLayout from "./components/Layout/RootLayout";
import LoginLayout from "./components/Layout/LoginLayout";
import AdminLayout from "./components/Layout/AdminLayout";
import StudentLayout from "./components/Layout/StudentLayout";
import TeacherLayout from "./components/Layout/TeacherLayout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminLogin from "./pages/LoginPages/AdminLogin";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1500,
      easing: "ease",
      once: true,
    });
  }, []);
  return (
    <BrowserRouter>
      <>
        <Routes>
          <Route path="/*" element={<RootLayout />} />
          <Route path="/login/*" element={<LoginLayout />} />
          <Route path="/admin/*" element={<AdminLogin />} />
          <Route path="/admins/*" element={<AdminLayout />} />
          <Route path="/student/*" element={<StudentLayout />} />
          <Route path="/teacher/*" element={<TeacherLayout />} />
        </Routes>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </>
    </BrowserRouter>
  );
}

export default App;
