import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import TeacherCard from "./TeacherCard";
import { FiSearch } from "react-icons/fi";
import { getHitID } from "../../../config/HitIDStorage";
import axios from "axios";
import apiKey from "../../../config/apiKey";

function HomeTutoring() {
  const ID = getHitID();
  const admin = JSON.parse(localStorage.getItem("edo-admin"));
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    // Replace 'your_api_endpoint' with the actual endpoint URL
    const fetchData = async () => {
      setLoading(true); // Start loading before data fetch
      try {
        const response = await axios.get(
          `${apiKey}/api/edolutions/admin/read-notifications-home/${ID}`,
          {
            headers: {
              Authorization: `Bearer ${admin?.token}`,
            },
          }
        );
        setData(response.data.data); // Assuming your API returns the data in this format
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Stop loading after data fetch
      }
    };

    if (ID) {
      fetchData();
    }
  }, [ID, admin?.token]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = data.filter(
    (item) =>
      item.student_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.class_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.subjects.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.day_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.start_time.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.duration.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Time format
  function formatTime(time) {
    let [hours, minutes] = time.split(":");
    let period = "AM";

    hours = parseInt(hours, 10);

    if (hours >= 12) {
      period = "PM";
      if (hours > 12) {
        hours -= 12;
      }
    } else if (hours === 0) {
      hours = 12;
    }

    return `${hours}:${minutes} ${period}`;
  }
  return (
    <>
      <div className="flex items-center xl:p-5 sm:p-0 justify-center">
        <div className="w-full border rounded-3xl shadow-xl p-5">
          <TeacherCard />

          <div className="flex flex-wrap md:justify-start justify-center space-x-5 border-b-2 text-base font-medium ">
            <NavLink
              to="../teacherDash"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              Dashboard
            </NavLink>

            <NavLink
              to=".././teacherMessage"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              View Profile
            </NavLink>

            <NavLink
              to=".././onlineTutoring"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              Online Tutoring
            </NavLink>

            <NavLink
              to=".././homeTutoring"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              Home Tutoring
            </NavLink>

            <NavLink
              to=".././teacherChat"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              Messages
            </NavLink>

            {/* <NavLink to=".././teacherReport" className={({ isActive }) => (isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined)}>Report</NavLink> */}
          </div>

          <div className="mt-2 p-2">
            <div className="flex flex-wrap items-center justify-between p-2">
              <div className="flex justify-between">
                <p className="text-[22px] font-bold">Home Tution</p>
              </div>
              <div className="flex items-center">
                <div className="relative border shadow-md rounded-md flex items-center px-5 py-2">
                  <FiSearch className="h-5 w-5 absolute text-[#5963F5]" />
                  <input
                    type="text"
                    className="outline-none pl-8"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                </div>
              </div>
            </div>
            <div className="mt-5">
              <table className="w-full text-sm text-left text-gray-500 border-b table-auto">
                <thead className="text-sm text-[#B5B7C0] font-medium border-b">
                  <tr className="border-b">
                    <td className="py-5 px-1">Student Name</td>
                    <td className="py-5 px-1">Class</td>
                    <td className="py-5 px-1">Subject</td>
                    <td className="py-5 px-1">Day</td>
                    <td className="py-5 px-1">Timing</td>
                    <td className="py-5 px-1">Duration</td>
                    <td className="py-5 px-1">Payment</td>
                  </tr>
                </thead>
                <tbody className="text-black text-sm font-medium">
                  {loading ? (
                    <tr>
                      <td colSpan="7" className="py-5 px-1 text-center">
                        <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-200 bg-opacity-5 backdrop-blur-sm z-50">
                          <div className="w-12 h-12 border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin border-t-[#2DD0A5]"></div>
                          <p className="mt-4 text-lg font-semibold text-[#2dd0a5]">
                            Edolution...
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : filteredData.length === 0 ? (
                    <tr>
                      <td colSpan="7" className="py-5 px-1 text-center">
                        <p className="text-gray-500">
                          No Home Tution Available
                        </p>
                      </td>
                    </tr>
                  ) : (
                    filteredData.map((item, index) => (
                      <tr key={index} className="border-b">
                        <td className="py-5 px-1">{item.student_name}</td>
                        <td className="py-5 px-1">{item.class_name}</td>
                        <td className="py-5 px-1">{item.subjects}</td>
                        <td className="py-5 px-1 capitalize">
                          {item.day_name ? item.day_name : "Not Define"}
                        </td>
                        <td className="py-5 px-1">
                          {item.start_time && item.end_time
                            ? `${formatTime(item.start_time)} to ${formatTime(
                                item.end_time
                              )}`
                            : item.timeslot_id}
                        </td>
                        <td className="py-5 px-1">
                          {item.days} {item.duration}
                        </td>
                        <td className="py-5 px-1">Rs.{item.payment}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeTutoring;
