import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Profile from "../../../assets/Profile.png";
import { BiArrowBack } from "react-icons/bi";
import vector from "../../../assets/Vector.png";

const TeacherStartClass = () => {
  const [showUserList, setShowUserList] = useState(false);
  const [currentChatUser, setCurrentChatUser] = useState(null);
  const toggleUserList = () => {
    setShowUserList(!showUserList);
  };

  const selectChatUser = (userName) => {
    setCurrentChatUser(userName);
    setShowUserList(false);
  };

  const [showModal, setShowModal] = useState(false);
  const [messages] = useState({
    Alice: [
      { user: "Alice", text: "Hi there!", time: "10:00 AM" },
      { user: "Saad", text: "Hey! How are you?", time: "10:05 AM" },
      {
        user: "Alice",
        text: "I'm good, thanks! How about you?",
        time: "10:10 AM",
      },
      { user: "Alice", text: "Reply me", time: "10:12 AM" },
      { user: "Saad", text: "Hey! How are you?", time: "10:05 AM" },
      {
        user: "Alice",
        text: "I'm good, thanks! How about you?",
        time: "10:10 AM",
      },
      { user: "Alice", text: "Reply me", time: "10:12 AM" },
      { user: "Saad", text: "Hey! How are you?", time: "10:05 AM" },
      {
        user: "Alice",
        text: "I'm good, thanks! How about you?",
        time: "10:10 AM",
      },
      { user: "Alice", text: "Reply me", time: "10:12 AM" },
      {
        user: "Alice",
        text: "I'm good, thanks! How about you?",
        time: "10:10 AM",
      },
      { user: "Alice", text: "Reply me", time: "10:12 AM" },
    ],
    Bob: [
      { user: "Bob", text: "Hey! How are you?", time: "10:05 AM" },
      { user: "Saad", text: "Hey! How are you?", time: "10:10 AM" },
      { user: "Bob", text: "Not bad, thanks!", time: "10:15 AM" },
    ],
    Ali: [
      { user: "Ali", text: "Hey! How are you?", time: "09:00 AM" },
      { user: "Saad", text: "Hey! How are you?", time: "09:05 AM" },
      { user: "Ali", text: "Yeah, it's been a while.", time: "09:10 AM" },
    ],
    Kamran: [
      { user: "Kamran", text: "Hey! How are you?", time: "10:30 AM" },
      { user: "Saad", text: "Hello, are you free today?", time: "10:35 AM" },
      { user: "Kamran", text: "Yes, what's up?", time: "10:40 AM" },
    ],
  });

  const userList = [
    {
      name: "Alice",
      lastMessage: "I'm good, thanks! How Are You??",
      unreadMessages: 2,
      time: "10:10 AM",
    },
    {
      name: "Bob",
      lastMessage: "Hey! How are you?",
      unreadMessages: 0,
      time: "10:05 AM",
    },
    {
      name: "Ali",
      lastMessage: "Hey! How are you?",
      unreadMessages: 4,
      time: "09:00 AM",
    },
    {
      name: "Kamran",
      lastMessage: "Hey! How are you?",
      unreadMessages: 0,
      time: "10:45 AM",
    },
  ];

  const filteredMessages = messages[currentChatUser] || [];
  const navigate = useNavigate();
  return (
    <div className="bg-[#FFFFFF] shadow-lg rounded-xl border overflow-hidden p-2">
      <div className="py-4 px-3">
        <div
          className="flex text-2xl font-bold items-center cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <BiArrowBack />
          <p>Chats</p>
        </div>
        <div className="text-xs font-light text-[#2D3748]">
          <p>Lorem ipsum dolor sit amet consectetur adipisicing.</p>
        </div>
      </div>
      <div className=" flex">
        {/* User List (Toggleable on small screen) */}
        <div
          className={`w-3/4 bg-white md:w-1/4 h-screen md:relative absolute  border-r border-gray-300 py-4 px-3 lg:block ${
            showUserList ? "block" : "hidden"
          }`}
        >
          <input
            type="text"
            placeholder="Search..."
            className="w-full border border-gray-300 rounded-lg py-2 px-4 mb-4 outline-none"
          />
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-sm font-semibold text-[#8E8EA1]">Chats</h2>
            <button
              className="text-gray-500  lg:hidden block hover:text-gray-700 focus:outline-none"
              onClick={toggleUserList}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div>
            {userList.map((user, index) => (
              <div
                key={index}
                className="flex items-center mb-3 cursor-pointer"
                onClick={() => selectChatUser(user.name)}
              >
                <div className="h-8 w-8 bg-gray-200 rounded-full  flex items-center justify-center mr-3">
                  {user.name.charAt(0)}
                </div>
                <div>
                  <h3 className="text-sm font-semibold text-[#22242C]">
                    {user.name}
                  </h3>
                  <p className="text-xs font-normal text-[#8E8EA1] truncate">
                    {user.lastMessage.slice(0, 10)}
                  </p>
                </div>
                <div className="flex flex-col ml-auto text-xs text-gray-500 gap-1 items-center">
                  <span className="text-[9px]">{user.time}</span>
                  {user.unreadMessages > 0 && (
                    <span className="bg-[#FF4A55] text-white px-1 rounded-full mr-1">
                      {user.unreadMessages}
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Chat Messages */}
        <div className="flex-1 w-full lg:w-3/4 h-full">
          <div className="py-4 px-8">
            <div className="flex justify-end">
              <p
                className="bg-[#4FD1C5] w-full text-center   lg:hidden block rounded-lg text-white text-xl font-medium py-2 px-3"
                onClick={toggleUserList}
              >
                Show Chat List
              </p>
            </div>
            {currentChatUser && (
              <div className="flex flex-wrap items-center justify-between gap-7 my-7">
                <div className="flex items-center gap-3">
                  <div>
                    <img
                      className="rounded-full border w-12 h-12"
                      src={Profile}
                      alt=""
                    />
                  </div>
                  <div>
                    <p className="font-semibold text-xl">{currentChatUser}</p>
                    <p className="font-semibold text-xs">Online</p>
                  </div>
                </div>
                <div>
                  <p
                    className="bg-[#4FD1C5] rounded-lg cursor-pointer text-white text-xl font-medium py-2 
                    px-5"
                    onClick={() => setShowModal(true)}
                  >
                    End Class
                  </p>
                </div>
              </div>
            )}
            <div className="flex flex-col h-auto p-2 overflow-y-auto">
              {filteredMessages.map((message, index) => (
                <div
                  key={index}
                  className={`flex ${
                    message.user === currentChatUser
                      ? "justify-end"
                      : "justify-start"
                  } mb-2`}
                >
                  <div
                    className={` rounded-lg py-2 px-4 ${
                      message.user === currentChatUser
                        ? "ml-2 bg-[rgb(187,222,251,0.2)]"
                        : "mr-2 bg-gray-100"
                    }`}
                  >
                    {message.text}
                    <div className="flex justify-end">
                      <span className="text-[9px] text-gray-500">
                        {message.time}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="mt-2">
              <div className="flex">
                <input
                  type="text"
                  placeholder="Write Your Message..."
                  className="flex-1 outline-none border border-gray-300 rounded-lg py-2 px-4"
                />
                <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg ml-2 focus:outline-none focus:shadow-outline">
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* modal---------------------------- */}
      <div>
        {showModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
              <div className="relative xl:mx-auto lg:mx-5 xl:w-1/2 lg:w-9/12  ">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none overflow-y-auto shiftmodel">
                  {/*header*/}

                  {/*body*/}
                  <div className="relative text-start">
                    <div>
                      <div className="md:flex items-center sm:block">
                        <div className="flex flex-col justify-center items-center mx-auto order-2 md:order-1 py-12 md:w-1/2 w-auto text-center">
                          <div className="mb-4">
                            <div className="flex justify-center">
                              <img src={vector} alt="" />
                            </div>
                            <p className="text-2xl font-bold">Class Details</p>
                          </div>
                          <div className="font-semibold text-black text-start">
                            <h1>TIme: 2 hours</h1>
                            <h1>Location: Location here</h1>
                            <h1>Sanp</h1>
                          </div>

                          <div className="mt-8">
                            <button
                              className="bg-[#5963F5] text-white rounded-md px-7 mt-5 py-2"
                              onClick={() => setShowModal(false)}
                            >
                              Confirm to End
                            </button>
                          </div>
                        </div>
                        <div className="md:w-1/2 order-1 md:order-2">
                          <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d924237.7096542217!2d66.49595908273504!3d25.192983663770796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33e06651d4bbf%3A0x9cf92f44555a0c23!2sKarachi%2C%20Karachi%20City%2C%20Sindh%2C%20Pakistan!5e0!3m2!1sen!2s!4v1713914556658!5m2!1sen!2s"
                            title="Map showing the location of Karachi, Pakistan"
                            className="sm:h-[450px] h-[300px] w-full"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="fast"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default TeacherStartClass;
