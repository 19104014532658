import React from 'react'
import JobLanding from "../assets/JobLanding.png"


const JobLandingCard = () => {
  return (
    <div>
      <div className="flex items-center justify-center mt-[100px]  ">
        <div className="flex flex-wrap   max-w-7xl  items-center justify-center  p-4">
          <div className="w-full lg:w-7/12 md:w-7/12 sm:w-full" data-aos="fade-left">
            <p className="text-[48px] font-bold leading-[53.28px] text-[#1E1E1E] small-heading medium-heading ">Your Education, Your Choice <br /> 
              <span className='small-heading text-transparent bg-clip-text bg-gradient-to-r from-[#4FD1C5] 
              to-[#AC47F5]'>Explore Our World-Class </span>
              <span className='small-heading text-transparent bg-clip-text bg-gradient-to-r from-[#4FD1C5] 
              to-[#AC47F5]'>Teacher Directory.</span>
            </p>
            <p className="text-[20px] font-normal leading-[27px] text-[#1E1E1E] mt-2 small-text" data-aos="fade-right">We take pride in boasting the best team of tutors, offering unparalleled expertise and support both online and in person.</p>
          </div>
          <div className="w-full lg:w-5/12 md:w-5/12 sm:w-full">
            <div className='float-right'><img src={JobLanding} alt="" data-aos="flip-right" /></div>
          </div>

        </div>
      </div>
    </div>

  )
}

export default JobLandingCard
