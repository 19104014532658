import { React, useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import apiKey from "../../../config/apiKey";
import axios from "axios";
import { toast } from "react-toastify";
const HomeTution = () => {
  const student = JSON.parse(localStorage.getItem("edo-student"));
  const [isLoading, setIsLoading] = useState(false);
  const [mySubjects, setMySubjects] = useState([]);
  const [classes, setClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [formData, setFormData] = useState({
    class_id: "",
    subject_id: "",
    type_id: "1",
    location: "", // Add location field
  });

  const autocompleteRef = useRef(null); // Reference for autocomplete input

  // Google Autocomplete function
  const handlePlaceSelect = useCallback(() => {
    const autocomplete = new window.google.maps.places.Autocomplete(autocompleteRef.current, {
      componentRestrictions: { country: 'pk' }, // Restrict to Pakistan
      fields: ['address_components', 'formatted_address'], // Specify the fields you need
    });
  
    // Add listener for place selection
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
  
      if (!place.address_components) {
        // Handle the case where no address components are returned
        console.error("No address components available");
        return;
      }
  
      // Use formatted_address to get the full location as a string
      const formattedAddress = place.formatted_address;
  
      setFormData((prevData) => ({
        ...prevData,
        location: formattedAddress || "", // Update location with the selected address
      }));
    });
  }, []);

  useEffect(() => {
    if (window.google) {
      handlePlaceSelect(); // Initialize Google Places Autocomplete when component mounts
    }
  }, [handlePlaceSelect]);

  useEffect(() => {
    axios
      .get(`${apiKey}/api/edolutions/student/classes`, {
        headers: {
          Authorization: `Bearer ${student?.token}`,
        },
      })
      .then((res) => {
        setClasses(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
    axios
      .get(`${apiKey}/api/edolutions/student/subject-wp`, {
        headers: {
          Authorization: `Bearer ${student?.token}`,
        },
      })
      .then((res) => {
        setMySubjects(res.data);
        
      })
      .catch((err) => {
        console.error(err);
      });
  }, [student?.token]);

  useEffect(() => {
    axios
      .get(`${apiKey}/api/edolutions/student/classes/${formData.class_id}`, {
        headers: {
          Authorization: `Bearer ${student?.token}`,
        },
      })
      .then((res) => {
        setSubjects(res.data);
        
      })
      .catch((err) => {
        console.error(err);
      });
  }, [formData.class_id, student?.token]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    axios
      .post(`${apiKey}/api/edolutions/student/collect-data`, formData, {
        headers: {
          Authorization: `Bearer ${student?.token}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: {
            backgroundColor: "#4FD1C5",
            color: "#fff",
          },
        });
        navigate(".././homeTeacherList");
      })
      .catch((err) => {
        toast.error("Error Occured", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const navigate = useNavigate();

  return (
    <div>
      <div className="relative h-screen flex flex-col break-words w-full mb-6 shadow-lg bg-white rounded-lg p-8 border-0">
        <div
          className="flex  gap-2 items-center cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <BiArrowBack className="h-5 w-5" />
          <p className="text-[14px]">Back</p>
        </div>
        <div className="flex justify-center items-center xl:h-screen h-fit xl:mt-0 mt-16">
          <div className="md:w-[500px] w-full">
            <div className="text-center">
              <p className="text-2xl font-semibold">Find Teacher</p>
              <p className="text-sm font-normal">Home Tutoring</p>
            </div>

            <div className="flex flex-col mt-5">
              <select
                className="text-[#B5B7C0] text-md cursor-pointer dropdown-select w-full mt-5 rounded-lg outline-none bg-[#F9FBFF] px-5 py-2"
                onChange={handleChange}
                name="class_id"
              >
                <option value={JSON.stringify({})}>Select Class</option>
                {classes.map((classItem, index) => (
                  <option key={index} value={classItem.id}>
                    {classItem.class_name}
                  </option>
                ))}
              </select>

              <select
                className="text-[#B5B7C0] text-md cursor-pointer dropdown-select w-full mt-5 rounded-lg outline-none bg-[#F9FBFF] px-5 py-2"
                onChange={handleChange}
                name="subject_id"
              >
                <option value={JSON.stringify({})}>Select Subject</option>
                {subjects.map((subject, index) => (
                  <option key={index} value={subject.subject_id}>
                    {subject.subject_name}
                  </option>
                ))}
              </select>

              {/* Autocomplete location input */}
              <input
                className="text-[#B5B7C0] text-md cursor-pointer dropdown-select w-full mt-5 rounded-lg outline-none bg-[#F9FBFF] px-5 py-2"
                placeholder="Enter your location"
                name="location"
                value={formData.location}
                onChange={handleChange}
                ref={autocompleteRef} // Bind input to Google Autocomplete
              />
            </div>

            <div className="text-center mt-10">
              <button
                className={`bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg ml-2 focus:outline-none focus:shadow-outline ${
                  isLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <svg
                    className="animate-spin h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  "Find Teacher"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeTution;
