import React from 'react';
import { Link } from 'react-router-dom';
const Invoice = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 h-screen overflow-y-auto bg-black bg-opacity-50">
      <div className="bg-white w-full max-w-lg rounded-lg shadow-lg border-2 border-gray-200">
        <div className="flex justify-between items-center mb-4">
        
    
        </div>
        {children}
        <div className="mt-4 flex justify-end">
            <Link to="/student/myTutoring">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition"
          >
            Close
          </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
