import MasterCard from "../../../assets/MasterCard.png";
import { ImUpload } from "react-icons/im";
import Diamond from "../../../assets/Diamond.png";
import { Link, NavLink } from "react-router-dom";
import { React, useState, useEffect } from "react";
import apiKey from "../../../config/apiKey";
import axios from "axios";
import { toast } from "react-toastify";

const StudentPayment = () => {
  const student = JSON.parse(localStorage.getItem("edo-student"));
  const user = {
    id: student ? student.student.id : "",
  };
  // image work
  const [profileImage, setProfileImage] = useState(null);
  const handleImageUpload = (e) => {
    let formData = new FormData();
    formData.append("photo", e.target.files[0]);

    axios
      .post(`${apiKey}/api/edolutions/student/student_image`, formData, {
        headers: {
          Authorization: `Bearer ${student?.token}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: {
            backgroundColor: "#4FD1C5", // Customize background color
            color: "#fff", // Customize text color
          },
        });

        // Fetch the updated profile image after successful upload
        axios
          .get(`${apiKey}/api/edolutions/student/student-profile`, {
            headers: {
              Authorization: `Bearer ${student?.token}`,
            },
          })
          .then((res) => {
            if (res.data[0]) {
              setProfileImage(res.data[0].photo_url);
              // console.log(profileImage);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.response.data.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  useEffect(() => {
    axios
      .get(`${apiKey}/api/edolutions/student/student-profile`, {
        headers: {
          Authorization: `Bearer ${student?.token}`,
        },
      })
      .then((res) => {
        if (res.data[0]) {
          setProfileImage(res.data[0].photo_url);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [student?.token]);
  // image work


  const [formUser, setFormUser] = useState({
    name: "",
  });
  useEffect(() => {
    axios
      .get(`${apiKey}/api/edolutions/student/getuser/${user.id}`, {
        headers: {
          Authorization: `Bearer ${student?.token}`,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setFormUser(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [student?.token,user.id]);

  return (
    <div>
      <div className="flex items-center xl:p-5 sm:p-0 justify-center pb-12 ">
        <div className="flex flex-wrap w-full justify-center border rounded-3xl shadow-xl pb-12">
          <div className=" w-full xl:w-1/3 p-4 ">
            <div className="flex flex-col  items-center justify-center">
              <div className="">
              <img
                  className="rounded-full border w-40 h-40"
                  src={
                    profileImage
                      ? profileImage
                      : `${apiKey}/userProfile/87654321.png`
                  }
                  alt="Profile"
                />
              </div>
              <input
                type="file"
                id="uploadBtn"
                className=""
                onChange={handleImageUpload}
              />
              <label htmlFor="uploadBtn" className=" select-none	">
                <ImUpload className="w-6 h-6 cursor-pointer ml-32 -mt-6" />
              </label>
            </div>

            <div className="flex items-center justify-center text-3xl mt-3 font-semibold">
              <p>{formUser.name}</p>
            </div>

            <div className="flex items-center justify-center text-xl mt-5 font-medium text-[#979CA5]">
              <p>Student</p>
            </div>

            <div className="flex items-center justify-center ">
              <Link to="../profile">
              <button className="border bg-[#5653FC] text-white text-xl font-medium rounded-lg px-8 py-3 mt-5">
                {" "}
                Go To Dashboard
              </button>
              </Link>
            </div>

            <div className="mx-4">
              <hr className="h-0.5 mt-4 bg-[#979CA5]" />
            </div>

            <div className="mt-3 mx-4 text-2xl font-semibold text-center">
              <p>Account Settings</p>
            </div>

            <div className="mx-8 xl:block md:flex md:flex-wrap md:justify-center md:gap-8 cursor-pointer">
              <NavLink to="../proSetting">
                <div className="  flex items-center  bg-blue-50    text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5">
                  <img src={Diamond} alt="" /> <p>Edit Profile</p>
                </div>
              </NavLink>

              {/* <NavLink to="../teacherList">
                <div className=" flex items-center  bg-blue-50 text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5">
                  <img src={Diamond} alt="" /> <p>My Teacher</p>
                </div>
              </NavLink> */}
            </div>

            {/* content7 */}
            <div className="mt-14 mx-4 text-2xl font-semibold text-center">
              <p>My Subscription</p>
            </div>
            {/* content8 */}

            <div className="mx-8 xl:block md:flex md:flex-wrap md:justify-center md:gap-8 cursor-pointer">
              <NavLink to="../studentPayment">
                <div className=" flex items-center border-l-4  border-[#5963F5]  bg-blue-50 text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5">
                  <img src={Diamond} alt="" /> <p>Payments</p>
                </div>
              </NavLink>

              <NavLink to="">
                <div className=" flex items-center  bg-blue-50 text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5">
                  <img src={Diamond} alt="" /> <p>Invoices</p>
                </div>
              </NavLink>
            </div>
          </div>

          <div className="w-full xl:w-2/3 border-l-2 ">
            {/* content1 */}
            <div className="mx-8 mt-12 flex flex-wrap items-center justify-between space-y-5">
              <div>
                <p className="text-2xl font-bold">Payment</p>
                {/* <p className="text-[12px] font-medium">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p> */}
              </div>
              <div>
                <Link to="../paymentDetail">
                  <p className="border border-blue-500 w-fit rounded-lg px-5 py-2 cursor-pointer font-medium ">
                    {" "}
                    + Add Card
                  </p>
                </Link>
              </div>
            </div>
            <div className="mx-4">
              <hr className="h-0.5 mt-4 bg-[#979CA5]" />
            </div>
            {/* content2 */}

            <div className="flex justify-between mt-12 rounded-xl sm:w-full md:w-1/2 mx-8 border px-16 py-14 border-blue-500">
              <div>
                <img src={MasterCard} alt="" />
              </div>
              <div className="font-medium text-lg">
                <p>Mastercard</p>
                <p>. . . . 0000</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentPayment;
