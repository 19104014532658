import { ImUpload } from "react-icons/im";
import Diamond from "../../../assets/Diamond.png";
import { NavLink } from "react-router-dom";
import { React, useState, useEffect } from "react";
import apiKey from "../../../config/apiKey";
import axios from "axios";
import { toast } from "react-toastify";

const TeacherList = () => {
  const thead = [
    {
      id: 1,
      thead1: "Teacher Name",
      thead2: "Subject",
      thead3: "Student Name",
      thead5: "Status",
    },
  ];

  const data = [
    {
      id: 1,
      column1: "Muslim Sheikh",
      column2: "Subject Name",
      column3: "Student Name Here",
      column4: "Inactive",
    },
    {
      id: 2,
      column1: "Muslim Sheikh",
      column2: "Subject Name",
      column3: "Student Name Here",
      column4: "Ongoing",
    },
    {
      id: 3,
      column1: "Muslim Sheikh",
      column2: "Subject Name",
      column3: "Student Name Here",
      column4: "Ongoing",
    },
    {
      id: 4,
      column1: "Muslim Sheikh",
      column2: "Subject Name",
      column3: "Student Name Here",
      column4: "Inactive",
    },
  ];

  const student = JSON.parse(localStorage.getItem("edo-student"));
  const user = {
    id: student ? student.student.id : "",
  };
  // image work
  const [profileImage, setProfileImage] = useState(null);
  const handleImageUpload = (e) => {
    let formData = new FormData();
    formData.append("photo", e.target.files[0]);

    axios
      .post(`${apiKey}/api/edolutions/student/student_image`, formData, {
        headers: {
          Authorization: `Bearer ${student?.token}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: {
            backgroundColor: "#4FD1C5", // Customize background color
            color: "#fff", // Customize text color
          },
        });

        // Fetch the updated profile image after successful upload
        axios
          .get(`${apiKey}/api/edolutions/student/student-profile`, {
            headers: {
              Authorization: `Bearer ${student?.token}`,
            },
          })
          .then((res) => {
            if (res.data[0]) {
              setProfileImage(res.data[0].photo_url);
              // console.log(profileImage);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.response.data.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  useEffect(() => {
    axios
      .get(`${apiKey}/api/edolutions/student/student-profile`, {
        headers: {
          Authorization: `Bearer ${student?.token}`,
        },
      })
      .then((res) => {
        if (res.data[0]) {
          setProfileImage(res.data[0].photo_url);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [student?.token]);
  // image work

  const [formUser, setFormUser] = useState({
    name: "",
  });
  useEffect(() => {
    axios
      .get(`${apiKey}/api/edolutions/student/getuser/${user.id}`, {
        headers: {
          Authorization: `Bearer ${student?.token}`,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setFormUser(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [student?.token,user.id]);

  return (
    <div>
      <div className="flex items-center xl:p-5 sm:p-0 justify-center pb-12 ">
        <div className="flex flex-wrap w-full justify-center border rounded-3xl shadow-xl pb-12">
          <div className=" w-full xl:w-1/3 p-4 ">
            <div className="flex flex-col  items-center justify-center">
              <div className="">
              <img
                  className="rounded-full border w-40 h-40"
                  src={
                    profileImage
                      ? profileImage
                      : `${apiKey}/userProfile/87654321.png`
                  }
                  alt="Profile"
                />
              </div>
              <input
                type="file"
                id="uploadBtn"
                className=""
                onChange={handleImageUpload}
              />
              <label htmlFor="uploadBtn" className=" select-none	">
                <ImUpload className="w-6 h-6 cursor-pointer ml-32 -mt-6" />
              </label>
            </div>

            <div className="flex items-center justify-center text-3xl mt-3 font-semibold">
              <p>{formUser.name}</p>
            </div>

            <div className="flex items-center justify-center text-xl mt-5 font-medium text-[#979CA5]">
              <p>Student</p>
            </div>

            <div className="mx-4">
              <hr className="h-0.5 mt-4 bg-[#979CA5]" />
            </div>

            <div className="mt-3 mx-4 text-2xl font-semibold text-center">
              <p>Account Settings</p>
            </div>

            <div className="mx-8 xl:block md:flex md:flex-wrap md:justify-center md:gap-8 cursor-pointer">
              <NavLink to="../proSetting">
                <div className="  flex items-center  bg-blue-50    text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5">
                  <img src={Diamond} alt="" /> <p>Edit Profile</p>
                </div>
              </NavLink>

              {/* <NavLink to="../teacherList">
                <div className=" flex items-center border-l-4 border-[#5963F5]  bg-blue-50 text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5">
                  <img src={Diamond} alt="" /> <p>My Teacher</p>
                </div>
              </NavLink> */}
            </div>

            {/* content7 */}
            <div className="mt-14 mx-4 text-2xl font-semibold text-center">
              <p>My Subscription</p>
            </div>
            {/* content8 */}

            <div className="mx-8 xl:block md:flex md:flex-wrap md:justify-center md:gap-8 cursor-pointer">
              <NavLink to="../studentPayment">
                <div className=" flex items-center  bg-blue-50 text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5">
                  <img src={Diamond} alt="" /> <p>Payments</p>
                </div>
              </NavLink>

              <NavLink to="">
                <div className=" flex items-center   bg-blue-50 text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5">
                  <img src={Diamond} alt="" /> <p>Invoices</p>
                </div>
              </NavLink>
            </div>
          </div>

          <div className="w-full xl:w-2/3 p-4 border-l-2 ">
            {/* content1 */}
            <div className="mx-4 mt-12">
              <p className="text-2xl font-bold">My Teachers List</p>
              {/* <p className="text-sm font-medium">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p> */}
            </div>
            <div className="mx-4">
              <hr className="h-0.5 mt-4 bg-[#979CA5]" />
            </div>
            {/* content2 */}

            <div className="w-full  p-4">
              <table className="w-full text-sm text-left text-gray-500 border-b table-auto">
                <thead className="text-sm text-[#B5B7C0] font-medium border-b">
                  {thead.map((items) => (
                    <tr key={items.id} className="border-b">
                      <td className="py-5 px-1">{items.thead1}</td>
                      <td className="py-5 px-1">{items.thead2}</td>
                      <td className="py-5 px-1">{items.thead3}</td>
                      <td className="py-5 px-1">{items.thead5}</td>
                    </tr>
                  ))}
                </thead>
                <tbody className="text-black text-sm font-medium ">
                  {data.map((item) => (
                    <tr key={item.id} className="border-b">
                      <td className="py-5 px-1">{item.column1}</td>
                      <td className="py-5 px-1">{item.column2}</td>
                      <td className="py-5 px-1">{item.column3}</td>
                      <td className="py-5 px-1">
                        <p
                          className={`border shadow-lg px-5 rounded-md py-1 w-fit ${
                            item.column4 === "Inactive"
                              ? "text-red-500"
                              : item.column4 === "Ongoing"
                              ? "text-green-500"
                              : ""
                          }`}
                        >
                          {item.column4}
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeacherList;
