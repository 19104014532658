import React, { useState } from "react";
import { FiSearch, FiMapPin } from "react-icons/fi";
import { BiArrowBack, BiStar } from "react-icons/bi";
import Profile from "../../../assets/profile2.png";
import { MdOutlineGridView, MdViewList } from "react-icons/md";
import { NavLink, useNavigate } from "react-router-dom";

const HomeTeacherList = () => {
  const [isGridView, setIsGridView] = useState(true); 

  
  const teachers = [
    {
      id: 1,
      name: "John Doe",
      subject: "Mathematics",
      rating: 4,
      location: "New York",
      image: Profile,
      path: "../homeTeacherPortfolio"
    },
    {
      id: 2,
      name: "Jane Smith",
      subject: "Science",
      rating: 3,
      location: "Los Angeles",
      image: Profile,
    },
    {
        id: 3,
        name: "Jane Smith",
        subject: "Science",
        rating: 3,
        location: "Los Angeles",
        image: Profile,
      },
      {
        id: 4,
        name: "Jane Smith",
        subject: "Science",
        rating: 3,
        location: "Los Angeles",
        image: Profile,
      },
      {
        id: 5,
        name: "Jane Smith",
        subject: "Science",
        rating: 3,
        location: "Los Angeles",
        image: Profile,
      },
      {
        id: 6,
        name: "Muslim Sheikh",
        subject: "Science",
        rating: 5,
        location: "Los Angeles",
        image: Profile,
      },
    
  ];

  
  const toggleView = () => {
    setIsGridView((prevState) => !prevState);

  };
  const navigate = useNavigate();

  return (
    <div className="relative flex flex-col break-words w-full mb-6 shadow-lg bg-white rounded-lg p-8 border-0">
      <div className="flex  gap-2 items-center cursor-pointer" onClick={() => navigate(-1)}>
        <BiArrowBack className="h-5 w-5" />
        <p className="text-[14px]">Back</p>
      </div>
      <div className="flex flex-wrap border-b-2 pb-5 items-center mt-5 justify-between">
        <div>
          <p className="text-2xl font-semibold ">Find Teachers</p>
          <p className="text-sm font-normal mt-2 ">Home Tuition</p>
        </div>
        <div className="flex flex-wrap justify-center md:space-y-0 space-y-5 items-center">
          <div className="relative border shadow-md rounded-md flex items-center  px-5 py-2">
            <FiSearch className="h-5 w-5 absolute  text-black" />{" "}
            <input
              type="text"
              className="outline-none pl-8"
              placeholder="Search"
              name=""
              id=""
            />
          </div>
          <div className="relative ml-2">
            <div className="border bg-[#F9FBFF] cursor-pointer shadow-md rounded-md  py-2 px-3 outline-none   text-sm">

            <span className="font-light">Sorted By :</span>
            <select name="" className="cursor-pointer" >
             <option value="" className="font-bold"> Top Teachers</option>
             <option value="" className="font-bold"> Last Teachers</option>
       
             
            </select>
              </div>
          </div>
        </div>
      </div>
      <div className="flex justify-start mt-5 mb-5 gap-3 ">
        <button  onClick={toggleView} >
         <MdOutlineGridView className="h-8 w-8"/>
        </button>

        <button onClick={toggleView} >
         <MdViewList className="h-8 w-8"/>
        </button>

      </div>
          
          <div className={isGridView ? "flex flex-wrap justify-center gap-6 mt-5" : "gap-6 flex flex-col justify-center items-center mt-5 cursor-pointer"}>
            
            {teachers.map((teacher) => (
               <NavLink to={teacher.path}> 
              <div
            key={teacher.id}
            className="bg-white shadow-md w-56 rounded-lg overflow-hidden flex flex-col justify-center items-center cursor-pointer"
          >
            <img
              src={teacher.image}
              alt={teacher.name}
              className="h-24 object-cover object-center rounded-t-lg"
            />
            <div className="p-4 text-center">
              <h3 className="text-lg font-semibold">{teacher.name}</h3>
              <p className="text-xs text-gray-600 font-normal">{teacher.subject}</p>
              <div className="flex items-center justify-center mt-2">
                {[...Array(teacher.rating)].map((_, index) => (
                  <BiStar
                    key={index}
                    className="h-4 w-4 fill-current text-yellow-500"
                  />
                ))}
              </div>
              <div className="flex items-center justify-center mt-2">
                <FiMapPin className="h-3 w-3 mr-1 text-gray-500" />
                <p className="text-xs font-normal text-gray-600">{teacher.location}</p>
              </div>
            </div>
          </div>
            </NavLink>
        ))}
      </div>
    </div>
  );
};

export default HomeTeacherList;
