import React, { useState, useEffect } from "react";
import Book from "../../../assets/Book.png";
import { FaGreaterThan } from "react-icons/fa";
import { GiTeacher } from "react-icons/gi";
import { BiUser } from "react-icons/bi";
import apiKey from "../../../config/apiKey";
import axios from "axios";
import group from "../../../assets/Group 300.png";
import { Link } from "react-router-dom";

const Home = () => {
  const [progress, setProgress] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [totalCountStd, setTotalCountStd] = useState(0);
  const [meetings, setMeetings] = useState([]);
  const [upcomingMeetings, setUpcomingMeetings] = useState([]);
  const [completedMeetings, setCompletedMeetings] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [teachersData, setTeachersData] = useState([]);

  const admin = JSON.parse(localStorage.getItem("edo-admin"));
  const user = {
    name: admin ? admin.admin.name : "",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [teachersResponse, studentsResponse, meetingsResponse] =
          await Promise.all([
            axios.get(`${apiKey}/api/edolutions/admin/users/role/2`, {
              headers: {
                Authorization: `Bearer ${admin?.token}`,
              },
            }),

            axios.get(`${apiKey}/api/edolutions/admin/users/role/3`, {
              headers: {
                Authorization: `Bearer ${admin?.token}`,
              },
            }),

            axios.get(`${apiKey}/api/edolutions/admin/allmeetings`, {
              headers: {
                Authorization: `Bearer ${admin?.token}`,
              },
            }),
          ]);

        const activeTeachers = teachersResponse.data.filter(
          (item) => item.status === "Active"
        );
        setTotalCount(activeTeachers.length);
        setTeachersData(activeTeachers);

        const activeStudents = studentsResponse.data.filter(
          (item) => item.status === "Active"
        );
        setTotalCountStd(activeStudents.length);

        const meetingsData = meetingsResponse.data.meetings;
        setMeetings(meetingsData);

        const now = new Date();
        setUpcomingMeetings(
          meetingsData.filter((meeting) => new Date(meeting.start_time) > now)
        );
        setCompletedMeetings(
          meetingsData.filter((meeting) => new Date(meeting.start_time) <= now)
          
        );

        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading to false if there is an error
      }
    };

    fetchData();
  }, [admin?.token]);

  useEffect(() => {
    const calculateProgress = () => {
      const calculatedProgress = 50; // Adjust this calculation as needed
      return calculatedProgress;
    };
    setProgress(calculateProgress());
  }, []);

  const [detailsVisible, setDetailsVisible] = useState({});

  const toggleDetails = (meetingId) => {
    setDetailsVisible((prevState) => ({
      ...prevState,
      [meetingId]: !prevState[meetingId],
    }));
  };

  return (
    <div className="relative my-4 flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg p-4 border-0">
      {loading && (
        <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-200 bg-opacity-5 backdrop-blur-sm z-50">
          <div className="w-12 h-12 border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin border-t-[#2DD0A5]"></div>
          <p className="mt-4 text-lg font-semibold  text-[#2dd0a5]">
            Edolution...
          </p>{" "}
          {/* Added "Edolution" text */}
        </div>
      )}

      <div
        className={`transition-opacity ${
          loading ? "opacity-0" : "opacity-100"
        }`}
      >
        <div className="bg-gray-200">
          <div className="container-fluid">
            <div className="grid grid-cols-1 sm:grid-cols-3">
              <div className="bg-white px-4 py-4 col-span-1 sm:col-span-2 md:col-span-2 lg:col-span-2 xl:col-span-2">
                <div className="flex items-center space-x-1">
                  <img src={Book} alt="" />
                  <p className="text-[18px] font-bold capitalize">
                    Hey {user.name}
                  </p>
                </div>
                <div>
                  <p className="text-[24px] font-bold mt-2">
                    Dashboard{" "}
                    <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#2DD0A5] to-purple-400">
                      Home
                    </span>
                  </p>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 md:grid-cols-1 gap-4 mt-5">
                  <div className="shadow-xl p-8 rounded-xl sm:row-span-2 lg:col-span-2 md:col-span-4">
                    <Link to="/admins/teacher">
                      <div className="flex items-center justify-between">
                        <p className="text-[24px] font-bold">Total Teachers</p>
                        <p className="text-[32px] font-bold">{totalCount}</p>
                      </div>
                      <img src={group} alt="" className="float-end" />
                    </Link>
                  </div>

                  <div className="shadow-xl p-8 rounded-xl sm:row-span-2 lg:col-span-2 md:col-span-4">
                    <Link to="/admins/student">
                      <div className="flex items-center justify-between">
                        <p className="text-[24px] font-bold">Total Students</p>
                        <p className="text-[32px] font-bold">{totalCountStd}</p>
                      </div>
                      <img src={group} alt="" className="float-end" />
                    </Link>
                  </div>
                </div>

                <div className="flex items-center justify-between px-6 mt-10">
                  <div>
                    <p className="lg:text-[24px] md:text-[22px] font-semibold text-[#121212]">
                      Featured Top Rated Teachers
                    </p>
                  </div>

                  <div className="flex items-center space-x-2 cursor-pointer">
                    <p className="lg:text-[20px] md:text-[15px] font-semibold text-[#2BACE2]">
                      View all
                    </p>
                    <p className="text-[#2BACE2]">
                      <FaGreaterThan />
                    </p>
                  </div>
                </div>

                <div className="flex flex-col mt-8">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="overflow-hidden border-gray-200">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-[20px] font-medium text-[#94918A] tracking-wider w-4/12"
                              >
                                Teachers name
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-[20px] font-medium text-[#94918A] tracking-wider w-2/12"
                              >
                                Phone
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-[20px] font-medium text-[#94918A] tracking-wider w-2/12"
                              >
                                Joining
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-[20px] font-medium text-[#94918A] tracking-wider w-2/12"
                              >
                                Type
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
  {teachersData.slice(0, 3).map((teacher) => (
    <tr key={teacher.id}>
      <th className="flex items-center space-x-4">
        <div>
          <img src={Book} alt="" />
        </div>
        <div className="text-start">
          <p className="text-[20px] font-medium">
            {teacher.name}
          </p>
          <p className="text-sm text-gray-500">
            {teacher.email}
          </p>
        </div>
      </th>

      <th>
        <div className="flex items-center justify-center space-x-1">
          <p>{teacher.phone}</p>
        </div>
      </th>
      <th>
        {new Date(teacher.created_at).toLocaleDateString()}
      </th>
      <th>
        <div className="flex items-center justify-center space-x-1">
          <p>
            {teacher.type_id === 1 && "Home"}
            {teacher.type_id === 2 && "Online"}
            {teacher.type_id === 3 && "Both"}
          </p>
        </div>
      </th>
    </tr>
  ))}
</tbody>

                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white col-span-1 h-screen overflow-hidden">
                <div>
                  <div className="flex justify-between items-center space-x-2">
                    <div className="flex items-center space-x-2">
                      {" "}
                      <GiTeacher className="text-2xl text-[#2BACE2]" />
                      <p className="text-[18px] font-semibold">
                        Upcoming Meetings
                      </p>
                    </div>
                    <div>
                      <Link to="/admins/upcoming">
                        <button className="px-4 py-2 text-sm font-semibold text-white bg-[#2bace2] rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75">
                          All Meetings
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="mt-4 grid gap-4 h-64 overflow-auto">
                    {upcomingMeetings.length > 0 ? (
                      upcomingMeetings.map((meeting) => (
                        <div
                          key={meeting.id}
                          className="p-4 border rounded-lg shadow-md bg-white"
                        >
                          <p className="font-bold text-lg">
                            Topic: {meeting.topic}
                          </p>
                          <p className="font-medium">
                            Meeting ID: {meeting.id}
                          </p>
                          {detailsVisible[meeting.id] && (
                            <>
                              <p className="font-medium">{meeting.agenda}</p>
                              <p className="text-sm text-gray-600">
                                Scheduled for:{" "}
                                {new Date(meeting.start_time).toLocaleString()}
                              </p>
                            </>
                          )}
                          <button
                            onClick={() => toggleDetails(meeting.id)}
                            className="mt-2 text-blue-500"
                          >
                            {detailsVisible[meeting.id]
                              ? "Hide Details"
                              : "Show Details"}
                          </button>
                        </div>
                      ))
                    ) : (
                      <div className="p-4 border rounded-lg shadow-md bg-white">
                        <p>No upcoming meetings.</p>
                      </div>
                    )}
                  </div>
                </div>

                <div className="mt-8">
                  <div className="flex justify-between items-center space-x-2">
                    <div className="flex items-center space-x-2">
                      {" "}
                      <BiUser className="text-2xl text-[#2BACE2]" />
                      <p className="text-[18px] font-semibold">
                        Completed Meetings
                      </p>
                    </div>
                    <div>
                      <Link to="/admins/completed">
                        <button className="px-4 py-2 text-sm font-semibold text-white bg-[#2bace2] rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75">
                          All Meetings
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="mt-4 grid gap-4 h-64 overflow-auto">
                    {completedMeetings.length > 0 ? (
                      completedMeetings.map((meeting) => (
                        <div
                          key={meeting.id}
                          className="p-4 border rounded-lg shadow-md bg-white"
                        >
                          <p className="font-bold text-lg capitalize">
                            Topic: {meeting.topic}
                          </p>
                          <p className="font-medium">
                            Meeting ID: {meeting.id}
                          </p>
                          {detailsVisible[meeting.id] && (
                            <>
                              <p className="font-medium">{meeting.agenda}</p>
                              <p className="text-sm text-gray-600">
                                Completed on:{" "}
                                {new Date(meeting.start_time).toLocaleString()}
                              </p>
                            </>
                          )}
                          <button
                            onClick={() => toggleDetails(meeting.id)}
                            className="mt-2 text-blue-500"
                          >
                            {detailsVisible[meeting.id]
                              ? "Hide Details"
                              : "Show Details"}
                          </button>
                        </div>
                      ))
                    ) : (
                      <div className="p-4 border rounded-lg shadow-md bg-white">
                        <p>No completed meetings.</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
