import { ImUpload } from "react-icons/im";
import Diamond from "../../../assets/Diamond.png";
import { NavLink, useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import axios from "axios";
import apiKey from "../../../config/apiKey";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
const PaymentDetail = () => {
  const teacher = JSON.parse(localStorage.getItem("edo-teacher"));
  const [profileImage, setProfileImage] = useState(null);
  const handleImageUpload = (e) => {
    let formData = new FormData();
    formData.append("photo", e.target.files[0]);
  
    axios
      .post(`${apiKey}/api/edolutions/teacher/teacher-image-upload`, formData, {
        headers: {
          Authorization: `Bearer ${teacher?.token}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: {
            backgroundColor: "#4FD1C5", // Customize background color
            color: "#fff", // Customize text color
          },
        });
  
        // Fetch the updated profile image after successful upload
        axios
          .get(`${apiKey}/api/edolutions/teacher/teacher-profile`, {
            headers: {
              Authorization: `Bearer ${teacher?.token}`,
            },
          })
          .then((res) => {
            if (res.data[0]) {
              setProfileImage(res.data[0].photo_url);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.response.data.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
  useEffect(() => {
    axios
      .get(`${apiKey}/api/edolutions/teacher/teacher-profile`, {
        headers: {
          Authorization: `Bearer ${teacher?.token}`,
        },
      })
      .then((res) => {
        if (res.data[0]) {
          // setFormData(res.data[0]);
          setProfileImage(res.data[0].photo_url);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [teacher?.token]);
  const navigate = useNavigate();




   // name work
   const userId = {
    id: teacher ? teacher.teacher.id : "",
  };
  const [formUser, setFormUser] = useState({})
  useEffect(() => {
    axios
      .get(`${apiKey}/api/edolutions/teacher/getuser/${userId.id}`, {
        headers: {
          Authorization: `Bearer ${teacher?.token}`,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setFormUser(res.data);
      })
      .catch((err) => {
        console.error(err);
      });

  }, [teacher?.token,userId.id]);

  return (
    <div>
      <div className="flex items-center xl:p-5 sm:p-0 justify-center pb-12 ">
        <div className="flex flex-wrap w-full justify-center border rounded-3xl shadow-xl pb-12">
          <div className=" w-full xl:w-1/3 p-4 ">
            <div className="flex flex-col  items-center justify-center">
              <div className="">
              <img
                  className="rounded-full border w-40 h-40"
                  src={
                    profileImage
                      ? profileImage
                      : `${apiKey}/userProfile/87654321.png`
                  }
                  alt="Profile"
                />
              </div>
              <input
                onChange={handleImageUpload}
                type="file"
                id="uploadBtn"
                className=""
              />
              <label htmlFor="uploadBtn" className=" select-none	">
                <ImUpload className="w-6 h-6 cursor-pointer ml-32 -mt-6" />
              </label>
            </div>

            <div className="flex items-center justify-center text-3xl font-semibold mt-2">
            <p>{formUser.name}</p>
            </div>

            <div className="flex items-center justify-center text-xl mt-5 font-medium text-[#979CA5]">
              <p>Teacher</p>
            </div>

            <div className="mx-4">
              <hr className="h-0.5 mt-4 bg-[#979CA5]" />
            </div>

            <div className="mt-3 mx-4 text-center text-2xl font-semibold">
              <p>Account Settings</p>
            </div>

            <div className="mx-8 xl:block md:flex md:flex-wrap md:justify-center md:gap-8 cursor-pointer">
              <NavLink to="../teacherSetting">
                <div className="  flex items-center  bg-blue-50  text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5">
                  <img src={Diamond} alt="" /> <p>Time Slot</p>
                </div>
              </NavLink>

              {/* <NavLink to="../students">
                <div className="  flex items-center  bg-blue-50   text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5">
                  <img src={Diamond} alt="" /> <p>My Students</p>
                </div>
              </NavLink> */}
            </div>

            {/* content7 */}
            <div className="mt-14 mx-4 text-center text-2xl font-semibold ">
              <p>My Subscription</p>
            </div>
            {/* content8 */}

            <div className="mx-8 xl:block md:flex md:flex-wrap md:justify-center md:gap-8 cursor-pointer">
              <NavLink to="../teacherPayment">
                <div className=" flex items-center border-l-4  border-[#5963F5]  bg-blue-50 text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5">
                  <img src={Diamond} alt="" /> <p>Payments</p>
                </div>
              </NavLink>

              <NavLink to="">
                <div className=" flex items-center   bg-blue-50 text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5">
                  <img src={Diamond} alt="" /> <p>Invoices</p>
                </div>
              </NavLink>
            </div>
          </div>

          <div className="w-full xl:w-2/3 p-4 border-l-2 ">
            <div
              className="flex text-[14px] gap-1 font-Semibold items-center cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <BiArrowBack className="h-5 w-5" />
              <p>Back</p>
            </div>
            {/* content1 */}
            <div className="mx-4 mt-4">
              <p className="text-2xl font-bold">Payment</p>
              <p className="text-sm font-medium">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </div>
            <div className="mx-4">
              <hr className="h-0.5 mt-4 bg-[#979CA5]" />
            </div>
            {/* content2 */}

            <div className="w-full mt-12 mx-14">
              <form>
                <div className="">
                  <h1 className="text-[#0A0D13] text-[18px] font-semibold">
                    Card Number
                  </h1>
                  <input
                    type="text"
                    placeholder="1234 5678 2312 0980"
                    className="border h-10 w-2/3  rounded-lg px-4 bg-gray-50 focus:border-blue-500 focus:outline-none"
                  />
                </div>

                <div className="flex flex-wrap gap-3 my-4 ">
                  <div>
                    <h1 className="text-[#0A0D13] text-[18px] font-semibold">
                      Expiration Date
                    </h1>
                    <input
                      type="text"
                      placeholder="MM/YY"
                      className="border h-10 rounded-lg px-4 bg-gray-50 focus:border-blue-500 focus:outline-none"
                    />
                  </div>
                  <div>
                    <h1 className="text-[#0A0D13] text-[18px] font-semibold">
                      CVV
                    </h1>
                    <input
                      type="text"
                      placeholder="123"
                      className="border h-10  rounded-lg px-4 bg-gray-50 focus:border-blue-500 focus:outline-none"
                    />
                  </div>
                </div>

                <div className="flex gap-2">
                  <input type="checkbox" />
                  <p className="text-[#ACACAC]"> Save card details</p>
                </div>
              </form>
            </div>

            <div className="ml-14">
              <button className="border border-[#4FD1C5] bg-[#4FD1C5] w-3/4 text-white font-bold text-[20px] text-center p-2  rounded-lg mt-8  cursor-pointer">
                Save
              </button>

              <p className="my-4 w-2/3 text-[#ACACAC] text-[12px]">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis
                doloremque incidunt possimus nobis totam animi et soluta
                quibusdam, cupiditate eius.dolor sit amet consectetur
                adipisicing elit.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetail;
