import {useEffect} from 'react'
import StudentFormSignUp from '../../components/StudentFormSignUp'
import Login1 from "../../assets/Login1.png";

function StudentSignUp() {
  useEffect(() => {
    window.scrollTo(0, 0); // Automatically scroll to top when page is loaded
  }, []);
  return (
    <div> 

      <StudentFormSignUp Description="Where you left off on your learning journey. Your personalized dashboard awaits, ready to empower you with the tools and resources you need to succeed."
       Image={Login1}
       path='../studentLogin'/>
    </div>
  )
}

export default StudentSignUp
