import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";

const Card2 = ({ card }) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div
        className="flex gap-2 items-center cursor-pointer"
        onClick={() => navigate(-1)}
      >
        <BiArrowBack className="h-5 w-5" />
        <p className="text-[14px]">Back</p>
      </div>

      {card &&
        card.map((index, idx) => (
          <div className="xl:mx-10 mx-0" key={idx}>
            <div className="flex flex-wrap items-center justify-between gap-7 my-7">
              <div>
                <div>
                  <img
                    className="rounded-full border w-20 h-20"
                    src={index.profile}
                    alt=""
                  />
                </div>
                <div>
                  <p className="font-semibold text-xl">{index.name}</p>
                  <p className="font-normal text-base">{index.charge}</p>
                </div>
              </div>

              <div>
                <button
                  className="bg-[#5963F5] rounded-lg text-white text-xl font-medium py-3 px-8"
                  onClick={() => setShowModal(true)}
                >
                  Request to Apply
                </button>
              </div>
            </div>
          </div>
        ))}

      {/* modal----------------------- */}
      <div>
        {showModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative mx-auto w-1/3">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none overflow-y-auto shiftmodel">
                  {/*header*/}
                  <div className="items-start justify-center p-5 border-solid border-blueGray-200 rounded-t">
                    <button
                      className=" ml-auto  float-right text-2xl"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="text-[#000]"> × </span>
                    </button>
                  </div>

                  <div className="text-center">
                    <h1 className="text-[24px] font-semibold text-[#000]">
                      Select Slot
                    </h1>
                  </div>

                  {/*body*/}
                  <div className="text-center">
                    <div className="mt-8">
                      <input
                        type="date"
                        className="text-[#B5B7C0] text-md border rounded-lg outline-none bg-[#F9FBFF] w-2/4 px-2 py-2 cursor-pointer"
                      />
                    </div>

                    <select className="text-[#B5B7C0] text-md dropdown-select border rounded-lg outline-none bg-[#F9FBFF] w-2/4 px-2 py-2 cursor-pointer my-3">
                      <option value="">Select Slot</option>
                      <option value="">Online Tution</option>
                      <option value="">Home Tution</option>
                    </select>

                    <div className="">
                      <input
                        type="text"
                        placeholder="Enter the Topic"
                        className="text-[#B5B7C0] text-md border rounded-lg outline-none bg-[#F9FBFF] w-2/4 px-2 py-2 cursor-pointer mb-8"
                      />
                    </div>
                  </div>
                  <div className="text-center mb-7 mt-4">
                    <Link
                      to="../homeTeacherPortfolio2"
                      className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-10 rounded-lg focus:outline-none focus:shadow-outline"
                    >
                      Enter
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default Card2;
