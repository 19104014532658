import React from 'react'
import Profile from "../../../assets/Profile.png";
import { NavLink } from 'react-router-dom';
const TeacherMsgCard = () => {
    return (
        <div className="xl:mx-10 mx-0 ">
        <div className="flex flex-wrap items-center justify-between gap-7 mb-7">
          <div className="">
            <div>
              <img
                className="rounded-full border w-20 h-20"
                src={Profile}
                alt=""
              />
            </div>
            <div>
              <p className="font-semibold text-xl">Irfan Ahsan</p>
            </div>
            <div className='flex space-x-10'>
            <p className="font-normal text-base">Class 8</p>
            <p className="font-normal text-base">Home Tutoring</p>
            </div>

          </div>
          <NavLink to ='./../teacherChat'>
              <p className="bg-blue-500 rounded-lg text-white text-xl font-medium py-3 px-8">Open Chat</p>
          </NavLink>
        </div>
      </div>
      )
}

export default TeacherMsgCard
