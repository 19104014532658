import React, { useState } from 'react'
import { FaWandMagicSparkles } from 'react-icons/fa6';
import { NavLink } from "react-router-dom";



const TeachNotification = () => {

  const [data] = useState([
    {
      
      lable: "You have been invited to a tuition"
    },
    {
      
      lable: "You must add banking information!"
    },
    {
     
      lable: "New message alert"
    },
    {
      
      lable: "New Support Call"
    },
  ])
  return (

    <>
      <div className="flex items-center xl:p-5 sm:p-0 justify-center ">
        <div className="w-full border rounded-3xl shadow-xl p-5 px-10">
          <div>

          <div className="mt-3  flex  items-center">
          <div>
            {" "}
            <p className="text-2xl font-semibold ">Settings</p>
          </div>
        </div>


        {/* tableee----------- */}
        <div className="flex  flex-wrap md:justify-start justify-center  space-x-5  border-b-2 text-base font-medium my-5">

          <NavLink to=".././profileSettingTech" className={({ isActive }) => (isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined)}>Profile Settings</NavLink>

          <NavLink to=".././subjectTech" className={({ isActive }) => (isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined)}>Subjects & WP</NavLink>

          <NavLink to=".././otherDetail" className={({ isActive }) => (isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined)}>Other Details</NavLink>

          <NavLink to=".././security" className={({ isActive }) => (isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined)}>Privacy & Security</NavLink>

          <NavLink to=".././techerNotiSetting" className={({ isActive }) => (isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined)}>Notification</NavLink>
        </div>
        {/* end---------- */}

            {/* content--------------------------- */}
            <div className=''>
              <div className="mt-8">
                <p className="font-semibold text-[22px]">Notification</p>
                
              </div>
              {data && data.map((index) => (
                <div className='grid grid-cols-2 gap-4 my-4 bg-[#A3E7E11C] p-4'>

                  <div className='flex gap-3'>
                    <div>
                      <FaWandMagicSparkles className='h-10 w-8' />
                    </div>
                    <div>
                      <h1 className='text-[#2D3748] text-[14px] font-semibold'>
                        {index.name}
                      </h1>
                      <p className='text-[#2D3748] text-[14px] mt-2'>{index.lable}</p>
                    </div>
                  </div>

                  <label className="inline-flex items-center mb-5 cursor-pointer justify-end">
                    <input type="checkbox" value="" className="sr-only peer" />
                    <div className="relative w-11 h-6 bg-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-500 dark:peer-focus:ring-white-500 rounded-full peer dark:bg-gray-300 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-blue-500 after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-blue-500 after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-blue-500 peer-checked:bg-blue-500"></div>

                  </label>

                </div>

              ))}
            </div>
          </div>

        </div>
      </div>
    </>

  );
}

export default TeachNotification
