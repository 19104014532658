import React from 'react'

const Terms = () => {
  return ( 
    <div className='mt-32  mx-auto text-justify max-w-7xl w-[90%] '>
         <h1 class="text-3xl text-[#008956] font-bold mb-6 ">Terms & Conditions</h1>
        <p class="text-gray-600  mb-8">Effective Date: 01-04-2024</p>

        <section class="mb-8">
            <p class="mb-4">
                Welcome to Edolutions! By using our platform (<a href="https://edolutions.com" class="text-blue-500">https://edolutions.com</a>), you agree to comply with and be bound by the following Terms and Conditions ("Terms" or "Agreement"). Please read these Terms carefully before using the Website or any of the services provided by Edolutions. If you do not agree to these Terms, please do not use our website or services.
            </p>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl text-[#008956] font-semibold mb-4">1. Acceptance of Terms</h2>
            <p>By accessing or using Edolutions, you acknowledge that you have read, understood, and agree to be legally bound by these Terms and our Privacy Policy. These Terms apply to all visitors, users, and others who access or use the services.</p>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl text-[#008956] font-semibold mb-4">2. Changes to Terms</h2>
            <p>We reserve the right to modify or update these Terms at any time without prior notice. Any changes will be effective immediately upon posting. Your continued use of the Website after the posting of changes constitutes your acceptance of the revised Terms. We recommend checking this page periodically for any updates.</p>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl text-[#008956] font-semibold mb-4">3. Eligibility</h2>
            <p>By using Edolutions, you represent and warrant that:</p>
            <ul class="list-disc ml-5 list-inside space-y-2">
                <li>You are at least 18 years of age, or, if under 18, have the legal consent of a parent or guardian to use the Website.</li>
                <li>You have the legal capacity and authority to enter into this Agreement.</li>
            </ul>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl text-[#008956] font-semibold mb-4">4. Use of Services</h2>
            <p>You agree to use Edolutions for lawful purposes only and in accordance with these Terms. You are prohibited from:</p>
            <ul class="list-disc ml-5 list-inside space-y-2">
                <li>Using the Website for any illegal or unauthorized purposes.</li>
                <li>Posting, transmitting, or sharing content that is illegal, defamatory, obscene, or infringes on intellectual property rights.</li>
                <li>Engaging in harmful activities such as distributing viruses, malware, or other malicious software.</li>
                <li>Interfering with the functionality of the Website or other users’ enjoyment of the platform.</li>
            </ul>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl text-[#008956] font-semibold mb-4">5. Registration and Account</h2>
            <p>Certain features of Edolutions may require you to register for an account. You agree to:</p>
            <ul class="list-disc ml-5 list-inside space-y-2">
                <li>Provide accurate and complete information during registration.</li>
                <li>Keep your account credentials confidential and secure.</li>
                <li>Be responsible for all activities that occur under your account.</li>
            </ul>
            <p>We reserve the right to terminate or suspend your account if we suspect any violation of these Terms.</p>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl text-[#008956] font-semibold mb-4">6. Intellectual Property</h2>
            <p>All content, materials, and services on Edolutions, including but not limited to text, graphics, logos, images, and software, are the property of Edolutions or its licensors and are protected by applicable intellectual property laws. You may not copy, distribute, or create derivative works from our content without prior written consent from Edolutions.</p>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl text-[#008956] font-semibold mb-4">7. Payment and Fees</h2>
            <p>Access to certain services or courses on Edolutions may require payment of fees. All fees are non-refundable unless otherwise stated. We reserve the right to modify our pricing at any time without prior notice, but such changes will not affect prior purchases.</p>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl text-[#008956] font-semibold mb-4">8. User Content</h2>
            <p>You may have the ability to submit, post, or upload content (e.g., assignments, reviews, comments). By doing so, you grant Edolutions a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, and distribute your content in connection with the Website and our services. You are responsible for ensuring that your content does not infringe any third-party rights or violate any laws.</p>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl text-[#008956] font-semibold mb-4">9. Termination</h2>
            <p>We may suspend or terminate your access to Edolutions at our sole discretion, without notice, for conduct that we believe violates these Terms or is harmful to other users, us, or third parties. Upon termination, you must cease using the Website and any associated services.</p>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl text-[#008956] font-semibold mb-4">10. Disclaimer of Warranties</h2>
            <p>Edolutions is provided on an “as is” and “as available” basis without warranties of any kind, either express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, or non-infringement. We do not warrant that the Website will be error-free, uninterrupted, or secure.</p>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl text-[#008956] font-semibold mb-4">11. Limitation of Liability</h2>
            <p>In no event shall Edolutions, its directors, employees, or affiliates be liable for any indirect, incidental, special, consequential, or punitive damages arising from your use of the Website or services, even if advised of the possibility of such damages. Our total liability to you for any claims arising from your use of the Website or services will not exceed the amount you paid to us, if any.</p>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl text-[#008956] font-semibold mb-4">12. Indemnification</h2>
            <p>You agree to indemnify and hold harmless Edolutions, its officers, employees, and affiliates from any claims, liabilities, damages, and expenses (including legal fees) arising out of your use of the Website, your breach of these Terms, or your violation of any rights of another party.</p>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl text-[#008956] font-semibold mb-4">13. Governing Law</h2>
            <p>These Terms and any disputes arising from your use of the Website shall be governed by and construed in accordance with the laws of [Insert Country], without regard to its conflict of law provisions.</p>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl text-[#008956] font-semibold mb-4">14. Contact Us</h2>
            <p>If you have any questions about these Terms, please contact us:</p>
            <p class="font-medium">Email: <a href="mailto:info@edolutions.com" class="text-blue-500">info@edolutions.com</a></p>
            <p class="font-medium">Phone: <a href="tel:+923313655666" class="text-blue-500">+92 331 3655666</a></p>
        </section>
    </div>
  )
}

export default Terms