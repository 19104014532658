import {useEffect} from 'react'
import FormSignUp from '../../components/FormSignUp'
import Login2 from "../../assets/Login2.png";

function TeacherSignUp() {
  useEffect(() => {
    window.scrollTo(0, 0); // Automatically scroll to top when page is loaded
  }, []);
  return (
    <div>
      
      <FormSignUp 
     Description="Continue managing your classes, tracking progress, and accessing valuable resources seamlessly. Empower yourself with the tools you need to guide your students towards success."
     Image={Login2}
       path='../teacherLogin'/>

    </div>
  )
}

export default TeacherSignUp
