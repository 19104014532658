import React from 'react';
import FormLogin from '../../components/FormLogin';
import Login2 from "../../assets/Login2.png";
import { useLocation } from 'react-router-dom';

const TeacherLogin = () => {
  const location = useLocation();
  const from = location.state?.from || "/teacher"; // Fallback to default if no `from` provided

  return (
    <div>
      <FormLogin
        Description="Continue managing your classes, tracking progress, and accessing valuable resources seamlessly. Empower yourself with the tools you need to guide your students towards success."
        Image={Login2}
        path=".././teacherSignUp"
        forgetpath=".././forgetPass"
        redirectTo={from} // Pass down the `from` location as `redirectTo`
      />
    </div>
  );
};


export default TeacherLogin;
