import React from "react";
import Pro from "../../../assets/Profile.png";
import { ImUpload } from "react-icons/im";
import Diamond from "../../../assets/Diamond.png";
import { Link, NavLink } from "react-router-dom";

const TeacherProfile = () => {

  // const [Profile]= useState([
  //     {
  //       name:'Edit Profile',
  //       path:''
  // },
  //     {
  //       name:'Edit Student',
  //       path:''
  // },

  // ])

  return (
    <div>
      <div className="flex items-center xl:p-5 sm:p-0 justify-center pb-12 ">
        <div className="flex flex-wrap w-full justify-center border rounded-3xl shadow-xl pb-12">

          <div className=" w-full xl:w-1/3 p-4 ">
            {/* content1 */}
            <div className="flex flex-col  items-center justify-center">
              <div className="">
                <img
                  className="rounded-full border w-40 h-40"
                  src={Pro}
                  alt=""
                />
              </div>
              <div className="flex justify-end relative left-16 -top-6">
                <ImUpload className="w-5 h-5" />
              </div>
            </div>
            {/* content2 */}

            <div className="flex items-center justify-center text-4xl font-semibold">
              <p>Irfan Ahsan</p>
            </div>
            {/* content3 */}

            <div className="flex items-center justify-center text-xl mt-5 font-medium text-[#979CA5]">
              <p>Teacher</p>
            </div>
            {/* content4 */}

            <div className="flex items-center justify-center ">
              <Link to='.././teacherDash'>
                <button className="border bg-[#5653FC] text-white text-xl font-medium rounded-lg px-8 py-3 mt-5"> Go To Dashboard
                </button>
              </Link>
            </div>
            <div className="mx-4">
              <hr className="h-0.5 mt-4 bg-[#979CA5]" />
            </div>
            {/* content5 */}
            <div className="mt-3 mx-4 text-2xl font-semibold">
              <p>Account Setting</p>
            </div>
            {/* content6 */}
            <div className="mx-8 xl:block md:flex md:flex-wrap md:justify-center md:gap-8 cursor-pointer">
              {/* {Profile &&
             Profile.map((data) =>( */}

              <NavLink to=''>
                <div className="  flex items-center  bg-blue-50  border-l-4 border-[#5963F5]  text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5">
                  <img src={Diamond} alt="" /> <p>Edit Profile</p>
                </div>
              </NavLink>
              {/* ))} */}


              <NavLink to=''>
                <div className=" flex items-center border-l-4 border-[#5963F5]  bg-blue-50 text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5">
                  <img src={Diamond} alt="" /> <p>My Students</p>
                </div>
              </NavLink>

              <NavLink to='../teacherNotification'>
                <div className=" flex items-center border-l-4 border-[#5963F5]  bg-blue-50 text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5">
                  <img src={Diamond} alt="" /> <p>Notification</p>
                </div>
              </NavLink>
            </div>

            {/* content7 */}
            <div className="mt-14 mx-4 text-2xl font-semibold ">
              <p>My Subscription</p>
            </div>
            {/* content8 */}


            <div className="mx-8 xl:block md:flex md:flex-wrap md:justify-center md:gap-8 cursor-pointer">
              <NavLink to='../teacherPayment'>
                <div className=" flex items-center border-l-4  border-[#5963F5]  bg-blue-50 text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5">
                  <img src={Diamond} alt="" /> <p>Payments</p>
                </div>
              </NavLink>

              <NavLink to=''>
                <div className=" flex items-center border-l-4  border-[#5963F5]  bg-blue-50 text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5">
                  <img src={Diamond} alt="" /> <p>Invoices</p>
                </div>
              </NavLink>

            </div>
          </div>

          <div className="w-full xl:w-2/3 p-4 border-l-2 ">
            {/* content1 */}
            <div className="mx-4 mt-12">
              <p className="text-2xl font-bold">Profile Details</p>
              <p className="text-sm font-medium">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </div>
            <div className="mx-4">
              <hr className="h-0.5 mt-4 bg-[#979CA5]" />
            </div>
            {/* content2 */}

            <div className="w-full mt-12">
              <form className="grid grid-cols-1 sm:grid-cols-2 gap-4 mx-12" action="">
                <div>
                  <input type="text" placeholder="First Name" className="border h-10 w-full rounded-lg px-4 bg-gray-50 focus:border-blue-500 focus:outline-none" />
                </div>
                <div>
                  <input type="text" placeholder="Last Name" className="border h-10 w-full rounded-lg px-4 bg-gray-50 focus:border-blue-500 focus:outline-none" />
                </div>
                <div>
                  <input type="text" placeholder="Email" className="border h-10 w-full rounded-lg px-4 bg-gray-50 focus:border-blue-500 focus:outline-none" />
                </div>
                <div>
                  <input type="text" placeholder="Hourly Rate" className="border h-10 w-full rounded-lg px-4 bg-gray-50 focus:border-blue-500 focus:outline-none" />
                </div>
                <div>
                  <input type="text" placeholder="Country" className="border h-10 w-full rounded-lg px-4 bg-gray-50 focus:border-blue-500 focus:outline-none" />
                </div>
                <div>
                  <input type="text" placeholder="City" className="border h-10 w-full rounded-lg px-4 bg-gray-50 focus:border-blue-500 focus:outline-none" />
                </div>
                <div>
                  <input type="text" placeholder="Zip" className="border h-10 w-full rounded-lg px-4 bg-gray-50 focus:border-blue-500 focus:outline-none" />
                </div>
                <div>
                  <input type="text" placeholder="Address" className="border h-10 w-full rounded-lg px-4 bg-gray-50 focus:border-blue-500 focus:outline-none" />
                </div>
              </form>
            </div>

            <div>
              <p className="border border-blue-500 w-fit p-2 rounded-lg mt-8 font-medium mx-12 cursor-pointer">Update Profile</p>
            </div>

          </div>

        </div>
      </div>
    </div>
  );
};

export default TeacherProfile;
