import React from "react";
import { Link } from "react-router-dom";

const Personalize = (props) => {
  return (
    <div>
      <div className="flex flex-wrap items-center border-2 border-white justify-between my-20 px-24 w-full gap-5  ">
        <div className="">
          <h1 className="small-heading medium-heading text-[40px] font-bold text-[#722DC0] leading-[45px] font-Poppins" data-aos="fade-right">Make a Living By <br />
            Teaching With Edolutions!</h1>
            <p className="text-[20px] small-text mt-2 text-[#000] leading-[25px] font-Poppins" data-aos="fade flex-wrap-right">Join our panel of expert tutors. Start sharing your knowledge <br />
             and earn at your convenience!</p>
        </div>

        <div>
        <Link to={props.link}>
            <button
              className="bg-[#2DD0A5] font-bold text-white text-[24px] overflow-x-hidden   py-2 px-8   rounded  hover:bg-[#2DD0A5] duration-500  " data-aos="fade-left">
              Register Now
            </button>
            </Link>
        </div>

      </div>
    </div>
  );
};

export default Personalize;
