import React, { useEffect, useState } from 'react';
import { FiSearch } from "react-icons/fi";
import { Link } from "react-router-dom";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import axios from 'axios';
import apiKey from "../../../config/apiKey";

export default function Report() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);



  const header = ["Student Name", "Teacher Name", "Class Name", "Subject", "Session & Fee", "Type", "Status", "Time"];
  const totalItems = data.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalItems);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${apiKey}/api/edolutions/get-notifications-all`,
      
        );
        console.log(response.data.data)
        const formattedData = response.data.data
          .filter((item) => item.Fee== "paid")  // Filter for paid status
          .map((item) => ({
            id: item.tbl_id,
            column1: item.student_name,
            column2: item.teacher_name,
            column3: item.class_name,
            column4: item.subjects,
            column5: `${item.days} sessions, Fee: ${item.payment}`,
            column6: item.type_name,
            column7: item.Fee,
            column8: item.created_at
          }));
  
        setData(formattedData);
        console.log(response.data.data)
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  

  const handlePageChange = (pageNumber) => {
    setCurrentPage(Math.min(Math.max(1, pageNumber), totalPages));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value));
    setCurrentPage(1);
  };

  return (
    <main>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="relative my-8 flex flex-col min-w-0 break-words w-full mb-6 shadow-lg bg-white rounded-lg p-8 border-0">
          <div className="flex flex-wrap items-center justify-between">
            <div>
              <p className="text-[22px] font-bold">Reports </p>
    
            </div>

            <div className="flex items-center">
              <div className="relative border shadow-md rounded-md flex items-center px-5 py-2">
                <FiSearch className="h-5 w-5 absolute text-[#5963F5]" />{" "}
                <input
                  type="text"
                  className="outline-none pl-8"
                  placeholder="Search"
                />
              </div>
              <div className="relative ml-2">
                <select className="border shadow-md rounded-md px-5 py-2 outline-none pl-2 pr-8 text-sm">
                  <option value="">Sorted By : Newest</option>
                  <option value="">Sorted By : Oldest</option>
                  <option value="">Sorted By : Teachers</option>
                  <option value="">Sorted By : One to One</option>
                </select>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <table className="w-full text-sm text-left text-gray-500 border-b table-auto">
              <thead className="text-sm text-[#B5B7C0] font-medium border-b">
                <tr>
                  {header.map((heading, idx) => (
                    <td key={idx} className="py-5 px-1">{heading}</td>
                  ))}
                </tr>
              </thead>
              <tbody className="text-black text-sm font-medium">
                {data.slice(startIndex, endIndex).map((item) => (
                  <tr key={item.id} className="border-b">
                    <td className="py-5 px-1">
                      <Link to={item.path}>{item.column1}</Link>
                    </td>
                    <td className="py-5 px-1">{item.column2}</td>
                    <td className="py-5 px-1">{item.column3}</td>
                    <td className="py-5 px-1">{item.column4}</td>
                    <td className="py-5 px-1">{item.column5}</td>
                    <td className="py-5 px-1">{item.column6}</td>
                    <td className="py-5 px-1">
        <p
          className={`border cursor-pointer shadow-lg px-5 rounded-md py-1 w-fit ${
            item.column7 === "paid" 
              ? "text-green-500 border-green-500"
              : "text-red-500 border-red-500"
          }`}
        >
          {item.column7}
        </p>
      </td>
      <td className="py-5 px-1">{item.column8}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="flex flex-wrap gap-5 justify-between items-center w-full text-sm mt-4 pb-4 px-2">
              <div className="flex items-center gap-x-2 text-gray-400">
                <select
                  className="text-sm border-none text-black bg-[#F5F5F5] rounded-lg"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  {[10, 20, 30, 40, 50, 100].map((perPage) => (
                    <option key={perPage} value={perPage}>{perPage}</option>
                  ))}
                </select>
                <span>Showing Data {startIndex + 1}-{endIndex} of {totalItems} Entries</span>
              </div>
              <div className="flex items-center gap-x-2 text-gray-400">
                <button
                  className="text-base text-black bg-[#F5F5F5] rounded-lg px-2 py-1"
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                >
                  <MdKeyboardArrowLeft />
                </button>
                {[...Array(Math.min(5, totalPages || 1))].map((_, index) => {
                  const pageNumber = currentPage + index;
                  const isActive = pageNumber === currentPage;
                  return (
                    <button
                      key={index}
                      className={`text-base rounded-lg px-3 py-1 ${isActive ? "text-white bg-[#4FD1C5]" : "text-black bg-[#F5F5F5]"}`}
                      onClick={() => handlePageChange(pageNumber)}
                      disabled={pageNumber < 1 || pageNumber > totalPages}
                    >
                      {pageNumber}
                    </button>
                  );
                })}
                <span>of {totalPages || 1} pages</span>
                <button
                  className="text-base text-black bg-[#F5F5F5] rounded-lg px-2 py-1"
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  <MdKeyboardArrowRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </main>
  );
}
