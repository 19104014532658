import React from 'react'
import { BsBook } from 'react-icons/bs'
import { FaLocationPin } from 'react-icons/fa6'
import TeacherMsgCard from './TeacherMsgCard'

function TeacherMessage() {
  return (
    <>
    <div>
     <div className="flex items-center xl:p-5 sm:p-0 justify-center">
       <div className="w-full border rounded-3xl shadow-xl p-5">

       <div>
         <div className="flex items-center xl:p-5 sm:p-0 justify-center">
                <div className="w-full  xl:p-5 sm:p-0">
                    <div className="p-5"><TeacherMsgCard /></div>
                    <div className="grid grid-cols-1 sm:grid-cols-3 ">
                        <div className="   md:px-4 md:py-4 col-span-1 sm:col-span-2 md:col-span-2 lg:col-span-2 xl:col-span-2 space-y-5">
                            <div className="border rounded-3xl shadow-xl p-5">
                                <p className="text-2xl font-semibold mb-3">About</p>
                                <p className="text-xs font-light">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                            </div>
                            <div className="border rounded-3xl shadow-xl p-5">
                                <p className="text-2xl font-semibold mb-3">Tuition Location</p>
                                <div>
                                    <p className="text-base font-semibold mb-3 flex items-center space-x-1"><FaLocationPin className='text-lg' /><span>Address</span>  </p>
                                    <p className='text-sm font-semibold'>Main Address: </p>
                                    <p className="text-sm font-light mb-3">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                                </div>
                            </div>
                        </div>
                        <div className="  col-span-1 sm:col-span-1 md:col-span-1 lg:col-span-1 xl:col-span-1 md:px-4 md:py-4 space-y-5 md:mt-3 mt-6 p-5 min-h-screen border rounded-3xl shadow-xl">
                            <div><p className="text-2xl font-semibold mb-2">Subjects</p></div>
                            <div>
                                <div><p className='flex items-center text-lg font-semibold space-x-2'><BsBook className='text-lg font-semibold' /><span>Mathematics</span></p></div>
                                <div><p className='text-sm font-semibold'>20 Hours</p></div>
                            </div>
                            <div>
                                <div><p className='flex items-center text-lg font-semibold space-x-2'><BsBook className='text-lg font-semibold' /><span>Chemistry</span></p></div>
                                <div><p className='text-sm font-semibold'>20 Hours</p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         </div>
       
       </div>
     </div>
   </div>
   </>
  )
}

export default TeacherMessage
