import React  from "react";
import Boards from "../components/Boards";
import Personalize from "../components/Personalize";
import LearningPotential from "../components/LearningPotential";
import ClassConduct from "../components/ClassConduct";
import FindLandingCard from "../components/FindLandingCard";
import FollowingSection from "../components/FollowingSection";
import ShowAllTeachers from "../components/ShowAllTeachers";

const ShowTeachers = () => {

 
  return (
    <div className="mt-28">
      {/* <FindLandingCard /> */}
      <ShowAllTeachers />
   
     
    
      <Personalize link="/login/studentSignUp" />
    </div>
  );
};

export default ShowTeachers;
