import React, { useState, useEffect } from "react";
import apiKey from "../../../config/apiKey";
import axios from "axios";
import { BiArrowBack } from "react-icons/bi";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const Upcoming = () => {
  const [meetings, setMeetings] = useState([]);
  const [upcomingMeetings, setUpcomingMeetings] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loading, setLoading] = useState(true); // Add loading state
  const admin = JSON.parse(localStorage.getItem("edo-admin"));
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMeetingsData = async () => {
      try {
        setLoading(true); // Start loading
        const meetingsResponse = await axios.get(
          `${apiKey}/api/edolutions/admin/allmeetings`,
          {
            headers: {
              Authorization: `Bearer ${admin?.token}`,
            },
          }
        );

        const meetingsData = meetingsResponse.data.meetings;
        setMeetings(meetingsData);

        const now = new Date();
        setUpcomingMeetings(
          meetingsData.filter((meeting) => new Date(meeting.start_time) > now)
        );
      } catch (error) {
        console.error("Error fetching meetings data:", error);
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchMeetingsData();
  }, [admin?.token]);

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filter upcoming meetings based on the search term
  const filteredMeetings = upcomingMeetings.filter((meeting) =>
    meeting.topic.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination logic
  const totalItems = filteredMeetings.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(Math.min(Math.max(1, pageNumber), totalPages));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleItemsPerPageChange = (event) => {
    const selectedItemsPerPage = parseInt(event.target.value, 10);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1); // Reset to first page
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalItems);
  const currentData = filteredMeetings.slice(startIndex, endIndex);

  // Calculate the range of pages to display
  const pageRange = [];
  const maxPageButtons = 5;
  const startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
  const endPage = Math.min(totalPages, startPage + maxPageButtons - 1);

  for (let i = startPage; i <= endPage; i++) {
    pageRange.push(i);
  }

  return (
    <>
      <div className="flex items-center xl:p-5 sm:p-0 justify-center">
        <div className="w-full border rounded-3xl shadow-xl p-5">
          <div className="bg-white px-3 col-span-1 overflow-hidden">
            <p
              onClick={() => navigate(-1)} // Navigate to the previous page
              className="py-2 text-black text-base flex items-center cursor-pointer"
            >
              <BiArrowBack /> Back
            </p>

            {/* Header and Search Bar */}
            <div className="flex flex-wrap items-center justify-between mt-4">
              <div>
                <p className="text-[22px] font-bold">Upcoming Meetings</p>
                <p className="text-[14px] font-normal text-[#4FD1C5] mt-2">
                  Review your Upcoming meetings
                </p>
              </div>
              <div className="flex items-center">
                <div className="relative border shadow-md rounded-md flex items-center px-5 py-2">
                  <FiSearch className="h-5 w-5 absolute text-black" />
                  <input
                    type="text"
                    className="outline-none pl-8"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
            </div>

            {loading ? (
              <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-200 bg-opacity-5 backdrop-blur-sm z-50">
                <div className="w-12 h-12 border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin border-t-[#2DD0A5]"></div>
                <p className="mt-4 text-lg font-semibold text-[#2DD0A5]">Edolution...</p>
              </div>
            ) : currentData.length > 0 ? (
              <>
                <table className="w-full text-sm text-left text-gray-500 border-b table-auto mt-4">
                  <thead className="text-sm text-[#B5B7C0] font-medium border-b">
                    <tr>
                      <th scope="col" className="py-2 px-1 w-2/12">
                        Topic
                      </th>
                      <th scope="col" className="py-2 px-1 w-2/12">
                        Meeting ID
                      </th>
                      <th scope="col" className="py-2 px-1 w-4/12">
                        Details
                      </th>
                      <th scope="col" className="py-2 px-1 w-2/12">
                        Upcoming On
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-black text-sm font-medium">
                    {currentData.map((meeting) => (
                      <tr key={meeting.id} className="border-b">
                        <td className="py-5 px-1 capitalize">
                          {meeting.topic}
                        </td>
                        <td className="py-5 px-1">{meeting.id}</td>
                        <td className="py-5 px-1">{meeting.agenda}</td>
                        <td className="py-5 px-1">
                          {new Date(meeting.start_time).toLocaleString()}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {/* Pagination */}
                <div className="flex flex-wrap gap-5 justify-between items-center w-full text-sm mt-4 pb-4 px-2">
                  <div className="flex items-center gap-x-2 text-gray-400">
                    <span>
                      <select
                        className="text-sm border-none text-black bg-[#F5F5F5] rounded-lg"
                        value={itemsPerPage}
                        onChange={handleItemsPerPageChange}
                      >
                        {[10, 20, 30, 40, 50, 100].map((perPage) => (
                          <option key={perPage} value={perPage}>
                            {perPage}
                          </option>
                        ))}
                      </select>
                    </span>
                    <span>Showing Data</span>
                    <span>
                      {startIndex + 1}-{endIndex} of {totalItems} Entries
                    </span>
                  </div>
                  <div className="flex items-center gap-x-2 text-gray-400">
                    <button
                      className="text-base text-black bg-[#F5F5F5] rounded-lg px-2 py-1"
                      onClick={handlePrevPage}
                      disabled={currentPage === 1}
                    >
                      <MdKeyboardArrowLeft />
                    </button>

                    <div className="flex items-center gap-x-2 text-gray-400">
                      {pageRange.map((pageNumber) => {
                        const isActive = pageNumber === currentPage;
                        return (
                          <button
                            key={pageNumber}
                            className={`text-base rounded-lg px-3 py-1 ${
                              isActive
                                ? "text-white bg-[#4FD1C5]"
                                : "text-black bg-[#F5F5F5]"
                            }`}
                            onClick={() => handlePageChange(pageNumber)}
                            disabled={pageNumber < 1 || pageNumber > totalPages}
                          >
                            {pageNumber}
                          </button>
                        );
                      })}
                      {totalPages > maxPageButtons && (
                        <span className="text-base text-gray-400">...</span>
                      )}
                    </div>

                    <button
                      className="text-base text-black bg-[#F5F5F5] rounded-lg px-2 py-1"
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    >
                      <MdKeyboardArrowRight />
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <div className="flex flex-col items-center justify-center h-24">
                <p>No upcoming meetings found.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Upcoming;
