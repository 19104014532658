import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Dashboard from "../../pages/StudentDashboard/Dashboard/Dashboard";
import SideNav from "../../pages/StudentDashboard/SideBar/SideNav";
import Notification from "../../pages/StudentDashboard/Notification/Notification";
import TopBar from "../../pages/StudentDashboard/SideBar/TopBar";
import NewNotification from "../../pages/StudentDashboard/Notification/NewNotification";
import UnreadNotification from "../../pages/StudentDashboard/Notification/UnreadNotification";
import HomeProfile from "../../pages/StudentDashboard/Home/HomeProfile";
import FindTeacher from "../../pages/StudentDashboard/FindTeacher/FindTeacher";
import HomeTution from "../../pages/StudentDashboard/FindTeacher/HomeTution";
import HomeTeacherList from "../../pages/StudentDashboard/FindTeacher/HomeTeacherList";
import OnlineTeacherList from "../../pages/StudentDashboard/FindTeacher/OnlineTeacherList";
import TeacherPortfolio from "../../pages/StudentDashboard/FindTeacher/TeacherProtfolio";
import TeacherPortfolio2 from "../../pages/StudentDashboard/FindTeacher/TeacherPortfolio2";
import HomeTeacherPortfolio from "../../pages/StudentDashboard/FindTeacher/HomeTeacherPortfolio";
import HomeTeacherPortfolio2 from "../../pages/StudentDashboard/FindTeacher/HomeTeacherProtfolio2";
import StudentPayment from "../../pages/StudentDashboard/Home/StudentPayment";
import PaymentDetail from "../../pages/StudentDashboard/Home/PaymentDetail";
import TeacherList from "../../pages/StudentDashboard/Home/TeacherList";
import Report from "../../pages/StudentDashboard/Report/Report";
import MyTutoring from "../../pages/StudentDashboard/MyToturing/MyTutoring";
import Profile from "../../pages/StudentDashboard/Dashboard/Profile";
import OnlineTutionn from "../../pages/StudentDashboard/FindTeacher/OnlineTutionn";
import ChatMsg from "../../pages/StudentDashboard/MessageChat/ChatMsg";
import ZoomMeetingPageStd from "../../pages/StudentDashboard/Meeting/ZoomMeetingPageStd";
import PaymentPage from "../../pages/StudentDashboard/FindTeacher/PaymentPage";

function StudentLayout() {
  const { pathname } = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    if (window.innerWidth <= 1280) {
      setIsSidebarOpen(!isSidebarOpen);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1280) {
        setIsSidebarOpen(false);
      } else {
        setIsSidebarOpen(true);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);

    const user = JSON.parse(localStorage.getItem("edo-student"));
    if (!user) {
      navigate("/login/teacherLogin");
    }
  }, [navigate, pathname]);
  const [totalUnreadCount, setTotalUnreadCount] = useState(0);
  return (
    <>
      <TopBar toggleSidebar={toggleSidebar} totalUnreadCount={totalUnreadCount} />
      <div className="flex">
        <SideNav isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

        <div className="h-screen flex-1 px-2 mt-5">
          <Routes>
            {/* Notification----------------- */}
            <Route path="/notification" element={<Notification />} />
            <Route path="/newNotification" element={<NewNotification />} />
            <Route
              path="/unreadNotification"
              element={<UnreadNotification />}
            />

            {/* Message---------------------- */}
            <Route path="/studentChat" element={<ChatMsg setTotalUnreadCount={setTotalUnreadCount} />} />

            {/* Dashboard--------------------- */}
            <Route path="" element={<Dashboard />} />

            {/* Home--------------------------- */}
            <Route path="/profile" element={<Profile />} />

            {/* My-Tutoring---------------- */}
            <Route path="/myTutoring" element={<MyTutoring />} />
            <Route path="/ZoomMeetingPageStd"element={<ZoomMeetingPageStd/>} />

            {/* Find-Teacher------------------- */}
            <Route path="/findTeacher" element={<FindTeacher />} />
            <Route path="/homeTution" element={<HomeTution />} />
            <Route path="/onlineTutionn" element={<OnlineTutionn />} />
            <Route path="/onlineTeacherList" element={<OnlineTeacherList />} />
            <Route path="/homeTeacherList" element={<HomeTeacherList />} />
            <Route
              path="/teacherPortfolio/:id"
              element={<TeacherPortfolio />}
            />
            <Route path="/teacherPortfolio2" element={<TeacherPortfolio2 />} />
            <Route
              path="/homeTeacherPortfolio/:id"
              element={<HomeTeacherPortfolio />}
            />
            <Route
              path="/homeTeacherPortfolio2"
              element={<HomeTeacherPortfolio2 />}
            />
            <Route
              path="/pay-fees/:id"
              element={<PaymentPage/>}
            />

            {/* Report------------------------------- */}
            <Route path="/studentReport" element={<Report />} />

            {/* Setting----------------------------- */}
            <Route path="/proSetting" element={<HomeProfile />} />
            <Route path="/studentPayment" element={<StudentPayment />} />
            <Route path="/paymentDetail" element={<PaymentDetail />} />
            <Route path="/teacherList" element={<TeacherList />} />
          </Routes>
        </div>
      </div>
    </>
  );
}

export default StudentLayout;
