import React from "react"
import ContactForm from "../components/ContactForm";
import ContactLanding from "../components/ContactLanding";


const ContactUs = () => {
  return (
    <>
    <ContactLanding/>
    <ContactForm/>
    

    </>

  );
};

export default ContactUs;