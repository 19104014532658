import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";

const HomeTution = () => {


  const navigate = useNavigate();

  return (

    <div>
      <div className="relative h-screen flex flex-col break-words w-full mb-6 shadow-lg bg-white rounded-lg p-8 border-0">
        <div className="flex  gap-2 items-center cursor-pointer" onClick={() => navigate(-1)}>
          <BiArrowBack className="h-5 w-5" />
          <p className="text-[14px]">Back</p>
        </div>
        <div className="flex justify-center items-center xl:h-screen h-fit xl:mt-0 mt-16">
          <div className="md:w-[500px] w-full">
            <div className="text-center">
              <p className="text-2xl font-semibold">Find Teachers</p>
              <p className="text-sm font-normal">Home Tuition</p>
            </div>

            <div className="flex flex-col mt-5">
              <select
                className="text-[#B5B7C0] text-md cursor-pointer dropdown-select w-full mt-5 rounded-lg outline-none bg-[#F9FBFF] px-5 py-2" >
                <option value="">Select Class</option>
                <option value="math">Math</option>
                <option value="science">Science</option>
                <option value="history">History</option>
              </select>

              <select
                className="text-[#B5B7C0] text-md cursor-pointer dropdown-select w-full mt-5 rounded-lg outline-none bg-[#F9FBFF] px-5 py-2" >
                <option value="">Select Subject</option>
                <option value="english">English</option>
                <option value="physics">Physics</option>
                <option value="chemistry">Chemistry</option>
              </select>

              <select
                className="text-[#B5B7C0] text-md cursor-pointer dropdown-select w-full mt-5 rounded-lg outline-none bg-[#F9FBFF] px-5 py-2">
                <option value="">Select Area</option>
                <option value="programming">Programming</option>
                <option value="arts">Arts</option>
                <option value="music">Music</option>
              </select>
            </div>

            <div className="text-center mt-10">

              <Link to='.././homeTeacherList'
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg ml-2 focus:outline-none focus:shadow-outline">
                Find Tuition
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeTution;
