import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import control from "../../assets/control.png";
import Logo2 from "../../assets/Edolutions 1.png";
import { FaGraduationCap } from "react-icons/fa";
import { MdKeyboardDoubleArrowRight, MdSearch } from "react-icons/md";
import {
  PiChalkboardTeacherFill,
  PiStudentFill,
  PiUserCircleGearFill,
  PiFilesFill,
} from "react-icons/pi";

import { GiTeacher } from "react-icons/gi";
import { SiGoogleclassroom } from "react-icons/si";
import { FaHome } from "react-icons/fa";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const [orderDetail, showOrderDetail] = useState(true);

  return (
    <div className="">
      <div
        className={` ${
          isOpen ? "w-[240px]" : "w-0"
        } bg-white h-screen  absolute xl:relative  duration-300 overflow-y-auto z-10 transition-all border-r-2 admin-scroll-bar`}
      >
        <img
          src={control}
          alt=""
          className={`absolute block xl:hidden cursor-pointer -right-3 top-16 sm:top-16 md:top-5 w-7 border-dark-purple border-2 rounded-full ${
            !isOpen && "rotate-180"
          }`}
          onClick={toggleSidebar}
        />

        <div>
          <div className="w-full p-5  md:hidden block">
            <img src={Logo2} alt="" />
          </div>

          <div className={`${isOpen ? "block" : "hidden"} px-5`}>
            {/* <span className="relative md:absolute inset-y-0 left-0 top-8 flex items-center pl-2 ">
              <button className=" flex items-center p-1 focus:outline-none text-[#232738] md:text-black">
                <MdSearch className="sm:hidden block" />
              </button>
            </span>
            <input
              type="text"
              placeholder="Search"
              className="w-full px-4 py-[6px] pl-12 rounded-md shadow cursor-pointer outline-none sm:hidden block border-2"
            /> */}
          </div>
        </div>

        <ul className="mt-3 text-[14px] text-[#232738] font-medium ms-3">

          <li className="mb-2 rounded  hover:text-blue-500 active:text-[#4FD1C5] py-5">
            <NavLink
              to="./home"
              className={({ isActive }) =>
                isActive ? "text-[#4FD1C5] px-3" : "px-3"
              }
            >
              <FaHome className="inline-block w-[22.5px] h-[22.5px] mr-2 -mt-2" />
              Home
            </NavLink>
          </li>
          <li className="mb-2 rounded  hover:text-blue-500 active:text-[#4FD1C5] py-5">
            <NavLink
              to="./onlineClasses"
              className={({ isActive }) =>
                isActive ? "text-[#4FD1C5] px-3" : "px-3"
              }
            >
              <SiGoogleclassroom className="inline-block w-[22.5px] h-[22.5px] mr-2 -mt-2" />
              Online Tutoring
            </NavLink>
          </li>
          <li className="mb-2 rounded  hover:text-blue-500 active:text-[#4FD1C5] py-5">
            <NavLink
              to="./homeTeacher"
              className={({ isActive }) =>
                isActive ? "text-[#4FD1C5] px-3" : "px-3"
              }
            >
              <GiTeacher className="inline-block w-[22.5px] h-[22.5px] mr-2 -mt-2" />
              Home Teacher
            </NavLink>
          </li>
          <li className="mb-2 rounded  hover:text-blue-500 active:text-[#4FD1C5] py-5">
            <NavLink
              to="./teacher"
              className={({ isActive }) =>
                isActive ? "text-[#4FD1C5] px-3" : "px-3"
              }
            >
              <PiChalkboardTeacherFill className="inline-block w-[22.5px] h-[22.5px] mr-2 -mt-2" />
              Teachers
            </NavLink>
          </li>
          <li className="mb-2 rounded  hover:text-blue-500 active:text-[#4FD1C5] py-5">
            <NavLink
              to="./student"
              className={({ isActive }) =>
                isActive ? "text-[#4FD1C5] px-3" : "px-3"
              }
            >
              <PiStudentFill className="inline-block w-[22.5px] h-[22.5px] mr-2 -mt-2" />
              Students
            </NavLink>
          </li>

          <li className="mb-2 rounded  hover:text-blue-500 active:text-[#4FD1C5] py-5 flex gap-5 cursor-pointer">
            <NavLink
              className="px-3  active:text-[#4FD1C5]"
              onClick={() => showOrderDetail(!orderDetail)}
            >
              <FaGraduationCap className="inline-block w-[22.5px] h-[22.5px] mr-2 -mt-2" />
              Academic Zone
            </NavLink>
            <div
              className="active:text-[#4FD1C5]"
              onClick={() => showOrderDetail(!orderDetail)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentcolor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={`lucide lucide-chevron-right transition-all ${
                  !orderDetail ? "rotate-90" : ""
                }`}
              >
                <path d="m9 18 6-6-6-6" />
              </svg>
            </div>
          </li>

          <div
            className={`grid transition-all ${
              orderDetail ? "grid-rows-[0]" : "grid-rows-1"
            } overflow-hidden`}
          >
            <div>
              <ul className="px-5">
                <li className="mb-2 rounded  hover:text-blue-500 active:text-[#4FD1C5] py-2">
                  <NavLink
                    to="./acClass"
                    className={({ isActive }) =>
                      isActive ? "text-[#4FD1C5] px-3" : "px-3"
                    }
                  >
                    <MdKeyboardDoubleArrowRight className="inline-block w-[22.5px] h-[22.5px] mr-2 -mt-2" />
                    Classes
                  </NavLink>
                </li>
                <li className="mb-2 rounded  hover:text-blue-500 active:text-[#4FD1C5] py-2">
                  <NavLink
                    to="./acSubject"
                    className={({ isActive }) =>
                      isActive ? "text-[#4FD1C5] px-3" : "px-3"
                    }
                  >
                    <MdKeyboardDoubleArrowRight className="inline-block w-[22.5px] h-[22.5px] mr-2 -mt-2" />
                    Subjects
                  </NavLink>
                </li>
                <li className="mb-2 rounded  hover:text-blue-500 active:text-[#4FD1C5] py-2">
                  <NavLink
                    to="./acSkill"
                    className={({ isActive }) =>
                      isActive ? "text-[#4FD1C5] px-3" : "px-3"
                    }
                  >
                    <MdKeyboardDoubleArrowRight className="inline-block w-[22.5px] h-[22.5px] mr-2 -mt-2" />
                    Skills
                  </NavLink>
                </li>

                <li className="mb-2 rounded  hover:text-blue-500 active:text-[#4FD1C5] py-2">
                  <NavLink
                    to="./acLanguage"
                    className={({ isActive }) =>
                      isActive ? "text-[#4FD1C5] px-3" : "px-3"
                    }
                  >
                    <MdKeyboardDoubleArrowRight className="inline-block w-[22.5px] h-[22.5px] mr-2 -mt-2" />
                    Languages
                  </NavLink>
                </li>

                <li className="mb-2 rounded  hover:text-blue-500 active:text-[#4FD1C5] py-2">
                  <NavLink
                    to="./demoClass"
                    className={({ isActive }) =>
                      isActive ? "text-[#4FD1C5] px-3" : "px-3"
                    }
                  >
                    <MdKeyboardDoubleArrowRight className="inline-block w-[22.5px] h-[22.5px] mr-2 -mt-2" />
                    Demo Class
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>

          <li className="mb-2 rounded  hover:text-blue-500 active:text-[#4FD1C5] py-5">
            <NavLink
              to="./report"
              className={({ isActive }) =>
                isActive ? "text-[#4FD1C5] px-3" : "px-3"
              }
            >
              <PiFilesFill className="inline-block w-[22.5px] h-[22.5px] mr-2 -mt-2" />
              Reports
            </NavLink>
          </li>
          <li className="mb-2 rounded  hover:text-blue-500 active:text-[#4FD1C5] py-5">
            <NavLink
              to="./myAccount"
              className={({ isActive }) =>
                isActive ? "text-[#4FD1C5] px-3" : "px-3"
              }
            >
              <PiUserCircleGearFill className="inline-block w-[22.5px] h-[22.5px] mr-2 -mt-2" />
              Settings
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
